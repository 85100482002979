@mixin reset {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
}

@mixin mediaQuery($max) {
  @media screen and (max-width: $max) {
    @content;
  }
}

@mixin box_shadow($color) {
  box-shadow: 0px 5px 16px 0px $color;
}

@mixin fill_available() {
  width: stretch;
  width: -moz-available;
  width: -webkit-fill-available;
}

@function pxToRem($value) {
  @return calc($value / 16) * 1rem;
}

// @function pxToVw($font) {
//   $vw-context: 1440 * 0.01 * 1px;
//   @return $font / $vw-context * 1vw;
//   @return $font;
// }
