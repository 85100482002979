#root {
  .profile_page {
    margin-top: 75px;
    padding: 50px var(--main-gap) 130px var(--main-gap);
    min-height: 100vh;
    .profile {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      .left {
        display: flex;
        align-items: center;
        gap: 50px;
        figure.logo {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          @include box_shadow(map-get($color, "dark/alpha/25"));
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .txt {
          display: flex;
          flex-direction: column;
          gap: 15px;
          .name_info {
            .h_8 {
            }
            .b_4 {
            }
          }
          .when_play_league {
            display: flex;
            flex-direction: column;
            gap: 5px;
            div {
              display: flex;
              align-items: center;
              gap: 5px;
              figure {
                width: 16px;
                display: flex;
                // color: map-get($color, "dark/100");
                svg {
                  width: 100%;
                  height: 100%;
                }
              }
              .b_3 {
              }
            }
          }
          .desc {
            // width: 50%;
            .b_4 {
            }
          }
        }
      }
      .right {
        a {
          display: flex;
          gap: 10px;
          padding: 8px;
          border-radius: 12px;
          @include box_shadow(map-get($color, "dark/alpha/25"));
          background-color: map-get($color, "neutral/100");
          figure {
            width: 15px;
            display: flex;
            svg {
              width: 100%;
              height: 100%;
            }
          }
          .b_3 {
            color: map-get($color, "dark/100");
          }
        }
      }
    }
    .profile_sect {
      border: 3px solid map-get($color, "neutral/300");
      border-radius: 24px;
      padding: 36px;
      display: flex;
      flex-direction: column;
      gap: 45px;
      margin-top: 50px;
      position: relative;
      .profile_sect_hood {
        .h_7 {
        }
      }
      .cards {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 25px;
        position: relative;
        .stat_card,
        .achievement_card {
          flex: 0 0 calc((100% / 4) - 25px);
          figure {
            color: map-get($color, "dark/100");
          }
        }
      }
      &.point_history {
        .point_history_table {
          width: 100%;
          height: 100%;
          border-collapse: separate;
          border-spacing: 0 25px;
          th,
          td {
            width: 10%;
          }
          thead {
            tr {
              background-color: map-get($color, "neutral/100");
              th {
                text-align: start;
                border-top: 3px solid map-get($color, "neutral/300");
                border-bottom: 3px solid map-get($color, "neutral/300");
                // background: red;
                padding: 12px;
                &:first-child {
                  border-left: 3px solid map-get($color, "neutral/300");
                  border-radius: 12px 0 0 12px;
                }
                &:last-child {
                  border-right: 3px solid map-get($color, "neutral/300");
                  border-radius: 0 12px 12px 0;
                }
              }
            }
          }
          tbody {
            tr {
              margin-top: 25px;
              // background-color: map-get($color, "neutral/100");
              // @include box_shadow(map-get($color, "dark/alpha/25"));
              // border-radius: 12px !important;
              // overflow: hidden;
              td {
                padding: 12px;
                background-color: map-get($color, "neutral/100");
                border-top: 3px solid map-get($color, "primary/alpha/10");
                border-bottom: 3px solid map-get($color, "primary/alpha/10");
                &:first-child {
                  border-left: 3px solid map-get($color, "primary/alpha/10");
                  border-radius: 16px 0 0 16px;
                }
                &:last-child {
                  border-right: 3px solid map-get($color, "primary/alpha/10");
                  border-radius: 0 16px 16px 0;
                }
                &.id {
                  color: map-get($color, "primary/300");
                }
                &.point {
                  color: map-get($color, "green/300");
                }
                figure {
                  display: flex;
                  justify-content: flex-end;
                  svg {
                    width: 16px;
                  }
                }
              }
            }
          }
        }
        .no_data {
          padding: 12px;
          background-color: map-get($color, "neutral/100");
          border: 3px solid map-get($color, "primary/alpha/10");
          border-radius: 16px;
          text-align: center;
        }
      }
    }
  }
}
