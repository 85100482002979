#root {
  .user_homepage {
    margin-top: 75px;
    padding: 20px var(--main-gap) 130px var(--main-gap);
    min-height: 100vh;
    .homepage_buttons {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 48px;
      margin: 50px 0;
      a {
        display: flex;
        align-items: center;
        gap: 20px;
        background-color: map-get($color, "neutral/100");
        @include box_shadow(map-get($color, "dark/alpha/25"));
        border-radius: 20px;
        padding: 6px 12px;
        width: 100%;
        width: calc((100% / 4) - 48px);
        figure {
          height: 40px;
          width: 40px;
          svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }
        p.h_8 {
          color: map-get($color, "dark/100");
        }
      }
    }
    .premium_discover {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;
      // width: calc(((100% / 4) * 3) - (48px / 3));
      width: calc(100% / 2 - (48px / 2));
      padding: 16px;
      border-radius: 24px;
      background-color: map-get($color, "neutral/100");
      @include box_shadow(map-get($color, "dark/alpha/25"));
      margin-top: 65px;
      figure {
        width: 100%;
        height: 120px;
        border-radius: 12px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .premium_discover_content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .h_7 {
          // display: flex;
          span {
            margin-right: 8px;
          }
        }
        .b_1 {
          span {
            margin-left: 8px;
          }
        }
      }
      .background_btn.h_8 {
        border-radius: 20px;
        padding: 4px 36px;
      }
    }
  }
  .homepage {
    .first {
      width: 100%;
      height: 100vh;
      overflow: hidden;
      position: relative;
      .img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        .filter {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: map-get($color, "neutral/alpha/40");
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .txt {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        .h_6 {
          color: map-get($color, "neutral/100");
        }
        .h_7 {
          color: map-get($color, "neutral/100");
        }
        .background_btn {
          width: 20vw;
          margin-top: 50px;
        }
      }
    }
    .rewards {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: map-get($color, "footer");
      padding: 150px 90px;
      gap: 80px;
      .content {
        flex: 1 1 100%;
        .h_7 {
          color: map-get($color, "neutral/100");
          // width: 80%;
        }
        .background_btn {
          background-color: map-get($color, "neutral/100");
          color: map-get($color, "footer");
          width: fit-content;
          margin-top: 30px;
        }
      }
      figure {
        flex: 1 1 100%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .how-to {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 150px 90px;
      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        flex: 1 1 100%;
        figure {
          img {
          }
        }
        .h_8 {
          text-align: center;
        }
      }
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .user_homepage {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      // min-height: 100vh;
      .homepage_buttons {
        // gap: 20px;
        // margin: 50px 0;
        a {
          // gap: 20px;
          border-radius: 16px;
          padding: 3px 12px;
          // width: calc((100% / 4) - 20px);
          figure {
            height: 35px;
            width: 35px;
            svg {
            }
            img {
            }
          }
          p.h_8 {
          }
        }
      }
      .premium_discover {
        // gap: 12px;
        // width: calc(100% / 2 - (20px / 2));
        // padding: 16px;
        // border-radius: 24px;
        // margin-top: 65px;
        figure {
          // height: 120px;
          // border-radius: 12px;
          img {
          }
        }
        .premium_discover_content {
          // gap: 12px;
          .h_7 {
            span {
              // margin-right: 8px;
            }
          }
          .b_1 {
            span {
              // margin-left: 8px;
            }
          }
        }
        .background_btn.h_8 {
          // border-radius: 1500px;
          // padding: 12px 36px;
        }
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .user_homepage {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      // min-height: 100vh;
      .homepage_buttons {
        gap: 30px;
        // margin: 50px 0;
        a {
          // gap: 20px;
          // border-radius: 16px;
          // padding: 12px;
          width: calc((100% / 4) - (30px));
          figure {
            height: 30px;
            width: 30px;
            svg {
            }
            img {
            }
          }
          p.h_8 {
          }
        }
      }
      .premium_discover {
        // gap: 12px;
        width: calc(100% / 2 - (30px / 2));
        // padding: 16px;
        // border-radius: 24px;
        // margin-top: 65px;
        figure {
          // height: 120px;
          // border-radius: 12px;
          img {
          }
        }
        .premium_discover_content {
          // gap: 12px;
          .h_7 {
            span {
              // margin-right: 8px;
            }
          }
          .b_1 {
            span {
              // margin-left: 8px;
            }
          }
        }
        .background_btn.h_8 {
          // border-radius: 1500px;
          // padding: 12px 36px;
        }
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .user_homepage {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      // min-height: 100vh;
      .homepage_buttons {
        gap: 30px;
        // margin: 50px 0;
        a {
          // gap: 20px;
          border-radius: 14px;
          // padding: 12px;
          width: calc((100% / 2) - (30px / 2));
          figure {
            // height: 35px;
            svg {
            }
            img {
            }
          }
          p.h_8 {
          }
        }
      }
      .premium_discover {
        // gap: 12px;
        width: calc(100%);
        // padding: 16px;
        // border-radius: 24px;
        // margin-top: 65px;
        figure {
          // height: 120px;
          // border-radius: 12px;
          img {
          }
        }
        .premium_discover_content {
          // gap: 12px;
          .h_7 {
            span {
              // margin-right: 8px;
            }
          }
          .b_1 {
            span {
              // margin-left: 8px;
            }
          }
        }
        .background_btn.h_8 {
          // border-radius: 1500px;
          // padding: 12px 36px;
        }
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768
  #root {
    .user_homepage {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      // min-height: 100vh;
      .homepage_buttons {
        // gap: 30px;
        // margin: 50px 0;
        a {
          gap: 18px;
          border-radius: 12px;
          // padding: 12px;
          // width: calc((100% / 2) - (30px / 2));
          figure {
            height: 28px;
            width: 28px;
            svg {
            }
            img {
            }
          }
          p.h_8 {
          }
        }
      }
      .premium_discover {
        // gap: 12px;
        // width: calc(100%);
        // padding: 16px;
        // border-radius: 24px;
        // margin-top: 65px;
        figure {
          // height: 120px;
          // border-radius: 12px;
          img {
          }
        }
        .premium_discover_content {
          // gap: 12px;
          .h_7 {
            span {
              // margin-right: 8px;
            }
          }
          .b_1 {
            span {
              // margin-left: 8px;
            }
          }
        }
        .background_btn.h_8 {
          // border-radius: 1500px;
          // padding: 12px 36px;
        }
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    .user_homepage {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      // min-height: 100vh;
      .homepage_buttons {
        gap: 20px;
        // margin: 50px 0;
        a {
          // gap: 20px;
          // border-radius: 16px;
          // padding: 12px;
          width: calc(100%);
          figure {
            // height: 35px;
            svg {
            }
            img {
            }
          }
          p.h_8 {
          }
        }
      }
      .premium_discover {
        // gap: 12px;
        // width: calc(100%);
        // padding: 16px;
        // border-radius: 24px;
        // margin-top: 65px;
        figure {
          // height: 120px;
          // border-radius: 12px;
          img {
          }
        }
        .premium_discover_content {
          // gap: 12px;
          .h_7 {
            span {
              // margin-right: 8px;
            }
          }
          .b_1 {
            span {
              // margin-left: 8px;
            }
          }
        }
        .background_btn.h_8 {
          // border-radius: 1500px;
          // padding: 12px 36px;
        }
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  #root {
    .user_homepage {
      margin: 0;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      // min-height: 100vh;
      .homepage_buttons {
        // gap: 30px;
        // margin: 50px 0;
        a {
          // gap: 20px;
          // border-radius: 16px;
          // padding: 12px;
          width: calc(100%);
          figure {
            // height: 35px;
            svg {
            }
            img {
            }
          }
          p.h_8 {
          }
        }
      }
      .premium_discover {
        // gap: 12px;
        // width: calc(100%);
        // padding: 16px;
        // border-radius: 24px;
        // margin-top: 65px;
        figure {
          // height: 120px;
          // border-radius: 12px;
          img {
          }
        }
        .premium_discover_content {
          // gap: 12px;
          .h_7 {
            span {
              // margin-right: 8px;
            }
          }
          .b_1 {
            span {
              // margin-left: 8px;
            }
          }
        }
        .background_btn.h_8 {
          // border-radius: 1500px;
          // padding: 12px 36px;
        }
      }
    }
  }
}
