$color: (
  // "primary/100": #b7dbf8,
  // "primary/200": #6fb8f0,
  // "primary/300": #345dff,
  "primary/300": #345dff,
  "primary/400": #224eff,
  "primary/500": #0c3dff,
  "primary/800": #08164e,
  "primary/alpha/50": #345dff80,
  "primary/alpha/10": #345dff1a,
  // "secondary/100": #7581f0,
  // "secondary/200": #3042e9,
  // "secondary/300": #1423b9,
  // "secondary/400": #0c1674,
  // "secondary/alpha/10": #05092f1a,
  // "neutral/100": #ffffff,
  "neutral/100": #fdfdff,
  "neutral/200": #e7e7ed,
  "neutral/300": #c9c9ce,
  "neutral/400": #b0b0b4,
  // "neutral/500": #a4a4a4,
  "neutral/600": #858588,
  "neutral/700": #757578,
  "neutral/800": #5e5e62,
  "neutral/900": #49494d,
  "neutral/1000": #373739,
  "neutral/alpha/40": #37373980,
  // "red/100": #fb3748,
  "red/200": #d00416,
  "red/alpha/50": #d0041680,
  "red/alpha/40": #d0041666,
  "red/alpha/25": #d0041640,
  "yellow/200": #ffdb43,
  "yellow/300": #dfb400,
  // "yellow/alpha/10": #ffdb431a,
  // "green/200": #84ebb4,
  "green/300": #1fc16b,
  "green/alpha/50": #1fc16b80,
  "green/alpha/25": #1fc16b40,
  "footer": #1a4573,
  "footer/alpha/50": #1a457380,
  "footer/alpha/25": #1a457340,
  "dark/100": #040814,
  "dark/alpha/50": #04081480,
  "dark/alpha/25": #04081440,
  "dark/alpha/10": #0408141a,
  "gold/100": #c9b037,
  "gold/200": #695100,
  "silver/100": #b4b4b4,
  "silver/200": #555555,
  "bronze/100": #ad8a56,
  "bronze/200": #8b4000
);
$font: "Murecho", sans-serif;
$transition: 300ms;
:root {
  --main-gap: 12vw;
}

@include mediaQuery(1920px) {
  // 1440
  :root {
    --main-gap: 10vw;
  }
}

@include mediaQuery(1440px) {
  // 1280
  :root {
    --main-gap: 8vw;
  }
}

@include mediaQuery(1280px) {
  // 1024
}

@include mediaQuery(1024px) {
  // 768
  :root {
    --main-gap: 6vw;
  }
}

@include mediaQuery(768px) {
  // 480
  :root {
    --main-gap: 5vw;
  }
}

@include mediaQuery(480px) {
  // 300
  :root {
    --main-gap: 3vw;
  }
}
