#root {
  .packages_page {
    margin-top: 75px;
    padding: 30px var(--main-gap) 100px var(--main-gap);
    min-height: 100vh;
    .packages_hood {
      display: flex;
      flex-direction: column;
      gap: 25px;
      text-align: center;
      margin-bottom: 25px;
      .h_5 {
      }
      .h_8 {
      }
    }
    .packages_filter {
      margin-bottom: 40px;
      .filter {
        display: flex;
        align-items: stretch;
        gap: 15px;
        position: relative;
        .week,
        .status {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 5px;
        }
      }
      .order {
      }
    }
    .package_items {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 35px;
    }
  }
  .no_package {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    text-align: center;
    margin-top: 50px;
    .h_3 {
      color: map-get($color, "neutral/900");
    }
    .h_8 {
      color: map-get($color, "neutral/900");
    }
    .outline_btn {
      // opacity: 0.8;
      border-radius: 18px;
      padding: 2px 16px;
      color: map-get($color, "primary/300");
    }
  }
  .package_item {
    background-color: map-get($color, "neutral/100");
    border: 3px solid map-get($color, "neutral/alpha/40");
    @include box_shadow(map-get($color, "dark/alpha/25"));
    border-radius: 16px;
    width: 100%;
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      .package_info {
        display: flex;
        align-items: center;
        gap: 70px;
        @include fill_available;
        .b_1 {
          color: map-get($color, "dark/100");
          overflow: hidden;
          width: calc(100% / 11);
          &.date {
          }
          &.id,
          &.correct_prediction {
            color: map-get($color, "primary/300");
            // width: calc(100% / 11);
          }
          &.correct_prediction {
            width: calc((100% / 11) * 1.5);
          }
          &.point {
            color: map-get($color, "green/300");
            // width: calc((100% / 11));
          }
          &.completed {
            width: fit-content;
          }
        }
      }
      figure {
        width: 15px;
        svg {
          width: 100%;
          height: 100%;
          fill: map-get($color, "dark/100");
        }
      }
    }
    &.active {
      border-color: map-get($color, "primary/alpha/50");
    }
  }
  .pagination {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    background-color: map-get($color, "neutral/100");
    border-radius: 1500px;
    @include box_shadow(map-get($color, "dark/alpha/50"));
    padding: 10px 20px;
    button {
      background: none;
    }
    .arrow_btn {
      figure {
        height: 20px;
        svg {
          width: 100%;
          height: 100%;
          fill: map-get($color, "neutral/800");
        }
      }
    }
    .b_3 {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: map-get($color, "neutral/800");
      &.active {
        background-color: map-get($color, "primary/300");
        color: map-get($color, "neutral/100");
      }
    }
  }
}
