#root {
  .about_page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 50px;
    padding: 50px 0;
    img {
      width: 25%;
    }
    p {
      width: 50%;
      text-align: center;
      span {
        display: block;
        // text-align: start;
      }
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .about_page {
      //   gap: 50px;
      img {
        // width: 25%;
      }
      p {
        // width: 50%;
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .about_page {
      //   gap: 50px;
      img {
        // width: 25%;
      }
      p {
        // width: 50%;
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .about_page {
      //   gap: 50px;
      img {
        // width: 25%;
      }
      p {
        width: 60%;
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768
  #root {
    .about_page {
      //   gap: 50px;
      img {
        width: 40%;
      }
      p {
        // width: 50%;
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    .about_page {
      //   gap: 50px;
      img {
        // width: 25%;
      }
      p {
        width: 75%;
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  #root {
    .about_page {
      //   gap: 50px;
      img {
        // width: 25%;
      }
      p {
        // width: 50%;
      }
    }
  }
}
