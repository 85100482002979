:root {
  --main-gap: 12vw;
}

@media screen and (max-width: 1920px) {
  :root {
    --main-gap: 10vw;
  }
}
@media screen and (max-width: 1440px) {
  :root {
    --main-gap: 8vw;
  }
}
@media screen and (max-width: 1024px) {
  :root {
    --main-gap: 6vw;
  }
}
@media screen and (max-width: 768px) {
  :root {
    --main-gap: 5vw;
  }
}
@media screen and (max-width: 480px) {
  :root {
    --main-gap: 3vw;
  }
}
.h_1 {
  font-family: "Murecho", sans-serif;
  font-size: 5.25rem;
  line-height: 7.8125rem;
  color: #040814;
}

.h_2 {
  font-family: "Murecho", sans-serif;
  font-size: 4.375rem;
  line-height: 6.5rem;
  color: #040814;
}

.h_3 {
  font-family: "Murecho", sans-serif;
  font-size: 3.625rem;
  line-height: 5.375rem;
  color: #040814;
}

.h_4 {
  font-family: "Murecho", sans-serif;
  font-size: 3rem;
  line-height: 4.375rem;
  color: #040814;
}

.h_5 {
  font-family: "Murecho", sans-serif;
  font-size: 2.5rem;
  line-height: 3.4375rem;
  color: #040814;
}

.h_6 {
  font-family: "Murecho", sans-serif;
  font-size: 2.125rem;
  line-height: 3.125rem;
  color: #040814;
}

.h_7 {
  font-family: "Murecho", sans-serif;
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: #040814;
}

.h_8, .alertify .ajs-dialog .ajs-header {
  font-family: "Murecho", sans-serif;
  font-size: 1.5rem;
  line-height: 2.1875rem;
  color: #040814;
}

.b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
  font-family: "Murecho", sans-serif;
  font-size: 1.1875rem;
  line-height: 1.75rem;
  color: #040814;
}

.b_2 {
  font-family: "Murecho", sans-serif;
  font-size: 1.0625rem;
  line-height: 1.5625rem;
  color: #040814;
}

.b_3 {
  font-family: "Murecho", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.375rem;
  color: #040814;
}

.b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
  font-family: "Murecho", sans-serif;
  font-size: 0.8125rem;
  line-height: 1.1875rem;
  color: #040814;
}

.--r, .b_1, .alertify .ajs-dialog .ajs-body .ajs-content, .b_2, .b_3, .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
  font-weight: 400;
}

.--m, .h_4, .h_5, .h_6, .h_7, .h_8, .alertify .ajs-dialog .ajs-header {
  font-weight: 500;
}

.--b, #root .verify-code-inputs .vi__container .vi__character, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .h_2, .h_3 {
  font-weight: 600;
}

.--bb, .alertify .ajs-dialog .ajs-footer .ajs-ok, .h_1 {
  font-weight: 700;
}

@media screen and (max-width: 1920px) {
  .h_1 {
    font-size: 5.125rem;
    line-height: 7.6875rem;
  }
  .h_2 {
    font-size: 4.25rem;
    line-height: 6.375rem;
  }
  .h_3 {
    font-size: 3.5rem;
    line-height: 5.25rem;
  }
  .h_4 {
    font-size: 2.875rem;
    line-height: 4.25rem;
  }
  .h_5 {
    font-size: 2.375rem;
    line-height: 3.5625rem;
  }
  .h_6 {
    font-size: 2rem;
    line-height: 3rem;
  }
  .h_7 {
    font-size: 1.625rem;
    line-height: 2.375rem;
  }
  .h_8, .alertify .ajs-dialog .ajs-header {
    font-size: 1.375rem;
    line-height: 2.0625rem;
  }
  .b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }
  .b_2 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .b_3 {
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }
  .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
}
@media screen and (max-width: 1440px) {
  .h_1 {
    font-size: 5rem;
    line-height: 7.5625rem;
  }
  .h_2 {
    font-size: 4.125rem;
    line-height: 6.25rem;
  }
  .h_3 {
    font-size: 3.375rem;
    line-height: 5.125rem;
  }
  .h_4 {
    font-size: 2.75rem;
    line-height: 4.125rem;
  }
  .h_5 {
    font-size: 2.25rem;
    line-height: 3.4375rem;
  }
  .h_6 {
    font-size: 1.875rem;
    line-height: 2.875rem;
  }
  .h_7 {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  .h_8, .alertify .ajs-dialog .ajs-header {
    font-size: 1.25rem;
    line-height: 1.9375rem;
  }
  .b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
    font-size: 1.0625rem;
    line-height: 1.625rem;
  }
  .b_2 {
    font-size: 0.9375rem;
    line-height: 1.4375rem;
  }
  .b_3 {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }
  .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
    font-size: 0.6875rem;
    line-height: 1.0625rem;
  }
}
@media screen and (max-width: 1280px) {
  .h_1 {
    font-size: 4.875rem;
    line-height: 7.4375rem;
  }
  .h_2 {
    font-size: 4rem;
    line-height: 6.125rem;
  }
  .h_3 {
    font-size: 3.25rem;
    line-height: 5rem;
  }
  .h_4 {
    font-size: 2.625rem;
    line-height: 4rem;
  }
  .h_5 {
    font-size: 2.125rem;
    line-height: 3.3125rem;
  }
  .h_6 {
    font-size: 1.75rem;
    line-height: 2.75rem;
  }
  .h_7 {
    font-size: 1.375rem;
    line-height: 2.125rem;
  }
  .h_8, .alertify .ajs-dialog .ajs-header {
    font-size: 1.125rem;
    line-height: 1.8125rem;
  }
  .b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
    font-size: 1rem;
    line-height: 1.5625rem;
  }
  .b_2 {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  .b_3 {
    font-size: 0.75rem;
    line-height: 1.1875rem;
  }
  .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
    font-size: 0.625rem;
    line-height: 1rem;
  }
}
@media screen and (max-width: 1024px) {
  .h_1 {
    font-size: 4.75rem;
    line-height: 7.3125rem;
  }
  .h_2 {
    font-size: 3.875rem;
    line-height: 6rem;
  }
  .h_3 {
    font-size: 3.125rem;
    line-height: 4.875rem;
  }
  .h_4 {
    font-size: 2.5rem;
    line-height: 3.875rem;
  }
  .h_5 {
    font-size: 2rem;
    line-height: 3.1875rem;
  }
  .h_6 {
    font-size: 1.625rem;
    line-height: 2.625rem;
  }
  .h_7 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .h_8, .alertify .ajs-dialog .ajs-header {
    font-size: 1rem;
    line-height: 1.6875rem;
  }
  .b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
    font-size: 0.9375rem;
    line-height: 1.5rem;
  }
  .b_2 {
    font-size: 0.8125rem;
    line-height: 1.3125rem;
  }
  .b_3 {
    font-size: 0.6875rem;
    line-height: 1.125rem;
  }
  .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
    font-size: 0.5625rem;
    line-height: 0.9375rem;
  }
}
@media screen and (max-width: 768px) {
  .h_1 {
    font-size: 4.5rem;
    line-height: 7.0625rem;
  }
  .h_2 {
    font-size: 3.625rem;
    line-height: 5.75rem;
  }
  .h_3 {
    font-size: 2.875rem;
    line-height: 4.625rem;
  }
  .h_4 {
    font-size: 2.25rem;
    line-height: 3.625rem;
  }
  .h_5 {
    font-size: 1.875rem;
    line-height: 3.0625rem;
  }
  .h_6 {
    font-size: 1.625rem;
    line-height: 2.625rem;
  }
  .h_7 {
    font-size: 1.25rem;
    line-height: 2rem;
  }
  .h_8, .alertify .ajs-dialog .ajs-header {
    font-size: 1rem;
    line-height: 1.6875rem;
  }
  .b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
    font-size: 0.875rem;
    line-height: 1.375rem;
  }
  .b_2 {
    font-size: 0.75rem;
    line-height: 1.1875rem;
  }
  .b_3 {
    font-size: 0.625rem;
    line-height: 1.0625rem;
  }
  .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
    font-size: 0.5rem;
    line-height: 0.875rem;
  }
}
@media screen and (max-width: 480px) {
  .h_1 {
    font-size: 3.4375rem;
    line-height: 6.6875rem;
  }
  .h_2 {
    font-size: 3.375rem;
    line-height: 5.5rem;
  }
  .h_3 {
    font-size: 2.75rem;
    line-height: 4.5rem;
  }
  .h_4 {
    font-size: 2.125rem;
    line-height: 3.5rem;
  }
  .h_5 {
    font-size: 1.75rem;
    line-height: 2.9375rem;
  }
  .h_6 {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }
  .h_7 {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  .h_8, .alertify .ajs-dialog .ajs-header {
    font-size: 0.875rem;
    line-height: 1.5625rem;
  }
  .b_1, .alertify .ajs-dialog .ajs-body .ajs-content {
    font-size: 0.8125rem;
    line-height: 1.25rem;
  }
  .b_2 {
    font-size: 0.6875rem;
    line-height: 1.1875rem;
  }
  .b_3 {
    font-size: 0.5625rem;
    line-height: 1rem;
  }
  .b_4, .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .ajs-ok {
    font-size: 0.4375rem;
    line-height: 0.8125rem;
  }
}
.alertify .ajs-dialog {
  overflow: hidden;
  border-radius: 16px;
}
.alertify .ajs-dialog .ajs-footer .ajs-ok {
  display: inline-block !important;
  padding: 10px !important;
}
.alertify .ajs-dialog .ajs-footer .ajs-cancel {
  display: inline-block !important;
  padding: 10px !important;
  color: #d00416 !important;
  border-color: #d00416 !important;
}

.alertify-notifier .ajs-error {
  border-radius: 6px;
  background: #d00416 !important;
  color: #fdfdff;
}

.achievement_card {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 3px solid #040814;
  border-radius: 12px;
  padding: 8px;
  background-color: #fdfdff;
  color: #5e5e62;
}
.achievement_card.block {
  filter: grayscale(100%);
  cursor: not-allowed;
  box-shadow: 0 0 0 0 !important;
}
.achievement_card.gold {
  border-color: #c9b037;
  box-shadow: 0px 5px 16px 0px #c9b037;
}
.achievement_card.silver {
  border-color: #b4b4b4;
  box-shadow: 0px 5px 16px 0px #b4b4b4;
}
.achievement_card.bronze {
  border-color: #ad8a56;
  box-shadow: 0px 5px 16px 0px #ad8a56;
}
.achievement_card figure {
  width: 48px;
  overflow: hidden;
}
.achievement_card figure img {
  width: 100%;
  height: 100%;
}
.achievement_card .card_content {
  width: 100%;
}
.achievement_card .card_content .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.achievement_card .card_content .bottom .b_2 {
  color: #1fc16b;
}
.third_party_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 12px;
  background-color: #fdfdff;
}
.third_party_btns svg {
  width: 30px;
  height: 30px;
}
.third_party_btns.btn_apple {
  fill: #000;
}
.third_party_btns.btn_google path:nth-child(1) {
  fill: #4285f4;
}
.third_party_btns.btn_google path:nth-child(2) {
  fill: #34a853;
}
.third_party_btns.btn_google path:nth-child(3) {
  fill: #fbbc05;
}
.third_party_btns.btn_google path:nth-child(4) {
  fill: #ea4335;
}
.third_party_btns.btn_facebook svg {
  fill: #0866ff;
}

.background_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #fdfdff;
  background: #345dff;
  border: 1px solid #345dff;
  padding: 13px 10px;
  border-radius: 12px;
  transition: 300ms all;
}
.background_btn h4 {
  color: #fdfdff;
}
.background_btn .btn-loader {
  display: none;
}
.background_btn:hover {
  background: #224eff;
  border-color: #224eff;
  box-shadow: 0px 4px 4px 0px rgba(4, 8, 20, 0.2509803922);
}
.background_btn:disabled, .background_btn[disabled] {
  cursor: not-allowed;
  background: #c9c9ce;
  border-color: #c9c9ce;
  color: #040814;
}
.background_btn:disabled:hover, .background_btn[disabled]:hover {
  box-shadow: 0 0 0 0;
}
.background_btn:disabled:active, .background_btn[disabled]:active {
  border: 3px solid #c9c9ce;
}
.background_btn:active {
  border: 1px solid rgba(4, 8, 20, 0.5019607843);
  box-shadow: 0 0 0 0;
}
.background_btn[data-loader=true] {
  cursor: not-allowed;
  background: #345dff;
  border: 1px solid rgba(4, 8, 20, 0.5019607843);
  color: #fdfdff;
}
.background_btn[data-loader=true] .btn-loader {
  display: inline-block;
}
.background_btn[data-loader=true]:hover {
  box-shadow: 0 0 0 0;
}
.background_btn[data-loader=true]:active {
  border: 1px solid rgba(4, 8, 20, 0.5019607843);
}

.outline_btn, .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer .ajs-cancel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: #345dff;
  border: 3px solid #345dff;
  padding: 13px 10px;
  border-radius: 12px;
  background-color: #fdfdff;
  transition: 300ms all;
}
.outline_btn .btn-loader, .alertify .ajs-dialog .ajs-footer .ajs-ok .btn-loader, .alertify .ajs-dialog .ajs-footer .ajs-cancel .btn-loader {
  display: none;
  border-color: #345dff !important;
}
.outline_btn:hover, .alertify .ajs-dialog .ajs-footer .ajs-ok:hover, .alertify .ajs-dialog .ajs-footer .ajs-cancel:hover {
  box-shadow: 4px 4px 4px 0px rgba(4, 8, 20, 0.2509803922);
}
.outline_btn:disabled, .alertify .ajs-dialog .ajs-footer .ajs-ok:disabled, .alertify .ajs-dialog .ajs-footer .ajs-cancel:disabled, .outline_btn[disabled], .alertify .ajs-dialog .ajs-footer [disabled].ajs-ok, .alertify .ajs-dialog .ajs-footer [disabled].ajs-cancel {
  cursor: not-allowed;
  border-color: #b0b0b4;
  color: #b0b0b4;
}
.outline_btn:disabled:hover, .alertify .ajs-dialog .ajs-footer .ajs-ok:disabled:hover, .alertify .ajs-dialog .ajs-footer .ajs-cancel:disabled:hover, .outline_btn[disabled]:hover, .alertify .ajs-dialog .ajs-footer [disabled].ajs-ok:hover, .alertify .ajs-dialog .ajs-footer [disabled].ajs-cancel:hover {
  box-shadow: 0 0 0 0;
}
.outline_btn:disabled:active, .alertify .ajs-dialog .ajs-footer .ajs-ok:disabled:active, .alertify .ajs-dialog .ajs-footer .ajs-cancel:disabled:active, .outline_btn[disabled]:active, .alertify .ajs-dialog .ajs-footer [disabled].ajs-ok:active, .alertify .ajs-dialog .ajs-footer [disabled].ajs-cancel:active {
  background: none;
}
.outline_btn:active, .alertify .ajs-dialog .ajs-footer .ajs-ok:active, .alertify .ajs-dialog .ajs-footer .ajs-cancel:active {
  background-color: #fdfdff;
  box-shadow: 0 0 0 0;
}
.outline_btn[data-loader=true], .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-cancel {
  cursor: not-allowed;
  background: #fdfdff;
  border-color: #345dff;
  color: #345dff;
}
.outline_btn[data-loader=true] .btn-loader, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok .btn-loader, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-cancel .btn-loader {
  display: inline-block;
}
.outline_btn[data-loader=true]:hover, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok:hover, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-cancel:hover {
  box-shadow: 0 0 0 0;
}
.outline_btn[data-loader=true]:active, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-ok:active, .alertify .ajs-dialog .ajs-footer [data-loader=true].ajs-cancel:active {
  border: 1px solid #b0b0b4;
}

@media screen and (max-width: 1440px) {
  .third_party_btns {
    padding: 10px;
  }
  .outline_btn, .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer .ajs-cancel {
    border: 2px solid #345dff;
  }
}
@media screen and (max-width: 1280px) {
  .third_party_btns svg {
    width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 1024px) {
  .third_party_btns {
    padding: 8px;
  }
}
@media screen and (max-width: 768px) {
  .third_party_btns {
    border-radius: 10px;
  }
  .third_party_btns svg {
    width: 20px;
    height: 20px;
  }
}
@media screen and (max-width: 480px) {
  .third_party_btns {
    padding: 5px;
    border-radius: 8px;
  }
  .third_party_btns svg {
    width: 15px;
    height: 15px;
  }
  .outline_btn, .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer .ajs-cancel {
    border: 1px solid #345dff;
  }
}
#root .custom-checkbox {
  display: block;
  position: relative;
  padding-left: 20px;
  cursor: pointer;
}
#root .custom-checkbox input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
#root .custom-checkbox input[type=checkbox]:checked ~ .checkmark {
  background-color: #e7e7ed;
}
#root .custom-checkbox input[type=checkbox]:checked ~ .checkmark svg {
  display: block;
}
#root .custom-checkbox .checkmark {
  position: absolute;
  top: calc(50% - 8px);
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: #c9c9ce;
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .custom-checkbox .checkmark svg {
  position: absolute;
  display: none;
  width: 10px;
}
#root .custom-checkbox:hover .checkmark {
  background-color: #b0b0b4;
}

#root .custom-dropdown {
  position: relative;
}
#root .custom-dropdown .disabled {
  display: none;
}
#root .custom-dropdown .select-trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 12px;
  padding: 5px 8px;
  background-color: #fdfdff;
  cursor: pointer;
}
#root .custom-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 25px;
}
#root .custom-dropdown .select-trigger .content-drop .img-drop {
  width: 60px;
  height: 60px;
  overflow: hidden;
}
#root .custom-dropdown .select-trigger .content-drop .img-drop img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#root .custom-dropdown .select-trigger .arrow {
  padding: 0 32px;
  transform: rotate(0deg);
  transition: transform 0.3s;
}
#root .custom-dropdown .select-trigger .arrow svg {
  width: 32px;
  height: 32px;
}
#root .custom-dropdown .select-trigger .arrow.open {
  transform: rotate(-180deg);
}
#root .custom-dropdown .select-trigger.open {
  border-radius: 12px 12px 0 0;
}
#root .custom-dropdown .options {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fdfdff;
  display: block;
  z-index: 2;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 0 0 12px 12px;
  border-top: 0;
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
}
#root .custom-dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 5px 8px;
  gap: 25px;
  border-bottom: 2px solid rgba(4, 8, 20, 0.2509803922);
  cursor: pointer;
}
#root .custom-dropdown .options .option .img-drop {
  width: 60px;
  height: 60px;
}
#root .custom-dropdown .options .option .img-drop img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#root .custom-dropdown .options .option:nth-last-child(1) {
  border-bottom: 0;
}
#root .custom-dropdown.disabled .disabled {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 57, 0.5019607843);
  border-radius: 12px;
  z-index: 1;
  cursor: no-drop;
}
#root .phone-dropdown {
  position: relative;
}
#root .phone-dropdown .select-trigger {
  width: -moz-max-content;
  width: max-content;
  display: flex;
  align-items: center;
  border-radius: 8px 0 0 8px;
  padding: 15px 8px;
  background-color: #fdfdff;
  cursor: pointer;
}
#root .phone-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  gap: 12px;
}
#root .phone-dropdown .select-trigger .content-drop .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .phone-dropdown .select-trigger .content-drop .img-drop img {
  width: 100%;
  height: 100%;
}
#root .phone-dropdown .select-trigger .arrow {
  transform: rotate(0deg);
  transition: transform 0.3s;
}
#root .phone-dropdown .select-trigger .arrow svg {
  width: 16px;
  height: 16px;
}
#root .phone-dropdown .select-trigger .arrow.open {
  transform: rotate(-180deg);
}
#root .phone-dropdown .options {
  display: none;
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  background-color: #fdfdff;
  display: block;
  z-index: 2;
  border-radius: 0 0 4px 4px;
  min-height: 60px;
  max-height: 120px;
  overflow: auto;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  -webkit-clip-path: inset(0px -60px -60px -60px);
          clip-path: inset(0px -60px -60px -60px);
}
#root .phone-dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 15px 8px;
  gap: 12px;
  justify-content: space-between;
  border-bottom: 2px solid rgba(52, 93, 255, 0.1019607843);
  cursor: pointer;
}
#root .phone-dropdown .options .option .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .phone-dropdown .options .option .img-drop img {
  width: 100%;
  height: 100%;
}
#root .phone-dropdown .options .option:nth-last-child(1) {
  border-bottom: 0;
}
#root .packages-dropdown,
#root .week-dropdown,
#root .month-dropdown,
#root .leagues-dropdown,
#root .periods-dropdown {
  width: 15vw;
  position: relative;
}
#root .packages-dropdown .select-trigger,
#root .week-dropdown .select-trigger,
#root .month-dropdown .select-trigger,
#root .leagues-dropdown .select-trigger,
#root .periods-dropdown .select-trigger {
  display: flex;
  align-items: center;
  background-color: #fdfdff;
  border-radius: 8px;
  padding: 10px 15px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  cursor: pointer;
}
#root .packages-dropdown .select-trigger .content-drop,
#root .week-dropdown .select-trigger .content-drop,
#root .month-dropdown .select-trigger .content-drop,
#root .leagues-dropdown .select-trigger .content-drop,
#root .periods-dropdown .select-trigger .content-drop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}
#root .packages-dropdown .select-trigger .content-drop .arrow,
#root .week-dropdown .select-trigger .content-drop .arrow,
#root .month-dropdown .select-trigger .content-drop .arrow,
#root .leagues-dropdown .select-trigger .content-drop .arrow,
#root .periods-dropdown .select-trigger .content-drop .arrow {
  width: 8px;
  display: flex;
  align-items: center;
}
#root .packages-dropdown .select-trigger .content-drop .arrow svg,
#root .week-dropdown .select-trigger .content-drop .arrow svg,
#root .month-dropdown .select-trigger .content-drop .arrow svg,
#root .leagues-dropdown .select-trigger .content-drop .arrow svg,
#root .periods-dropdown .select-trigger .content-drop .arrow svg {
  width: 100%;
  height: 100%;
}
#root .packages-dropdown .select-trigger.open,
#root .week-dropdown .select-trigger.open,
#root .month-dropdown .select-trigger.open,
#root .leagues-dropdown .select-trigger.open,
#root .periods-dropdown .select-trigger.open {
  border-radius: 8px 8px 0 0;
}
#root .packages-dropdown .options,
#root .week-dropdown .options,
#root .month-dropdown .options,
#root .leagues-dropdown .options,
#root .periods-dropdown .options {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fdfdff;
  display: block;
  z-index: 3;
  border-radius: 0 0 8px 8px;
  min-height: 60px;
  max-height: 210px;
  overflow: auto;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  -webkit-clip-path: inset(0px -60px -60px -60px);
          clip-path: inset(0px -60px -60px -60px);
}
#root .packages-dropdown .options .option,
#root .week-dropdown .options .option,
#root .month-dropdown .options .option,
#root .leagues-dropdown .options .option,
#root .periods-dropdown .options .option {
  display: flex;
  align-items: center;
  padding: 15px 8px;
  gap: 12px;
  justify-content: space-between;
  border-bottom: 2px solid rgba(52, 93, 255, 0.1019607843);
  cursor: pointer;
}
#root .packages-dropdown .options .option .img-drop,
#root .week-dropdown .options .option .img-drop,
#root .month-dropdown .options .option .img-drop,
#root .leagues-dropdown .options .option .img-drop,
#root .periods-dropdown .options .option .img-drop {
  width: 28px;
  height: 20px;
  border-radius: 2px;
  overflow: hidden;
}
#root .packages-dropdown .options .option .img-drop img,
#root .week-dropdown .options .option .img-drop img,
#root .month-dropdown .options .option .img-drop img,
#root .leagues-dropdown .options .option .img-drop img,
#root .periods-dropdown .options .option .img-drop img {
  width: 100%;
  height: 100%;
}
#root .packages-dropdown .options .option:nth-last-child(1),
#root .week-dropdown .options .option:nth-last-child(1),
#root .month-dropdown .options .option:nth-last-child(1),
#root .leagues-dropdown .options .option:nth-last-child(1),
#root .periods-dropdown .options .option:nth-last-child(1) {
  border-bottom: 0;
}

@media screen and (max-width: 1280px) {
  #root .packages-dropdown,
  #root .week-dropdown,
  #root .month-dropdown,
  #root .leagues-dropdown,
  #root .periods-dropdown {
    width: 17vw;
  }
  #root .packages-dropdown .select-trigger,
  #root .week-dropdown .select-trigger,
  #root .month-dropdown .select-trigger,
  #root .leagues-dropdown .select-trigger,
  #root .periods-dropdown .select-trigger {
    border-radius: 6px;
    padding: 8px 15px;
  }
  #root .packages-dropdown .select-trigger.open,
  #root .week-dropdown .select-trigger.open,
  #root .month-dropdown .select-trigger.open,
  #root .leagues-dropdown .select-trigger.open,
  #root .periods-dropdown .select-trigger.open {
    border-radius: 6px 6px 0 0;
  }
  #root .packages-dropdown .options,
  #root .week-dropdown .options,
  #root .month-dropdown .options,
  #root .leagues-dropdown .options,
  #root .periods-dropdown .options {
    border-radius: 0 0 6px 6px;
  }
  #root .packages-dropdown .options .option,
  #root .week-dropdown .options .option,
  #root .month-dropdown .options .option,
  #root .leagues-dropdown .options .option,
  #root .periods-dropdown .options .option {
    padding: 13px 8px;
    border-width: 1px;
  }
}
@media screen and (max-width: 1024px) {
  #root .packages-dropdown,
  #root .week-dropdown,
  #root .month-dropdown,
  #root .leagues-dropdown,
  #root .periods-dropdown {
    width: 23vw;
  }
  #root .packages-dropdown .select-trigger .content-drop .arrow,
  #root .week-dropdown .select-trigger .content-drop .arrow,
  #root .month-dropdown .select-trigger .content-drop .arrow,
  #root .leagues-dropdown .select-trigger .content-drop .arrow,
  #root .periods-dropdown .select-trigger .content-drop .arrow {
    width: 7px;
  }
}
@media screen and (max-width: 768px) {
  #root .packages-dropdown,
  #root .week-dropdown,
  #root .month-dropdown,
  #root .leagues-dropdown,
  #root .periods-dropdown {
    width: 100%;
  }
  #root .packages-dropdown .select-trigger .content-drop .arrow,
  #root .week-dropdown .select-trigger .content-drop .arrow,
  #root .month-dropdown .select-trigger .content-drop .arrow,
  #root .leagues-dropdown .select-trigger .content-drop .arrow,
  #root .periods-dropdown .select-trigger .content-drop .arrow {
    width: 6px;
  }
}
#root .main_ads_or_img {
  margin: 95px 0 70px 0;
  height: 170px;
  border-radius: 12px;
  overflow: hidden;
}
#root .main_ads_or_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 1440px) {
  #root .main_ads_or_img {
    margin: 70px 0;
  }
}
@media screen and (max-width: 1280px) {
  #root .main_ads_or_img {
    margin: 30px 0 50px 0;
    height: 150px;
  }
}
@media screen and (max-width: 1024px) {
  #root .main_ads_or_img {
    margin: 0px 0 50px 0;
    height: 130px;
  }
}
@media screen and (max-width: 768px) {
  #root .main_ads_or_img {
    margin: 0px 0 30px 0;
    height: 100px;
  }
}
@media screen and (max-width: 480px) {
  #root .main_ads_or_img {
    margin: 0;
    height: 80px;
  }
}
#root .phone-input {
  display: flex;
  border-radius: 8px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
}
#root .phone-input input {
  border: none;
  border-radius: 0 8px 8px 0;
  padding: 15px 10px;
}
#root .phone-input:has(.select-trigger.open) {
  border-radius: 8px 8px 8px 0;
}
#root .phone-input.correct {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5019607843);
}
#root .phone-input.correct .phone-dropdown .options {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5019607843);
}
#root .phone-input.wrong {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5019607843);
}
#root .phone-input.wrong .phone-dropdown .options {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5019607843);
}
#root .input {
  min-width: 250px;
  border: none;
  border-radius: 8px;
  padding: 15px 10px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
}
#root .input.correct {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5019607843);
}
#root .input.wrong {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5019607843);
}

.match_component {
  width: calc(50% - 24px);
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 16px;
  background-color: #fdfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 45px 12px;
  gap: 12px;
  position: relative;
}
.match_component.mini_gap {
  width: calc(50% - 18px);
}
.match_component .background_btn.btn--delete_to_basket {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 50%;
  padding: 5px 8px;
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5019607843);
  background-color: #fdfdff;
  border: none;
}
.match_component .background_btn.btn--delete_to_basket .btn-loader {
  border-top: 2px solid #d00416;
}
.match_component .background_btn.btn--delete_to_basket figure {
  width: 14px;
}
.match_component .background_btn.btn--delete_to_basket figure svg {
  width: 100%;
  height: 100%;
  fill: #d00416;
}
.match_component .existing_info {
  position: absolute;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
.match_component .existing_info figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #1fc16b;
  animation: opacity 1s linear infinite;
}
.match_component .existing_info figure p {
  color: #1fc16b;
}
.match_component .existing_info .modal {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 14px 8px;
  border: 1px solid #1fc16b;
  background: #fdfdff;
  border-radius: 4px 4px 4px 0;
  width: -moz-max-content;
  width: max-content;
  color: #1fc16b;
  display: none;
}
.match_component .existing_info:hover figure {
  animation: none;
}
.match_component .existing_info:hover .modal {
  display: inline-block;
}
.match_component .premium {
  position: absolute;
  top: 13px;
  left: 13px;
}
.match_component .premium figure {
  width: 24px;
}
.match_component .premium figure svg {
  width: 100%;
  height: 100%;
  fill: #ffdb43;
  stroke-width: 5%;
  stroke: rgba(4, 8, 20, 0.2509803922);
}
.match_component .match_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: stretch;
  height: -moz-available;
  height: -webkit-fill-available;
}
.match_component .match_content .teams .team_names {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}
.match_component .match_content .teams .team_names .team {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.match_component .match_content .teams .team_names .team.left {
  text-align: left;
}
.match_component .match_content .teams .team_names .team.right {
  text-align: right;
}
.match_component .match_content .teams .team_names .team .h_8, .match_component .match_content .teams .team_names .team .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog .match_component .match_content .teams .team_names .team .ajs-header {
  color: #49494d;
}
.match_component .match_content .teams .team_names .team .team_last_matches {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.match_component .match_content .teams .team_names .team .team_last_matches .b_4, .match_component .match_content .teams .team_names .team .team_last_matches .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer .match_component .match_content .teams .team_names .team .team_last_matches .ajs-ok, .match_component .match_content .teams .team_names .team .team_last_matches .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer .match_component .match_content .teams .team_names .team .team_last_matches .ajs-cancel {
  color: #49494d;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data {
  display: flex;
  align-items: center;
  gap: 8px;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data .match_info {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid rgba(4, 8, 20, 0.5019607843);
  position: relative;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data .match_info .modal {
  display: none;
  position: absolute;
  top: 12px;
  z-index: 1;
  padding: 8px;
  color: #040814;
  border: 1px solid #040814;
  background: #fdfdff;
  width: -moz-max-content;
  width: max-content;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data .match_info.W {
  background-color: #1fc16b;
  border: 1px solid #1fc16b;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data .match_info.L {
  background-color: #d00416;
  border: 1px solid #d00416;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data .match_info.D {
  background-color: #dfb400;
  border: 1px solid #dfb400;
}
.match_component .match_content .teams .team_names .team .team_last_matches .data .match_info:hover .modal {
  display: inline-block;
}
.match_component .match_content .teams .team_names .team .team_last_matches.left {
  text-align: left;
  align-items: flex-start;
}
.match_component .match_content .teams .team_names .team .team_last_matches.left .modal {
  border-radius: 0px 4px 4px 4px;
  left: 3px;
  text-align: left;
}
.match_component .match_content .teams .team_names .team .team_last_matches.right {
  text-align: right;
  align-items: flex-end;
}
.match_component .match_content .teams .team_names .team .team_last_matches.right .modal {
  right: 3px;
  border-radius: 4px 0px 4px 4px;
  text-align: right;
}
.match_component .match_content .teams .team_names span.vs {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.match_component .match_content .teams .team_names span.vs p,
.match_component .match_content .teams .team_names span.vs .h_8,
.match_component .match_content .teams .team_names span.vs .alertify .ajs-dialog .ajs-header,
.alertify .ajs-dialog .match_component .match_content .teams .team_names span.vs .ajs-header {
  text-align: center;
  color: #49494d;
}
.match_component .match_content .statistics {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 3px solid rgba(55, 55, 57, 0.5019607843);
  padding: 10px;
  border-radius: 24px;
  margin-top: 25px;
}
.match_component .match_content .statistics .statistic {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
.match_component .match_content .statistics .statistic .b_3 {
  color: #373739;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.match_component .background_btn {
  border-radius: 16px;
  padding: 6px 48px;
}
.match_component .background_btn .b_3 {
  color: #fdfdff;
}
.match_component .background_btn:disabled, .match_component .background_btn[disabled] {
  background: none;
  border-color: #345dff;
}
.match_component .background_btn:disabled .b_3, .match_component .background_btn[disabled] .b_3 {
  color: #345dff;
}
.match_component .selected_and_delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.match_component .selected_and_delete .selected_and_point {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.match_component .selected_and_delete .selected_and_point .prediction_selected {
  flex: 1 1;
}
.match_component .selected_and_delete .selected_and_point .prediction_selected .h_8, .match_component .selected_and_delete .selected_and_point .prediction_selected .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_selected .ajs-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.match_component .selected_and_delete .selected_and_point .prediction_selected .h_8 span, .match_component .selected_and_delete .selected_and_point .prediction_selected .alertify .ajs-dialog .ajs-header span, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_selected .ajs-header span {
  color: #345dff;
}
.match_component .selected_and_delete .selected_and_point .prediction_point {
  flex: 1 1;
}
.match_component .selected_and_delete .selected_and_point .prediction_point .h_8, .match_component .selected_and_delete .selected_and_point .prediction_point .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_point .ajs-header {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.match_component .selected_and_delete .selected_and_point .prediction_point .h_8 span, .match_component .selected_and_delete .selected_and_point .prediction_point .alertify .ajs-dialog .ajs-header span, .alertify .ajs-dialog .match_component .selected_and_delete .selected_and_point .prediction_point .ajs-header span {
  color: #345dff;
}
.match_component .selected_and_delete .delete .background_btn {
  padding: 6px 36px;
}
.match_component .selected_and_delete .delete .background_btn p {
  color: #fdfdff;
}
.match_component.added {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.2509803922);
}
.match_component.added .buttons {
  justify-content: space-between;
}
.match_component.correct {
  box-shadow: 0px 5px 16px 0px rgba(31, 193, 107, 0.5019607843);
}
.match_component.wrong {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.4);
}
.match_component.canceled {
  box-shadow: none;
  background-color: #c9c9ce;
}
.match_component.canceled .match_content .h_8, .match_component.canceled .match_content .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog .match_component.canceled .match_content .ajs-header {
  color: #d00416;
  opacity: 1;
}
.match_component.canceled .match_content .teams {
  opacity: 0.5;
}
.match_component.canceled .match_content .teams .teams_last_matches .team_last_matches .data .match_info:hover .modal {
  display: none;
}
.match_component.canceled .selected_and_delete {
  opacity: 0.5;
}

@media screen and (max-width: 1920px) {
  .match_component {
    width: calc((100% - 48px) / 2);
  }
}
@media screen and (max-width: 1440px) {
  .match_component {
    width: calc((100% - 30px) / 2);
  }
}
@media screen and (max-width: 1280px) {
  .match_component {
    width: 100%;
  }
  .match_component.mini_gap {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .match_component .background_btn.btn--delete_to_basket figure {
    width: 10px;
  }
  .match_component .existing_info figure {
    width: 17px;
    height: 17px;
  }
  .match_component .match_content .statistics {
    padding: 8px;
    border-width: 2px;
    border-radius: 20px;
  }
  .match_component .background_btn {
    padding: 3px 36px;
  }
}
@keyframes opacity {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
#root .modal-message {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(4, 8, 20, 0.2509803922);
  z-index: 99;
}
#root .modal_prediction {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background-color: rgba(4, 8, 20, 0.2509803922);
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .modal_prediction .match {
  width: 40vw;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 12px;
  background-color: #fdfdff;
  overflow: hidden;
}
#root .modal_prediction .match .match_scroll {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;
  overflow: auto;
  max-height: 90vh;
}
#root .modal_prediction .match .match_scroll .modal_close {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  border-radius: 50%;
  padding: 5px 8px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  background-color: #fdfdff;
  cursor: pointer;
}
#root .modal_prediction .match .match_scroll .modal_close figure {
  display: flex;
  width: 14px;
}
#root .modal_prediction .match .match_scroll .modal_close figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(4, 8, 20, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content {
  width: 100%;
}
#root .modal_prediction .match .match_scroll .match_content h5.h_8, #root .modal_prediction .match .match_scroll .match_content .alertify .ajs-dialog h5.ajs-header, .alertify .ajs-dialog #root .modal_prediction .match .match_scroll .match_content h5.ajs-header {
  text-align: center;
  color: rgba(4, 8, 20, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names .team {
  flex: 1 1;
  color: rgba(4, 8, 20, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names .team.left {
  text-align: left;
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names .team.right {
  text-align: right;
}
#root .modal_prediction .match .match_scroll .match_content .teams .team_names span.vs {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(4, 8, 20, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .b_4, #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .ajs-ok, #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .ajs-cancel {
  color: rgba(4, 8, 20, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data {
  display: flex;
  align-items: center;
  gap: 8px;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid rgba(4, 8, 20, 0.5019607843);
  position: relative;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info .modal {
  display: none;
  position: absolute;
  top: 12px;
  z-index: 1;
  padding: 8px;
  color: #040814;
  border: 1px solid #040814;
  background: #fdfdff;
  width: -moz-max-content;
  width: max-content;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info.W {
  background-color: #1fc16b;
  border: 1px solid #1fc16b;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info.L {
  background-color: #d00416;
  border: 1px solid #d00416;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info.D {
  background-color: #dfb400;
  border: 1px solid #dfb400;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info:hover .modal {
  display: inline-block;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.left {
  text-align: left;
  align-items: flex-start;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.left .modal {
  border-radius: 0px 4px 4px 4px;
  left: 3px;
  text-align: left;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.right {
  text-align: right;
  align-items: flex-end;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.right .modal {
  right: 3px;
  border-radius: 4px 0px 4px 4px;
  text-align: right;
}
#root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches.right .data {
  flex-direction: row-reverse;
}
#root .modal_prediction .match .match_scroll .divider {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 15px;
}
#root .modal_prediction .match .match_scroll .divider hr {
  width: 100%;
  height: 1px;
  color: rgba(4, 8, 20, 0.2509803922);
  background-color: rgba(4, 8, 20, 0.2509803922);
}
#root .modal_prediction .match .match_scroll .divider figure {
  width: 50px;
  display: flex;
}
#root .modal_prediction .match .match_scroll .divider figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(4, 8, 20, 0.5019607843);
}
#root .modal_prediction .match .match_scroll .predictions_and_scores {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .option {
  border-radius: 18px;
  cursor: pointer;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .option.active {
  background-color: #345dff;
  color: #fdfdff;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .predictions {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .predictions .option {
  width: 100%;
  padding: 6px 12px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .scores {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .scores .option {
  width: calc(20% - 16px);
  padding: 4px 12px;
}
#root .modal_prediction .match .match_scroll .predictions_and_scores .scores:has(.option:last-child:nth-child(5n)) {
  justify-content: space-between;
}
#root .modal_prediction .match .match_scroll .background_btn {
  border-radius: 20px;
  padding: 8px 48px;
  margin-top: 24px;
}
#root .modal_prediction .match .match_scroll .background_btn .b_3 {
  color: #fdfdff;
}
#root .modal_report_user {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;
  background-color: rgba(4, 8, 20, 0.2509803922);
  display: flex;
  align-items: center;
  justify-content: center;
}
#root .modal_report_user .popup_report_user {
  width: 60vw;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 24px;
  background-color: #fdfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  gap: 12px;
  max-height: 100vh;
  overflow: auto;
}
#root .modal_report_user .popup_report_user .top .user_details {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
#root .modal_report_user .popup_report_user .top .user_details figure {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(4, 8, 20, 0.2509803922);
}
#root .modal_report_user .popup_report_user .top .user_details figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .modal_report_user .popup_report_user .top .report h6.h_6 {
  text-align: center;
  margin: 20px 0;
}
#root .modal_report_user .popup_report_user .top .report .report_type {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#root .modal_report_user .popup_report_user .top .report .report_type div label {
  margin-left: 5px;
}
#root .modal_report_user .popup_report_user .top .report .report_type div label span {
  color: #d00416;
  margin-left: 5px;
}
#root .modal_report_user .popup_report_user .top .report .desc {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 30px 0;
}
#root .modal_report_user .popup_report_user .top .report .desc label span {
  color: #d00416;
  margin-left: 5px;
}
#root .modal_report_user .popup_report_user .top .report .desc textarea {
  resize: none;
  height: 150px;
}
#root .modal_report_user .popup_report_user .buttons {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
}
#root .modal_report_user .popup_report_user .buttons .outline_btn, #root .modal_report_user .popup_report_user .buttons .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .modal_report_user .popup_report_user .buttons .ajs-ok, #root .modal_report_user .popup_report_user .buttons .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .modal_report_user .popup_report_user .buttons .ajs-cancel,
#root .modal_report_user .popup_report_user .buttons .background_btn {
  padding: 7px 25px;
  border-radius: 1500px;
}
#root .modal_report_user .popup_report_user .buttons .background_btn {
  background-color: rgba(208, 4, 22, 0.5019607843);
  border: none;
}

@media screen and (max-width: 1920px) {
  #root .modal_prediction .match {
    width: 50vw;
  }
}
@media screen and (max-width: 1440px) {
  #root .modal_prediction .match {
    width: 60vw;
  }
}
@media screen and (max-width: 1280px) {
  #root .modal_prediction .match {
    width: 70vw;
  }
  #root .modal_prediction .match .match_scroll {
    padding: 16px;
  }
  #root .modal_prediction .match .match_scroll .modal_close {
    padding: 5px 7px;
  }
  #root .modal_prediction .match .match_scroll .modal_close figure {
    width: 10px;
  }
  #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info {
    width: 8px;
    height: 8px;
  }
  #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data .match_info .modal {
    top: 8px;
    padding: 5px;
  }
  #root .modal_prediction .match .match_scroll .divider figure {
    width: 40px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .predictions .option {
    padding: 3px 12px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .scores .option {
    padding: 1px 12px;
  }
  #root .modal_prediction .match .match_scroll .background_btn {
    padding: 5px 48px;
  }
}
@media screen and (max-width: 1024px) {
  #root .modal_prediction .match {
    width: 80vw;
  }
}
@media screen and (max-width: 768px) {
  #root .modal_prediction .match {
    width: 90vw;
  }
  #root .modal_prediction .match .match_scroll {
    max-height: 100vh;
  }
  #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data {
    gap: 6px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .predictions {
    gap: 12px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .scores {
    gap: 12px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .scores .option {
    width: calc(25% - 12px);
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .scores:has(.option:last-child:nth-child(4n)) {
    justify-content: space-between;
  }
  #root .modal_prediction .match .match_scroll .background_btn {
    margin-top: 16px;
  }
}
@media screen and (max-width: 480px) {
  #root .modal_prediction .match {
    width: 100vw;
    border-radius: 8px;
  }
  #root .modal_prediction .match .match_scroll {
    padding: 8px;
    gap: 5px;
  }
  #root .modal_prediction .match .match_scroll .modal_close {
    padding: 3px 4px;
  }
  #root .modal_prediction .match .match_scroll .modal_close figure {
    width: 7px;
  }
  #root .modal_prediction .match .match_scroll .match_content .teams .teams_last_matches .team_last_matches .data {
    gap: 3px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores {
    gap: 6px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .predictions {
    gap: 6px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .predictions .option {
    padding: 1px 12px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .scores {
    gap: 6px;
  }
  #root .modal_prediction .match .match_scroll .predictions_and_scores .scores .option {
    width: calc(25% - 6px);
    padding: 0px 12px;
  }
}
.mini-loader {
  background-color: #fdfdff;
}
.mini-loader .loader {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  font-size: 0;
  display: inline-block;
  margin: -25px 0 0 -25px;
  text-indent: -9999em;
  transform: translateZ(0);
}
.mini-loader .loader div {
  background-color: #1a4573;
  display: inline-block;
  float: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  opacity: 0.5;
  border-radius: 50%;
  animation: mini-loader 2s ease-in-out infinite;
}
.mini-loader .loader div:last-child {
  animation-delay: -1s;
}

.btn-loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fdfdff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: btn-loader 1s linear infinite;
}
.btn-loader.m {
  width: 20px;
  height: 20px;
}
.btn-loader.s {
  width: 15px;
  height: 15px;
  border-top: 2px solid #fdfdff;
  border-right: 2px solid transparent;
}

@keyframes mini-loader {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
@keyframes btn-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.progress_bar {
  position: relative;
  width: 100px;
  height: 10px;
  border: 1px solid rgba(4, 8, 20, 0.2509803922);
  border-radius: 50px;
  overflow: hidden;
}
.progress_bar .progress {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  height: 100%;
  background-color: #345dff;
}

@media screen and (max-width: 1024px) {
  .progress_bar {
    width: 80px;
    height: 6px;
  }
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
  list-style-type: none;
  outline: none;
}

body {
  font-family: "Murecho", sans-serif;
  overflow-x: hidden;
  background-image: url("/public/img/background.svg"), url("/public/img/background.svg");
  background-size: 500px 500px;
  background-position: 0 0, 250px 250px;
  background-color: #fdfdff;
}

svg {
  fill: #040814;
}

.non_select, .non_select--img, .achievement_card.block {
  -webkit-user-select: none;
  -moz-user-select: none;
       user-select: none;
}
.non_select--img, .achievement_card.block {
  pointer-events: none;
}

a,
button {
  border: none;
  cursor: pointer;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
  opacity: 0 !important;
}

@media screen and (max-width: 1920px) {
  body {
    background-size: 450px 450px;
    background-position: 0 0, 225px 225px;
  }
}
@media screen and (max-width: 1440px) {
  body {
    background-size: 400px 400px;
    background-position: 0 0, 200px 200px;
  }
}
@media screen and (max-width: 1280px) {
  body {
    background-size: 350px 350px;
    background-position: 0 0, 175px 175px;
  }
}
@media screen and (max-width: 1024px) {
  body {
    background-size: 300px 300px;
    background-position: 0 0, 150px 150px;
  }
}
@media screen and (max-width: 768px) {
  body {
    background-size: 250px 250px;
    background-position: 0 0, 125px 125px;
  }
}
@media screen and (max-width: 480px) {
  body {
    background-size: 200px 200px;
    background-position: 0 0, 100px 100px;
  }
}
html {
  scrollbar-color: #345dff rgba(52, 93, 255, 0.1019607843);
  scrollbar-width: thin;
}
html #root #overflow-register {
  scrollbar-width: thin;
}
html #root .hide-scroll {
  scrollbar-color: auto;
  scrollbar-width: auto;
}
html #root .hide-scroll::-webkit-scrollbar {
  width: 3px;
}
html #root .hide-scroll::-webkit-scrollbar-track {
  background: rgba(52, 93, 255, 0.1019607843);
}
html #root .hide-scroll::-webkit-scrollbar-thumb {
  background: #0c3dff;
  border-radius: 2px;
}
html.firefox {
  scrollbar-color: #345dff rgba(52, 93, 255, 0.1019607843);
  scrollbar-width: auto;
}
html.firefox #root #overflow-register {
  scrollbar-width: auto;
}
html.firefox #root .hide-scroll {
  scrollbar-color: #345dff rgba(52, 93, 255, 0.1019607843);
  scrollbar-width: thin;
}

.stat_card {
  display: flex;
  align-items: center;
  gap: 15px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 12px;
  padding: 8px;
  background-color: #fdfdff;
}
.stat_card figure {
  width: 40px;
  flex: 0 0 40px;
}
.stat_card figure svg {
  width: 100%;
  height: 100%;
  fill: #345dff;
}
.stat_card .txt {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(4.5em + 8px);
}
#root .verify-code-inputs .vi__container {
  width: 100%;
  gap: 24px;
  height: -moz-fit-content;
  height: fit-content;
}
#root .verify-code-inputs .vi__container .vi__character {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  aspect-ratio: 1/1;
  height: auto;
  padding: 8px;
  border-radius: 12px;
  font-size: 56px;
  line-height: 84px;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  transition: 300ms;
}
#root .verify-code-inputs .vi__container .vi__character.vi__character--inactive {
  background-color: #fdfdff;
}
#root .verify-code-inputs .vi__container .vi__character:where(.vi__character--selected) {
  outline: none;
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
  color: #345dff;
}

#root .bug_report_sect {
  border-top: 1px solid #1a4573;
  padding-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}
#root .bug_report_sect .bug_report_page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50vw;
}
#root .bug_report_sect .bug_report_page .h_6 {
  color: #1a4573;
}
#root .bug_report_sect .bug_report_page form .inputs {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#root .bug_report_sect .bug_report_page form .inputs div {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#root .bug_report_sect .bug_report_page form .inputs div.subject_image {
  flex-direction: row;
}
#root .bug_report_sect .bug_report_page form .inputs div.subject_image div {
  width: 50%;
}
#root .bug_report_sect .bug_report_page form .inputs div.subject_image div .input {
  background-color: #fdfdff;
}
#root .bug_report_sect .bug_report_page form .inputs label {
  margin-bottom: -10px;
}
#root .bug_report_sect .bug_report_page form .inputs label span {
  color: #d00416;
  margin-left: 5px;
}
#root .bug_report_sect .bug_report_page form .inputs input,
#root .bug_report_sect .bug_report_page form .inputs textarea,
#root .bug_report_sect .bug_report_page form .inputs select {
  padding: 10px;
}
#root .bug_report_sect .bug_report_page form .inputs textarea {
  resize: none;
  height: 150px;
}
#root .bug_report_sect .bug_report_page form .background_btn {
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  border-radius: 8px;
}

#root footer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #1a4573;
  padding: 55px 80px 67px 80px;
  gap: 50px;
}
#root footer .top {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
}
#root footer .top .logo {
  flex: 1 1 33.3333333333%;
  margin-bottom: -20px;
}
#root footer .top .logo .link {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}
#root footer .top .logo .link figure {
  width: 140px;
}
#root footer .top .logo .link figure img {
  width: 100%;
  height: 100%;
}
#root footer .top .mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 82px;
  flex: 1 1 33.3333333333%;
}
#root footer .top .mid .h_5 {
  color: #fdfdff;
  width: 650px;
  text-align: center;
}
#root footer .top .mid .background_btn {
  width: 460px;
  background: #fdfdff;
  border-color: #fdfdff;
  color: #040814;
}
#root footer .top .mid .pages {
  display: flex;
  align-items: center;
  gap: 40px;
}
#root footer .top .mid .pages .h_8, #root footer .top .mid .pages .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root footer .top .mid .pages .ajs-header {
  color: #fdfdff;
}
#root footer .top .social-medias {
  flex: 1 1 33.3333333333%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 35px;
}
#root footer .top .social-medias figure {
  height: 19px;
}
#root footer .top .social-medias figure svg {
  width: 100%;
  height: 100%;
  fill: #fdfdff;
}
#root footer .conditions {
  display: flex;
  gap: 30px;
}
#root footer .conditions .b_3 {
  color: #fdfdff;
}
#root footer.footer-mini {
  padding: 38px var(--main-gap);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
#root footer.footer-mini figure {
  width: 140px;
}
#root footer.footer-mini figure img {
  width: 100%;
  height: 100%;
}
#root footer.footer-mini .links {
  display: flex;
  justify-content: space-between;
  gap: 10vw;
}
#root footer.footer-mini .links div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
#root footer.footer-mini .links div .b_1, #root footer.footer-mini .links div .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root footer.footer-mini .links div .ajs-content {
  color: #fdfdff;
}
#root footer.footer-mini .social-icons {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
#root footer.footer-mini .social-icons figure {
  display: flex;
}
#root footer.footer-mini .social-icons figure svg {
  fill: #fdfdff;
  width: 100%;
  height: 100%;
}
#root footer.footer-mini .social-icons figure.twitter {
  width: 16px;
}
#root footer.footer-mini .social-icons figure.facebook {
  width: 10px;
}
#root footer.footer-mini .social-icons figure.instagram {
  width: 19px;
}
#root footer.footer-mini .markets {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#root footer.footer-mini .markets figure {
  width: auto;
  height: 55px;
  display: flex;
  color: #fdfdff;
}
#root footer.footer-mini .markets figure img {
  width: 100%;
  height: 100%;
}

#root nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px var(--main-gap);
  border-radius: 0 0 16px 16px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  background-color: #fdfdff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
#root nav .logo figure {
  display: flex;
  width: 140px;
}
#root nav .logo figure img {
  width: 100%;
  height: 100%;
}
#root nav .nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}
#root nav .nav-content .pages {
  display: flex;
  align-items: center;
  gap: 24px;
}
#root nav .nav-content .pages .b_1, #root nav .nav-content .pages .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root nav .nav-content .pages .ajs-content {
  color: #040814;
}
#root nav .nav-content .pages .prediction_link {
  position: relative;
  margin-right: 10px;
}
#root nav .nav-content .pages .prediction_link span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: -20px;
  background: #345dff;
  border-radius: 50%;
  color: #fdfdff;
}
#root nav .nav-content .nav-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
#root nav .nav-content .nav-btns .b_2 {
  padding: 8px 12px;
  border-radius: 150px;
}
#root nav .nav-content .user {
  display: flex;
  align-items: center;
  gap: 24px;
}
#root nav .nav-content .user .notifications figure {
  display: flex;
  height: 25px;
}
#root nav .nav-content .user .notifications figure svg {
  width: 100%;
  height: 100%;
  fill: #040814;
}
#root nav .nav-content .user .profile {
  position: relative;
  display: flex;
  align-items: center;
  gap: 25px;
}
#root nav .nav-content .user .profile .point {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}
#root nav .nav-content .user .profile .point .b_2 {
  color: #345dff;
  filter: drop-shadow(0.5px 0 0 rgba(52, 93, 255, 0.1019607843)) drop-shadow(0 0.5px 0 rgba(52, 93, 255, 0.1019607843)) drop-shadow(-0.5px 0 0 rgba(52, 93, 255, 0.1019607843)) drop-shadow(0 -0.5px 0 rgba(52, 93, 255, 0.1019607843));
}
#root nav .nav-content .user .profile .profile_photo {
  cursor: pointer;
  position: relative;
}
#root nav .nav-content .user .profile .profile_photo figure {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
}
#root nav .nav-content .user .profile .profile_photo figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root nav .nav-content .user .profile .profile_photo .modal {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 50%;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  background-color: #fdfdff;
  border-radius: 10px;
  width: -moz-max-content;
  width: max-content;
}
#root nav .nav-content .user .profile .profile_photo .modal a {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #e7e7ed;
}
#root nav .nav-content .user .profile .profile_photo .modal a:last-child {
  border: none;
}
#root nav .nav-content .user .profile .profile_photo .modal a figure {
  display: flex;
  box-shadow: 0 0 0 0;
  width: 20px;
  height: 20px;
}
#root nav .nav-content .user .profile .profile_photo .modal a figure svg {
  width: 100%;
  height: 100%;
}
#root nav .nav-content .user .profile .profile_photo .modal a p {
  color: #040814;
}
#root .mobile_nav {
  bottom: 0;
  top: auto;
  border-radius: 10px 10px 0 0;
  padding: 5px 15px;
}
#root .mobile_nav a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 6px;
}
#root .mobile_nav a figure {
  display: flex;
  height: 20px;
}
#root .mobile_nav a figure svg {
  width: 100%;
  height: 100%;
  fill: #040814;
}
#root .mobile_nav a.power_off figure svg {
  fill: #d00416;
}
#root .mobile_nav a.profile_photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: auto;
}
#root .mobile_nav a.profile_photo figure {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
}
#root .mobile_nav a.profile_photo figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .mobile_nav a.profile_photo .point {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root .mobile_nav a.profile_photo .point .b_4, #root .mobile_nav a.profile_photo .point .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .mobile_nav a.profile_photo .point .ajs-ok, #root .mobile_nav a.profile_photo .point .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .mobile_nav a.profile_photo .point .ajs-cancel {
  color: #345dff;
  filter: drop-shadow(0.5px 0 0 rgba(52, 93, 255, 0.1019607843)) drop-shadow(0 0.5px 0 rgba(52, 93, 255, 0.1019607843)) drop-shadow(-0.5px 0 0 rgba(52, 93, 255, 0.1019607843)) drop-shadow(0 -0.5px 0 rgba(52, 93, 255, 0.1019607843));
}
#root .mobile_nav a.profile_photo .point .progress_bar {
  width: 50px;
  height: 4px;
}
#root .mobile_nav a.active {
  background-color: #345dff;
}
#root .mobile_nav a.active figure svg {
  fill: #fdfdff;
}

@media screen and (max-width: 1920px) {
  #root nav .nav-content .pages .prediction_link {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1440px) {
  #root nav .nav-content .pages .prediction_link {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1280px) {
  #root nav .logo figure {
    width: 120px;
  }
  #root nav .nav-content .pages .prediction_link {
    margin-right: 10px;
  }
  #root nav .nav-content .pages .prediction_link span {
    width: 15px;
    height: 15px;
    right: -15px;
  }
  #root nav .nav-content .user .notifications figure {
    height: 20px;
  }
  #root nav .nav-content .user .profile .profile_photo figure {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 1024px) {
  #root nav .logo figure {
    width: 90px;
  }
  #root nav .nav-content {
    gap: 16px;
  }
  #root nav .nav-content .pages {
    gap: 16px;
  }
  #root nav .nav-content .user {
    gap: 16px;
  }
  #root nav .nav-content .user .profile {
    gap: 16px;
  }
  #root nav .nav-content .user .profile .profile_photo figure {
    width: 45px;
    height: 45px;
  }
  #root nav .nav-content .user .profile .profile_photo .modal a figure {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 768px) {
  #root nav .logo figure {
    width: 50px;
  }
  #root nav .nav-content {
    gap: 6px;
  }
  #root nav .nav-content .pages {
    gap: 6px;
  }
  #root nav .nav-content .user {
    gap: 6px;
  }
  #root nav .nav-content .user .notifications figure {
    height: 15px;
  }
  #root nav .nav-content .user .profile {
    gap: 6px;
  }
  #root nav .nav-content .user .profile .point .progress_bar {
    width: 50px;
  }
  #root nav .nav-content .user .profile .profile_photo figure {
    width: 25px;
    height: 25px;
  }
  #root nav .nav-content .user .profile .profile_photo .modal {
    border-radius: 8px;
  }
  #root nav .nav-content .user .profile .profile_photo .modal a {
    padding: 5px;
    gap: 5px;
  }
  #root nav .nav-content .user .profile .profile_photo .modal a figure {
    width: 10px;
    height: 10px;
  }
}
#root .about_page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  padding: 50px 0;
}
#root .about_page img {
  width: 25%;
}
#root .about_page p {
  width: 50%;
  text-align: center;
}
#root .about_page p span {
  display: block;
}

@media screen and (max-width: 1280px) {
  #root .about_page p {
    width: 60%;
  }
}
@media screen and (max-width: 1024px) {
  #root .about_page img {
    width: 40%;
  }
}
@media screen and (max-width: 768px) {
  #root .about_page p {
    width: 75%;
  }
}
#root .cond-use {
  margin-top: 75px;
  padding: 30px var(--main-gap) 60px var(--main-gap);
  min-height: 100vh;
}
#root .cond-use .h_2 {
  text-align: center;
  margin: 0 0 30px 0;
}
#root .cond-use .conditions {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}
#root .cond-use .conditions .condition {
  flex: 1 1 100%;
  padding: 56px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 16px;
  background-color: #fdfdff;
  transition: 300ms all;
}
#root .cond-use .conditions .condition .desc {
  margin-top: 50px;
}
#root .cond-use .conditions .condition.half {
  flex: 1 1 calc(50% - 17.5px);
  padding: 28px 56px;
}
#root .cond-use .conditions .condition:hover {
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
}
#root .privacy-policy {
  margin-top: 75px;
  padding: 30px var(--main-gap) 60px var(--main-gap);
  min-height: 100vh;
}
#root .privacy-policy .h_2 {
  text-align: center;
  margin: 0 0 30px 0;
}
#root .privacy-policy .policies {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
}
#root .privacy-policy .policies .policy {
  flex: 1 1 100%;
  padding: 56px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 16px;
  transition: 300ms all;
  background-color: #fdfdff;
}
#root .privacy-policy .policies .policy.half {
  flex: 1 1 calc(50% - 17.5px);
  padding: 28px 56px;
}
#root .privacy-policy .policies .policy:hover {
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
}
#root .faqs {
  margin-top: 75px;
  padding: 30px var(--main-gap);
  min-height: 100vh;
}
#root .faqs .main-hood {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}
#root .faqs .main-hood .h_6 {
  color: #08164e;
}
#root .faqs .questions-category .h_7 {
  margin: 30px 0 25px 0;
}
#root .faqs .questions-category .questions {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
#root .faqs .questions-category .questions .question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px;
  border-radius: 14px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  background-color: #fdfdff;
  cursor: pointer;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .content {
  flex: 1 1 100%;
  display: inline;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .content .hood {
  color: #08164e;
}
#root .faqs .questions-category .questions .question .content .open-txt {
  max-height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .arrow {
  flex: 1 1 20%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
#root .faqs .questions-category .questions .question .arrow figure {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px;
  border-radius: 50%;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  transform: rotate(-90deg);
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question .arrow figure svg {
  width: 16px;
  fill: #345dff;
  transition: 300ms all;
}
#root .faqs .questions-category .questions .question.open {
  align-items: flex-start;
  box-shadow: 0px 5px 16px 0px rgba(52, 93, 255, 0.5019607843);
}
#root .faqs .questions-category .questions .question.open .content {
  display: flex;
}
#root .faqs .questions-category .questions .question.open .content .open-txt {
  max-height: 1440px;
}
#root .faqs .questions-category .questions .question.open .arrow figure {
  background-color: #345dff;
  box-shadow: none;
  transform: rotate(0deg);
}
#root .faqs .questions-category .questions .question.open .arrow figure svg {
  fill: #fdfdff;
}

#root .user_homepage {
  margin-top: 75px;
  padding: 20px var(--main-gap) 130px var(--main-gap);
  min-height: 100vh;
}
#root .user_homepage .homepage_buttons {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 48px;
  margin: 50px 0;
}
#root .user_homepage .homepage_buttons a {
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fdfdff;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 20px;
  padding: 6px 12px;
  width: 100%;
  width: calc(25% - 48px);
}
#root .user_homepage .homepage_buttons a figure {
  height: 40px;
  width: 40px;
}
#root .user_homepage .homepage_buttons a figure svg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
#root .user_homepage .homepage_buttons a figure img {
  width: 100%;
  height: 100%;
}
#root .user_homepage .homepage_buttons a p.h_8, #root .user_homepage .homepage_buttons a .alertify .ajs-dialog p.ajs-header, .alertify .ajs-dialog #root .user_homepage .homepage_buttons a p.ajs-header {
  color: #040814;
}
#root .user_homepage .premium_discover {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: calc(50% - 24px);
  padding: 16px;
  border-radius: 24px;
  background-color: #fdfdff;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  margin-top: 65px;
}
#root .user_homepage .premium_discover figure {
  width: 100%;
  height: 120px;
  border-radius: 12px;
  overflow: hidden;
}
#root .user_homepage .premium_discover figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .user_homepage .premium_discover .premium_discover_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
#root .user_homepage .premium_discover .premium_discover_content .h_7 span {
  margin-right: 8px;
}
#root .user_homepage .premium_discover .premium_discover_content .b_1 span, #root .user_homepage .premium_discover .premium_discover_content .alertify .ajs-dialog .ajs-body .ajs-content span, .alertify .ajs-dialog .ajs-body #root .user_homepage .premium_discover .premium_discover_content .ajs-content span {
  margin-left: 8px;
}
#root .user_homepage .premium_discover .background_btn.h_8, #root .user_homepage .premium_discover .alertify .ajs-dialog .background_btn.ajs-header, .alertify .ajs-dialog #root .user_homepage .premium_discover .background_btn.ajs-header {
  border-radius: 20px;
  padding: 4px 36px;
}
#root .homepage .first {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
#root .homepage .first .img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
#root .homepage .first .img .filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 57, 0.5019607843);
}
#root .homepage .first .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .homepage .first .txt {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
#root .homepage .first .txt .h_6 {
  color: #fdfdff;
}
#root .homepage .first .txt .h_7 {
  color: #fdfdff;
}
#root .homepage .first .txt .background_btn {
  width: 20vw;
  margin-top: 50px;
}
#root .homepage .rewards {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a4573;
  padding: 150px 90px;
  gap: 80px;
}
#root .homepage .rewards .content {
  flex: 1 1 100%;
}
#root .homepage .rewards .content .h_7 {
  color: #fdfdff;
}
#root .homepage .rewards .content .background_btn {
  background-color: #fdfdff;
  color: #1a4573;
  width: -moz-fit-content;
  width: fit-content;
  margin-top: 30px;
}
#root .homepage .rewards figure {
  flex: 1 1 100%;
}
#root .homepage .rewards figure img {
  width: 100%;
  height: 100%;
}
#root .homepage .how-to {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 150px 90px;
}
#root .homepage .how-to .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  flex: 1 1 100%;
}
#root .homepage .how-to .content .h_8, #root .homepage .how-to .content .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .homepage .how-to .content .ajs-header {
  text-align: center;
}

@media screen and (max-width: 1920px) {
  #root .user_homepage .homepage_buttons a {
    border-radius: 16px;
    padding: 3px 12px;
  }
  #root .user_homepage .homepage_buttons a figure {
    height: 35px;
    width: 35px;
  }
}
@media screen and (max-width: 1440px) {
  #root .user_homepage .homepage_buttons {
    gap: 30px;
  }
  #root .user_homepage .homepage_buttons a {
    width: calc(25% - 30px);
  }
  #root .user_homepage .homepage_buttons a figure {
    height: 30px;
    width: 30px;
  }
  #root .user_homepage .premium_discover {
    width: calc(50% - 15px);
  }
}
@media screen and (max-width: 1280px) {
  #root .user_homepage .homepage_buttons {
    gap: 30px;
  }
  #root .user_homepage .homepage_buttons a {
    border-radius: 14px;
    width: calc(50% - 15px);
  }
  #root .user_homepage .premium_discover {
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  #root .user_homepage .homepage_buttons a {
    gap: 18px;
    border-radius: 12px;
  }
  #root .user_homepage .homepage_buttons a figure {
    height: 28px;
    width: 28px;
  }
}
@media screen and (max-width: 768px) {
  #root .user_homepage .homepage_buttons {
    gap: 20px;
  }
  #root .user_homepage .homepage_buttons a {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  #root .user_homepage {
    margin: 0;
  }
  #root .user_homepage .homepage_buttons a {
    width: 100%;
  }
}
#root .leaderboard_page {
  margin-top: 75px;
  padding: 100px var(--main-gap);
}
#root .leaderboard_page h5.h_5.hood {
  text-align: center;
  margin-bottom: 30px;
}
#root .leaderboard_page .info_leaderboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 40px;
}
#root .leaderboard_page .info_leaderboard .top,
#root .leaderboard_page .info_leaderboard .bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
}
#root .leaderboard_page .info_leaderboard .top .total, #root .leaderboard_page .info_leaderboard .top .remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1 1 calc(20% - 40px);
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
}
#root .leaderboard_page .info_leaderboard .top .total figure, #root .leaderboard_page .info_leaderboard .top .remaining_time figure,
#root .leaderboard_page .info_leaderboard .bottom .total figure,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time figure {
  height: 60px;
}
#root .leaderboard_page .info_leaderboard .top .total figure svg, #root .leaderboard_page .info_leaderboard .top .remaining_time figure svg,
#root .leaderboard_page .info_leaderboard .bottom .total figure svg,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time figure svg {
  height: 100%;
}
#root .leaderboard_page .info_leaderboard .top .total .content_total, #root .leaderboard_page .info_leaderboard .top .remaining_time .content_total,
#root .leaderboard_page .info_leaderboard .bottom .total .content_total,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .content_total {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#root .leaderboard_page .info_leaderboard .top .total .content_total p.b_1, #root .leaderboard_page .info_leaderboard .top .remaining_time .content_total p.b_1, #root .leaderboard_page .info_leaderboard .top .total .content_total .alertify .ajs-dialog .ajs-body p.ajs-content, #root .leaderboard_page .info_leaderboard .top .remaining_time .content_total .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .top .total .content_total p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .top .remaining_time .content_total p.ajs-content,
#root .leaderboard_page .info_leaderboard .bottom .total .content_total p.b_1,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .content_total p.b_1,
#root .leaderboard_page .info_leaderboard .bottom .total .content_total .alertify .ajs-dialog .ajs-body p.ajs-content,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .content_total .alertify .ajs-dialog .ajs-body p.ajs-content,
.alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .bottom .total .content_total p.ajs-content,
.alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .bottom .remaining_time .content_total p.ajs-content {
  margin-bottom: 15px;
}
#root .leaderboard_page .info_leaderboard .top .total.total_player, #root .leaderboard_page .info_leaderboard .top .total_player.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.total_player,
#root .leaderboard_page .info_leaderboard .bottom .total_player.remaining_time {
  background-color: #dfffee;
}
#root .leaderboard_page .info_leaderboard .top .total.total_player p, #root .leaderboard_page .info_leaderboard .top .total_player.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.total_player h6,
#root .leaderboard_page .info_leaderboard .top .total_player.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.total_player p,
#root .leaderboard_page .info_leaderboard .bottom .total_player.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.total_player h6,
#root .leaderboard_page .info_leaderboard .bottom .total_player.remaining_time h6 {
  color: #0e4f2c;
}
#root .leaderboard_page .info_leaderboard .top .total.total_player svg, #root .leaderboard_page .info_leaderboard .top .total_player.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.total_player svg,
#root .leaderboard_page .info_leaderboard .bottom .total_player.remaining_time svg {
  fill: #0e4f2c;
}
#root .leaderboard_page .info_leaderboard .top .total.average_point, #root .leaderboard_page .info_leaderboard .top .average_point.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.average_point,
#root .leaderboard_page .info_leaderboard .bottom .average_point.remaining_time {
  background-color: #dfe5fe;
}
#root .leaderboard_page .info_leaderboard .top .total.average_point p, #root .leaderboard_page .info_leaderboard .top .average_point.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.average_point h6,
#root .leaderboard_page .info_leaderboard .top .average_point.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.average_point p,
#root .leaderboard_page .info_leaderboard .bottom .average_point.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.average_point h6,
#root .leaderboard_page .info_leaderboard .bottom .average_point.remaining_time h6 {
  color: #162978;
}
#root .leaderboard_page .info_leaderboard .top .total.average_point svg, #root .leaderboard_page .info_leaderboard .top .average_point.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.average_point svg,
#root .leaderboard_page .info_leaderboard .bottom .average_point.remaining_time svg {
  fill: #162978;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_package, #root .leaderboard_page .info_leaderboard .top .correct_package.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_package,
#root .leaderboard_page .info_leaderboard .bottom .correct_package.remaining_time {
  background-color: #c3dff4;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_package p, #root .leaderboard_page .info_leaderboard .top .correct_package.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.correct_package h6,
#root .leaderboard_page .info_leaderboard .top .correct_package.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_package p,
#root .leaderboard_page .info_leaderboard .bottom .correct_package.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_package h6,
#root .leaderboard_page .info_leaderboard .bottom .correct_package.remaining_time h6 {
  color: #124063;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_package svg, #root .leaderboard_page .info_leaderboard .top .correct_package.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_package svg,
#root .leaderboard_page .info_leaderboard .bottom .correct_package.remaining_time svg {
  fill: #124063;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_home, #root .leaderboard_page .info_leaderboard .top .correct_home.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_home,
#root .leaderboard_page .info_leaderboard .bottom .correct_home.remaining_time {
  background-color: #e4c1c5;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_home p, #root .leaderboard_page .info_leaderboard .top .correct_home.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.correct_home h6,
#root .leaderboard_page .info_leaderboard .top .correct_home.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_home p,
#root .leaderboard_page .info_leaderboard .bottom .correct_home.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_home h6,
#root .leaderboard_page .info_leaderboard .bottom .correct_home.remaining_time h6 {
  color: #63121b;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_home svg, #root .leaderboard_page .info_leaderboard .top .correct_home.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_home svg,
#root .leaderboard_page .info_leaderboard .bottom .correct_home.remaining_time svg {
  fill: #63121b;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_draw, #root .leaderboard_page .info_leaderboard .top .correct_draw.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_draw,
#root .leaderboard_page .info_leaderboard .bottom .correct_draw.remaining_time {
  background-color: #a7e4dd;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_draw p, #root .leaderboard_page .info_leaderboard .top .correct_draw.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.correct_draw h6,
#root .leaderboard_page .info_leaderboard .top .correct_draw.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_draw p,
#root .leaderboard_page .info_leaderboard .bottom .correct_draw.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_draw h6,
#root .leaderboard_page .info_leaderboard .bottom .correct_draw.remaining_time h6 {
  color: #0e3a35;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_draw svg, #root .leaderboard_page .info_leaderboard .top .correct_draw.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_draw svg,
#root .leaderboard_page .info_leaderboard .bottom .correct_draw.remaining_time svg {
  fill: #0e3a35;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_away, #root .leaderboard_page .info_leaderboard .top .correct_away.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_away,
#root .leaderboard_page .info_leaderboard .bottom .correct_away.remaining_time {
  background-color: #dcbed0;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_away p, #root .leaderboard_page .info_leaderboard .top .correct_away.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.correct_away h6,
#root .leaderboard_page .info_leaderboard .top .correct_away.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_away p,
#root .leaderboard_page .info_leaderboard .bottom .correct_away.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_away h6,
#root .leaderboard_page .info_leaderboard .bottom .correct_away.remaining_time h6 {
  color: #631243;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_away svg, #root .leaderboard_page .info_leaderboard .top .correct_away.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_away svg,
#root .leaderboard_page .info_leaderboard .bottom .correct_away.remaining_time svg {
  fill: #631243;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_score, #root .leaderboard_page .info_leaderboard .top .correct_score.remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_score,
#root .leaderboard_page .info_leaderboard .bottom .correct_score.remaining_time {
  background-color: #ccc7ae;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_score p, #root .leaderboard_page .info_leaderboard .top .correct_score.remaining_time p,
#root .leaderboard_page .info_leaderboard .top .total.correct_score h6,
#root .leaderboard_page .info_leaderboard .top .correct_score.remaining_time h6,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_score p,
#root .leaderboard_page .info_leaderboard .bottom .correct_score.remaining_time p,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_score h6,
#root .leaderboard_page .info_leaderboard .bottom .correct_score.remaining_time h6 {
  color: #403700;
}
#root .leaderboard_page .info_leaderboard .top .total.correct_score svg, #root .leaderboard_page .info_leaderboard .top .correct_score.remaining_time svg,
#root .leaderboard_page .info_leaderboard .bottom .total.correct_score svg,
#root .leaderboard_page .info_leaderboard .bottom .correct_score.remaining_time svg {
  fill: #403700;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time {
  background-color: #fff5cd;
  flex: 1 1 calc(60% - 40px);
  justify-content: center;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content h6.h_6,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content h6.h_6 {
  color: #4f4417;
  text-transform: uppercase;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 {
  display: flex;
  align-items: stretch;
  gap: 8px;
  color: #4f4417;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .day,
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .hours,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .day,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .hours {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .day span.h_8, #root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .day .alertify .ajs-dialog span.ajs-header, .alertify .ajs-dialog #root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .day span.ajs-header,
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .hours span.h_8,
#root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .hours .alertify .ajs-dialog span.ajs-header,
.alertify .ajs-dialog #root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content p.h_6 .hours span.ajs-header,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .day span.h_8,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .day .alertify .ajs-dialog span.ajs-header,
.alertify .ajs-dialog #root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .day span.ajs-header,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .hours span.h_8,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .hours .alertify .ajs-dialog span.ajs-header,
.alertify .ajs-dialog #root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content p.h_6 .hours span.ajs-header {
  color: #4f4417;
}
#root .leaderboard_page .info_leaderboard .top .remaining_time p.b_1, #root .leaderboard_page .info_leaderboard .top .remaining_time .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .top .remaining_time p.ajs-content,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time p.b_1,
#root .leaderboard_page .info_leaderboard .bottom .remaining_time .alertify .ajs-dialog .ajs-body p.ajs-content,
.alertify .ajs-dialog .ajs-body #root .leaderboard_page .info_leaderboard .bottom .remaining_time p.ajs-content {
  border-top: 1px solid rgba(4, 8, 20, 0.2509803922);
  padding-top: 10px;
}
#root .leaderboard_page .leaderboard_sect {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
  margin-top: 50px;
}
#root .leaderboard_page .leaderboard_sect .h_7.hood {
  text-align: center;
}
#root .leaderboard_page .leaderboard_sect .h_7.hood span {
  margin-right: 10px;
}
#root .leaderboard_page .leaderboard_sect .filter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}
#root .leaderboard_page .leaderboard_sect .filter .user_leagues,
#root .leaderboard_page .leaderboard_sect .filter .periods,
#root .leaderboard_page .leaderboard_sect .filter .week {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#root .leaderboard_page .leaderboard_sect .leaderboard {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 30px;
}
#root .leaderboard_page .leaderboard_sect .leaderboard .user_queue {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
#root .leaderboard_page .leaderboard_sect .leaderboard .user_queue span.dot {
  width: 15px;
  height: 15px;
  background-color: #858588;
  border-radius: 50%;
}
#root .leaderboard_page .leaderboard_sect .outline_btn, #root .leaderboard_page .leaderboard_sect .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .leaderboard_page .leaderboard_sect .ajs-ok, #root .leaderboard_page .leaderboard_sect .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .leaderboard_page .leaderboard_sect .ajs-cancel {
  width: -moz-max-content;
  width: max-content;
  border-radius: 18px;
  padding: 2px 10px;
}
#root .leaderboard_page .leaderboard_sect .outline_btn p, #root .leaderboard_page .leaderboard_sect .alertify .ajs-dialog .ajs-footer .ajs-ok p, .alertify .ajs-dialog .ajs-footer #root .leaderboard_page .leaderboard_sect .ajs-ok p, #root .leaderboard_page .leaderboard_sect .alertify .ajs-dialog .ajs-footer .ajs-cancel p, .alertify .ajs-dialog .ajs-footer #root .leaderboard_page .leaderboard_sect .ajs-cancel p {
  color: #345dff;
}
#root .leaderboard_card, #root .leaderboard_king_card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fdfdff;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 16px;
  padding: 12px;
  cursor: pointer;
  position: relative;
}
#root .leaderboard_card .leaderboard_card_inner, #root .leaderboard_king_card .leaderboard_card_inner {
  width: 100%;
  height: 40px;
  position: relative;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  z-index: 2;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front.visible, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front.visible {
  opacity: 1;
  z-index: 2;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front.hidden, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front.hidden {
  opacity: 0;
  z-index: 1;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left {
  display: flex;
  align-items: center;
  gap: 15px;
  width: stretch;
  width: -moz-available;
  width: -webkit-fill-available;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left .queue, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left .queue {
  display: flex;
  align-items: center;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left .queue p.b_1, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left .queue p.b_1, #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left .queue .alertify .ajs-dialog .ajs-body p.ajs-content, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left .queue .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left .queue p.ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left .queue p.ajs-content {
  white-space: pre;
  font-family: monospace;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left figure, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left figure {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.1019607843);
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left figure img, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left p.name, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left p.name {
  width: 8vw;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left p.percent, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left p.percent {
  width: -moz-max-content;
  width: max-content;
  color: #345dff;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right {
  display: flex;
  align-items: center;
  gap: 15px;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .point, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .point {
  color: #345dff;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .point span, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .point span {
  margin-right: 5px;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report {
  position: relative;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report figure, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report figure {
  display: flex;
  height: 20px;
  width: 20px;
  opacity: 1;
  transition: 300ms;
  cursor: pointer;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report figure svg, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(4, 8, 20, 0.2509803922);
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content {
  position: absolute;
  background-color: #fdfdff;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  top: 0;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 10px;
  border-radius: 5px;
  display: none;
  z-index: 1;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .b_1, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .b_1, #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .ajs-content {
  color: #5e5e62;
  transition: 300ms;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .b_1:hover, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .b_1:hover, #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content:hover, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .alertify .ajs-dialog .ajs-body .ajs-content:hover, .alertify .ajs-dialog .ajs-body #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .ajs-content:hover, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content .ajs-content:hover {
  color: #040814;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report:hover .content, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report:hover .content {
  display: flex;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: 1;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back.visible, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back.visible {
  opacity: 1;
  z-index: 2;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back.hidden, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back.hidden {
  opacity: 0;
  z-index: 1;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back .percents, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back .percents p, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents p {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back .percents p span, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents p span {
  color: #345dff;
}
#root .leaderboard_card.my, #root .my.leaderboard_king_card {
  box-shadow: 0px 5px 16px 0px #345dff;
}
#root .leaderboard_king_card {
  width: calc((100% - 60px) / 3);
  align-items: flex-start;
}
#root .leaderboard_king_card .leaderboard_card_inner {
  width: 100%;
  height: 80px;
  position: relative;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
  z-index: 2;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front.visible {
  opacity: 1;
  z-index: 2;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front.hidden {
  opacity: 0;
  z-index: 1;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 15px;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left {
  width: auto;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left figure.queue,
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left figure.profile {
  width: 60px;
  height: 60px;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left figure.queue {
  box-shadow: 0 0 0 0;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left figure.profile img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .right {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .right .txt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .right .txt .h_8 span, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .right .txt .alertify .ajs-dialog .ajs-header span, .alertify .ajs-dialog #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .right .txt .ajs-header span {
  margin-right: 5px;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report figure {
  display: flex;
  height: 20px;
  opacity: 1;
  transition: 300ms;
  cursor: pointer;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report figure svg {
  width: 100%;
  height: 100%;
  fill: rgba(4, 8, 20, 0.2509803922);
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content {
  position: absolute;
  background-color: #fdfdff;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  top: 0;
  right: 0;
  width: -moz-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 15px 10px;
  border-radius: 5px;
  display: none;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content .b_1, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content .ajs-content {
  color: #5e5e62;
  transition: 300ms;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content .b_1:hover, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content .alertify .ajs-dialog .ajs-body .ajs-content:hover, .alertify .ajs-dialog .ajs-body #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content .ajs-content:hover {
  color: #040814;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report:hover .content {
  display: flex;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  z-index: 1;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back.visible {
  opacity: 1;
  z-index: 2;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back.hidden {
  opacity: 0;
  z-index: 1;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents p {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents p span {
  color: #345dff;
}
#root .leaderboard_king_card.first {
  border: 2px solid #695100;
  box-shadow: 0px 5px 16px 0px #695100;
}
#root .leaderboard_king_card.first p {
  color: #695100;
}
#root .leaderboard_king_card.second {
  border: 2px solid #555555;
  box-shadow: 0px 5px 16px 0px #555555;
}
#root .leaderboard_king_card.second p {
  color: #555555;
}
#root .leaderboard_king_card.thirth {
  border: 2px solid #8b4000;
  box-shadow: 0px 5px 16px 0px #8b4000;
}
#root .leaderboard_king_card.thirth p {
  color: #8b4000;
}
#root .no_leaderboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}
#root .no_leaderboard .h_3 {
  color: #49494d;
  text-align: center;
  width: 75%;
}
#root .no_leaderboard .h_8, #root .no_leaderboard .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .no_leaderboard .ajs-header {
  color: #49494d;
}
#root .no_leaderboard figure {
  width: 28px;
}
#root .no_leaderboard figure svg {
  width: 100%;
  height: 100%;
  fill: #49494d;
}

@media screen and (max-width: 1440px) {
  #root .leaderboard_page .info_leaderboard .top .total figure, #root .leaderboard_page .info_leaderboard .top .remaining_time figure,
  #root .leaderboard_page .info_leaderboard .bottom .total figure,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time figure {
    height: 50px;
  }
}
@media screen and (max-width: 1280px) {
  #root .leaderboard_page .info_leaderboard .top,
  #root .leaderboard_page .info_leaderboard .bottom {
    gap: 20px;
  }
  #root .leaderboard_page .info_leaderboard .top .total, #root .leaderboard_page .info_leaderboard .top .remaining_time,
  #root .leaderboard_page .info_leaderboard .bottom .total,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time {
    flex: 1 1 calc(20% - 20px);
  }
  #root .leaderboard_page .info_leaderboard .top .total figure, #root .leaderboard_page .info_leaderboard .top .remaining_time figure,
  #root .leaderboard_page .info_leaderboard .bottom .total figure,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time figure {
    height: 45px;
  }
  #root .leaderboard_page .info_leaderboard .top .remaining_time,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time {
    flex: 1 1 calc(60% - 20px);
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report .content, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report .content {
    padding: 10px;
  }
  #root .leaderboard_king_card {
    width: 100%;
  }
  #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .report .content {
    padding: 10px;
  }
}
@media screen and (max-width: 1024px) {
  #root .leaderboard_page .info_leaderboard .top .total, #root .leaderboard_page .info_leaderboard .top .remaining_time,
  #root .leaderboard_page .info_leaderboard .bottom .total,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time {
    flex: 1 1 calc(33.3333333333% - 20px);
  }
  #root .leaderboard_card, #root .leaderboard_king_card {
    border-radius: 12px;
    padding: 8px;
  }
}
@media screen and (max-width: 768px) {
  #root .leaderboard_page .info_leaderboard .top .total, #root .leaderboard_page .info_leaderboard .top .remaining_time,
  #root .leaderboard_page .info_leaderboard .bottom .total,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time {
    border-radius: 12px;
  }
  #root .leaderboard_page .info_leaderboard .top .total figure, #root .leaderboard_page .info_leaderboard .top .remaining_time figure,
  #root .leaderboard_page .info_leaderboard .bottom .total figure,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time figure {
    height: 40px;
  }
  #root .leaderboard_page .leaderboard_sect .filter {
    gap: 15px;
  }
  #root .leaderboard_page .leaderboard_sect .filter .user_leagues,
  #root .leaderboard_page .leaderboard_sect .filter .periods,
  #root .leaderboard_page .leaderboard_sect .filter .week {
    width: 100%;
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left p.name, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left p.name {
    width: 20vw;
  }
}
@media screen and (max-width: 480px) {
  #root .leaderboard_page {
    margin: 0;
    padding: 50px var(--main-gap);
  }
  #root .leaderboard_page .info_leaderboard {
    gap: 10px;
  }
  #root .leaderboard_page .info_leaderboard .top,
  #root .leaderboard_page .info_leaderboard .bottom {
    gap: 10px;
  }
  #root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content {
    gap: 10px;
    flex-direction: column;
  }
  #root .leaderboard_page .info_leaderboard .top .remaining_time .remaining_time_content h6.h_6,
  #root .leaderboard_page .info_leaderboard .bottom .remaining_time .remaining_time_content h6.h_6 {
    text-align: center;
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left {
    gap: 5px;
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .left p.name, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .left p.name {
    width: 30vw;
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right {
    gap: 5px;
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_front .right .report figure, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .right .report figure {
    height: 15px;
    width: 15px;
  }
  #root .leaderboard_card .leaderboard_card_inner .leaderboard_card_back .percents, #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_back .percents {
    flex-direction: column;
    gap: 0;
  }
  #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content {
    gap: 10px;
  }
  #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left figure.queue,
  #root .leaderboard_king_card .leaderboard_card_inner .leaderboard_card_front .leaderboard_king_card_content .left figure.profile {
    width: 50px;
    height: 50px;
  }
}
#root .leagues_page {
  margin-top: 75px;
  padding: 100px var(--main-gap);
}
#root .leagues_page .hood_league {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
#root .leagues_page .leagues {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 48px;
  position: relative;
}
#root .leagues_component .hood_league {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
#root .leagues_component .hood_league a.outline_btn, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-ok, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-cancel {
  padding: 2px 10px;
  border-radius: 16px;
  cursor: pointer;
}
#root .leagues_component .hood_league a.outline_btn .b_1, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-ok .b_1, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-ok .b_1, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-cancel .b_1, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-cancel .b_1, #root .leagues_component .hood_league a.outline_btn .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .leagues_component .hood_league a.outline_btn .ajs-content, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-ok .ajs-body .ajs-content, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-body .ajs-footer a.ajs-ok .ajs-content, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-ok .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .leagues_component .hood_league a.ajs-ok .ajs-content, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-footer a.ajs-cancel .ajs-body .ajs-content, #root .leagues_component .hood_league .alertify .ajs-dialog .ajs-body .ajs-footer a.ajs-cancel .ajs-content, .alertify .ajs-dialog .ajs-footer #root .leagues_component .hood_league a.ajs-cancel .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .leagues_component .hood_league a.ajs-cancel .ajs-content {
  color: #345dff;
}
#root .leagues_component .leagues {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 48px;
  position: relative;
}
#root .league_component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1 calc(25% - 48px);
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  padding: 24px;
  border-radius: 24px;
  background-color: #fdfdff;
  gap: 8px;
  position: relative;
  overflow: hidden;
}
#root .league_component .block {
  display: none;
  position: absolute;
  top: 20px;
  left: 20px;
}
#root .league_component .block figure {
  width: 24px;
}
#root .league_component .block figure svg {
  width: 100%;
  height: 100%;
  fill: #ffdb43;
  stroke-width: 5%;
  stroke: rgba(4, 8, 20, 0.2509803922);
}
#root .league_component .content_league {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
#root .league_component .content_league figure {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgba(4, 8, 20, 0.2509803922);
}
#root .league_component .content_league figure img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .league_component .content_league .h_8, #root .league_component .content_league .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .league_component .content_league .ajs-header {
  text-align: center;
}
#root .league_component .content_league .b_2 {
  text-align: center;
}
#root .league_component .bottom .b_2 {
  text-align: center;
  color: #1fc16b;
  margin-bottom: 10px;
}
#root .league_component .bottom .background_btn {
  width: 100%;
  padding: 3px;
  border-radius: 16px;
}
#root .league_component.premium .block {
  display: inline-block;
}

@media screen and (max-width: 1440px) {
  #root .leagues_component .leagues {
    gap: 30px;
  }
  #root .league_component {
    flex: 1 1 calc(25% - 30px);
  }
  #root .league_component .content_league figure {
    width: 80px;
    height: 80px;
  }
}
@media screen and (max-width: 1280px) {
  #root .league_component {
    flex: 1 1 calc(50% - 30px);
  }
}
@media screen and (max-width: 768px) {
  #root .league_component {
    flex: 1 1 100%;
  }
}
#root .register-login-page .content.login .form .phone-mail-username .phone {
  position: relative;
}
#root .register-login-page .content.login .form .phone-mail-username .phone input {
  width: 100%;
}
#root .register-login-page .content.login .form .phone-mail-username .mail {
  position: relative;
}
#root .register-login-page .content.login .form .phone-mail-username .username {
  position: relative;
}
#root .register-login-page .content.login .form .phone-mail-username .input {
  width: 100%;
}
#root .register-login-page .content.login .form .password {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 32px 0;
  position: relative;
}
#root .register-login-page .content.login .form .remember-forget {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#root .register-login-page .content.login .form .remember-forget .b_2 {
  color: #345dff;
}
#root .register-login-page .content.login .form .remember-forget .remember-me .custom-checkbox {
  padding-left: 0;
  padding-right: 20px;
}
#root .register-login-page .content.login .form .remember-forget .remember-me .custom-checkbox .checkmark {
  right: 0;
  left: auto;
}
#root .register-login-page .content.login .forgot-password .b_1.desc, #root .register-login-page .content.login .forgot-password .alertify .ajs-dialog .ajs-body .desc.ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.login .forgot-password .desc.ajs-content {
  margin-top: 12px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail {
  margin: 130px 0 190px 0;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .links {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 15px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .links .b_3 {
  width: 100%;
  border: 3px solid #345dff;
  border-radius: 12px;
  color: #345dff;
  padding: 12px;
  background-color: #fdfdff;
  text-align: center;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .links .b_3.active {
  background-color: #345dff;
  color: #fdfdff;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .phone {
  position: relative;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .phone input {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .mail {
  position: relative;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .input {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure {
  width: 16px;
  height: 16px;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure svg.xmark {
  fill: #d00416;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term figure svg.correct {
  fill: #1fc16b;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .b_4.wrong, #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .wrong.ajs-ok, #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .wrong.ajs-cancel {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .b_4.correct, #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .correct.ajs-ok, #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password form .phone-mail .terms .term .correct.ajs-cancel {
  color: #1fc16b;
}
#root .register-login-page .content.login .forgot-password form .phone-mail .terms.active {
  height: -moz-max-content;
  height: max-content;
}
#root .register-login-page .content.login .forgot-password form .background_btn {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password .verify-code-page {
  margin-top: 50px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt {
  display: none;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt .h_8, #root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .register-login-page .content.login .forgot-password .verify-code-page .wrong-txt .ajs-header {
  color: #d00416;
  text-align: center;
  margin: 20px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 25px;
  margin-bottom: 75px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .b_1, #root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .ajs-content {
  width: 95%;
  text-align: center;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links .b_2 {
  color: #345dff;
  text-align: center;
  background: none;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links .b_2:disabled, #root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .content-verify-inner .links .b_2[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .content-verify .counter {
  text-align: center;
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .verify-code-page .background_btn {
  width: 100%;
}
#root .register-login-page .content.login .forgot-password .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5019607843);
}
#root .register-login-page .content.login .forgot-password .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character:where(.vi__character--selected) {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .verify-code-page.wrong .wrong-txt {
  display: block;
}
#root .register-login-page .content.login .forgot-password .set-password p.b_1, #root .register-login-page .content.login .forgot-password .set-password .alertify .ajs-dialog .ajs-body p.ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.login .forgot-password .set-password p.ajs-content {
  margin-top: 12px;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords {
  display: flex;
  flex-direction: column;
  gap: 75px;
  margin: 95px 0 190px 0;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .password,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .password .b_4, #root .register-login-page .content.login .forgot-password .set-password .passwords .password .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .password .ajs-ok, #root .register-login-page .content.login .forgot-password .set-password .passwords .password .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .password .ajs-cancel,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .b_4,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .alertify .ajs-dialog .ajs-footer .ajs-ok,
.alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .ajs-ok,
#root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .alertify .ajs-dialog .ajs-footer .ajs-cancel,
.alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .password-again .ajs-cancel {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure {
  width: 16px;
  height: 16px;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure svg.xmark {
  fill: #d00416;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term figure svg.correct {
  fill: #1fc16b;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .b_4.wrong, #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .wrong.ajs-ok, #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .wrong.ajs-cancel {
  color: #d00416;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .b_4.correct, #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .correct.ajs-ok, #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.login .forgot-password .set-password .passwords .terms .term .correct.ajs-cancel {
  color: #1fc16b;
}
#root .register-login-page .content.login .forgot-password .set-password .passwords .terms.active {
  height: -moz-max-content;
  height: max-content;
}

#root .matches_page {
  margin-top: 75px;
  padding: 20px var(--main-gap) 130px var(--main-gap);
  position: relative;
  min-height: 100vh;
}
#root .matches_page .matches_content .h_5.hood {
  text-align: center;
  margin: 25px 0 70px 0;
}
#root .matches_page .matches_content .matches {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 48px;
}
#root .matches_page .matches_content.no {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}
#root .matches_page .matches_content.no .h_3 {
  text-align: center;
  width: 75%;
  color: #49494d;
}
#root .matches_page .matches_content.no .h_8, #root .matches_page .matches_content.no .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .matches_page .matches_content.no .ajs-header {
  text-align: center;
  color: #49494d;
}
#root .matches_page .matches_content.no figure {
  width: 28px;
}
#root .matches_page .matches_content.no figure svg {
  width: 100%;
  height: 100%;
  fill: #49494d;
}
#root .favorite_match {
  margin-top: 60px;
}
#root .favorite_match .hood_match {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
#root .favorite_match .hood_match a.outline_btn, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-ok, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-cancel {
  padding: 2px 10px;
  border-radius: 16px;
  cursor: pointer;
}
#root .favorite_match .hood_match a.outline_btn .b_1, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-ok .b_1, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-ok .b_1, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-cancel .b_1, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-cancel .b_1, #root .favorite_match .hood_match a.outline_btn .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .favorite_match .hood_match a.outline_btn .ajs-content, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-ok .ajs-body .ajs-content, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-body .ajs-footer a.ajs-ok .ajs-content, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-ok .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .favorite_match .hood_match a.ajs-ok .ajs-content, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-footer a.ajs-cancel .ajs-body .ajs-content, #root .favorite_match .hood_match .alertify .ajs-dialog .ajs-body .ajs-footer a.ajs-cancel .ajs-content, .alertify .ajs-dialog .ajs-footer #root .favorite_match .hood_match a.ajs-cancel .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body .ajs-footer #root .favorite_match .hood_match a.ajs-cancel .ajs-content {
  color: #345dff;
}
#root .favorite_match .matches {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 48px;
  position: relative;
}

@media screen and (max-width: 1440px) {
  #root .matches_page .matches_content .matches {
    gap: 30px;
  }
  #root .favorite_match .matches {
    gap: 30px;
  }
}
@media screen and (max-width: 1280px) {
  #root .favorite_match .matches {
    align-items: normal;
  }
}
#root .coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #1a4573;
  gap: 30px;
}
#root .coming-soon .h_1 {
  color: #fdfdff;
}
#root .coming-soon .h_5 {
  text-align: center;
  width: 35%;
  color: #fdfdff;
}
#root .coming-soon .outline_btn, #root .coming-soon .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .coming-soon .ajs-ok, #root .coming-soon .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .coming-soon .ajs-cancel {
  color: #fdfdff;
  background: transparent;
  border-color: #fdfdff;
}

#root .package_detail {
  margin-top: 75px;
  padding: 30px var(--main-gap) 100px var(--main-gap);
  min-height: 100vh;
}
#root .package_detail .hood {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  margin-bottom: 25px;
}
#root .package_detail .matches {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
  margin-bottom: 60px;
}

#root .packages_page {
  margin-top: 75px;
  padding: 30px var(--main-gap) 100px var(--main-gap);
  min-height: 100vh;
}
#root .packages_page .packages_hood {
  display: flex;
  flex-direction: column;
  gap: 25px;
  text-align: center;
  margin-bottom: 25px;
}
#root .packages_page .packages_filter {
  margin-bottom: 40px;
}
#root .packages_page .packages_filter .filter {
  display: flex;
  align-items: stretch;
  gap: 15px;
  position: relative;
}
#root .packages_page .packages_filter .filter .week,
#root .packages_page .packages_filter .filter .status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5px;
}
#root .packages_page .package_items {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
}
#root .no_package {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  text-align: center;
  margin-top: 50px;
}
#root .no_package .h_3 {
  color: #49494d;
}
#root .no_package .h_8, #root .no_package .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .no_package .ajs-header {
  color: #49494d;
}
#root .no_package .outline_btn, #root .no_package .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .no_package .ajs-ok, #root .no_package .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .no_package .ajs-cancel {
  border-radius: 18px;
  padding: 2px 16px;
  color: #345dff;
}
#root .package_item {
  background-color: #fdfdff;
  border: 3px solid rgba(55, 55, 57, 0.5019607843);
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 16px;
  width: 100%;
}
#root .package_item a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
}
#root .package_item a .package_info {
  display: flex;
  align-items: center;
  gap: 70px;
  width: stretch;
  width: -moz-available;
  width: -webkit-fill-available;
}
#root .package_item a .package_info .b_1, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .ajs-content {
  color: #040814;
  overflow: hidden;
  width: 9.0909090909%;
}
#root .package_item a .package_info .b_1.id, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .id.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .id.ajs-content, #root .package_item a .package_info .b_1.correct_prediction, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .correct_prediction.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .correct_prediction.ajs-content {
  color: #345dff;
}
#root .package_item a .package_info .b_1.correct_prediction, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .correct_prediction.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .correct_prediction.ajs-content {
  width: 13.6363636364%;
}
#root .package_item a .package_info .b_1.point, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .point.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .point.ajs-content {
  color: #1fc16b;
}
#root .package_item a .package_info .b_1.completed, #root .package_item a .package_info .alertify .ajs-dialog .ajs-body .completed.ajs-content, .alertify .ajs-dialog .ajs-body #root .package_item a .package_info .completed.ajs-content {
  width: -moz-fit-content;
  width: fit-content;
}
#root .package_item a figure {
  width: 15px;
}
#root .package_item a figure svg {
  width: 100%;
  height: 100%;
  fill: #040814;
}
#root .package_item.active {
  border-color: rgba(52, 93, 255, 0.5019607843);
}
#root .pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #fdfdff;
  border-radius: 1500px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.5019607843);
  padding: 10px 20px;
}
#root .pagination button {
  background: none;
}
#root .pagination .arrow_btn figure {
  height: 20px;
}
#root .pagination .arrow_btn figure svg {
  width: 100%;
  height: 100%;
  fill: #5e5e62;
}
#root .pagination .b_3 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #5e5e62;
}
#root .pagination .b_3.active {
  background-color: #345dff;
  color: #fdfdff;
}

#root .prediction_page {
  margin-top: 75px;
  min-height: 100vh;
  padding: 80px var(--main-gap) 135px var(--main-gap);
}
#root .prediction_page .hood.h_5 {
  text-align: center;
}
#root .prediction_page .hood.h_8, #root .prediction_page .alertify .ajs-dialog .hood.ajs-header, .alertify .ajs-dialog #root .prediction_page .hood.ajs-header {
  margin-top: 25px;
  text-align: center;
}
#root .prediction_page .prediction_page_content {
  display: flex;
  flex-direction: column;
  gap: 60px;
}
#root .prediction_page .prediction_page_content .right_to_and_points {
  display: flex;
  gap: 36px;
  justify-content: space-between;
  margin-top: 40px;
  width: 100%;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fdfdff;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  border-radius: 20px;
  padding: 6px 12px;
  width: calc(50% - 18px);
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood {
  display: flex;
  align-items: center;
  gap: 10px;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood figure,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood figure,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood figure {
  height: 35px;
  display: flex;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood figure img,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood figure img,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood figure img {
  width: 100%;
  height: 100%;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.h_8,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood .alertify .ajs-dialog h6.ajs-header,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.ajs-header {
  display: flex;
  align-items: flex-start;
  gap: 5px;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.h_8 .info,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood .alertify .ajs-dialog h6.ajs-header .info,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.ajs-header .info {
  position: relative;
  cursor: pointer;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info figure, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info figure, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info figure,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info figure,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info figure,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info figure,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.h_8 .info figure,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood .alertify .ajs-dialog h6.ajs-header .info figure,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.ajs-header .info figure {
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #757578;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info figure svg, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info figure svg, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info figure svg,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info figure svg,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info figure svg,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info figure svg,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.h_8 .info figure svg,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood .alertify .ajs-dialog h6.ajs-header .info figure svg,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.ajs-header .info figure svg {
  width: 100%;
  height: 100%;
  fill: #757578;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info .modal, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info .modal, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info .modal,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.h_8 .info .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood .alertify .ajs-dialog h6.ajs-header .info .modal,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.ajs-header .info .modal {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 14px 8px;
  border: 1px solid #040814;
  background: #fdfdff;
  border-radius: 4px 4px 4px 0;
  width: -moz-max-content;
  width: max-content;
  display: none;
}
#root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.h_8 .info:hover .modal, #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood .alertify .ajs-dialog h6.ajs-header .info:hover .modal, .alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .right_to_predictions div.hood h6.ajs-header .info:hover .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.h_8 .info:hover .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood .alertify .ajs-dialog h6.ajs-header .info:hover .modal,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .total_points div.hood h6.ajs-header .info:hover .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.h_8 .info:hover .modal,
#root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood .alertify .ajs-dialog h6.ajs-header .info:hover .modal,
.alertify .ajs-dialog #root .prediction_page .prediction_page_content .right_to_and_points .match_count div.hood h6.ajs-header .info:hover .modal {
  display: inline-block;
}
#root .prediction_page .prediction_page_content .predictions_and_settings {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions {
  display: flex;
  flex-wrap: wrap;
  gap: 36px;
  width: 100%;
  margin-bottom: 60px;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .background_btn {
  border-radius: 12px;
  padding: 2px 36px;
  width: auto;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .background_btn[data-loader=true] p {
  color: #345dff;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .background_btn[data-loader=true] .btn-loader {
  border-color: #345dff;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction {
  display: flex;
  flex-direction: column;
  gap: 60px;
  align-items: center;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction h6.h_7 {
  color: #49494d;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction .outline_btn.b_3, #root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction .alertify .ajs-dialog .ajs-footer .b_3.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction .b_3.ajs-ok, #root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction .alertify .ajs-dialog .ajs-footer .b_3.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .prediction_page .prediction_page_content .predictions_and_settings .predictions .no_prediction .b_3.ajs-cancel {
  padding: 6px 18px;
  border-radius: 18px;
  width: -moz-fit-content;
  width: fit-content;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .background_btn {
  border-radius: 20px;
  padding: 6px 36px;
  width: 25%;
}
#root .prediction_page .prediction_page_content .predictions_and_settings .background_btn p {
  color: #fdfdff;
}

#root .profile_page {
  margin-top: 75px;
  padding: 50px var(--main-gap) 130px var(--main-gap);
  min-height: 100vh;
}
#root .profile_page .profile {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
#root .profile_page .profile .left {
  display: flex;
  align-items: center;
  gap: 50px;
}
#root .profile_page .profile .left figure.logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
}
#root .profile_page .profile .left figure.logo img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .profile_page .profile .left .txt {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
#root .profile_page .profile .left .txt .when_play_league {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
#root .profile_page .profile .left .txt .when_play_league div {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .profile_page .profile .left .txt .when_play_league div figure {
  width: 16px;
  display: flex;
}
#root .profile_page .profile .left .txt .when_play_league div figure svg {
  width: 100%;
  height: 100%;
}
#root .profile_page .profile .right a {
  display: flex;
  gap: 10px;
  padding: 8px;
  border-radius: 12px;
  box-shadow: 0px 5px 16px 0px rgba(4, 8, 20, 0.2509803922);
  background-color: #fdfdff;
}
#root .profile_page .profile .right a figure {
  width: 15px;
  display: flex;
}
#root .profile_page .profile .right a figure svg {
  width: 100%;
  height: 100%;
}
#root .profile_page .profile .right a .b_3 {
  color: #040814;
}
#root .profile_page .profile_sect {
  border: 3px solid #c9c9ce;
  border-radius: 24px;
  padding: 36px;
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-top: 50px;
  position: relative;
}
#root .profile_page .profile_sect .cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 25px;
  position: relative;
}
#root .profile_page .profile_sect .cards .stat_card,
#root .profile_page .profile_sect .cards .achievement_card {
  flex: 0 0 calc(25% - 25px);
}
#root .profile_page .profile_sect .cards .stat_card figure,
#root .profile_page .profile_sect .cards .achievement_card figure {
  color: #040814;
}
#root .profile_page .profile_sect.point_history .point_history_table {
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0 25px;
}
#root .profile_page .profile_sect.point_history .point_history_table th,
#root .profile_page .profile_sect.point_history .point_history_table td {
  width: 10%;
}
#root .profile_page .profile_sect.point_history .point_history_table thead tr {
  background-color: #fdfdff;
}
#root .profile_page .profile_sect.point_history .point_history_table thead tr th {
  text-align: start;
  border-top: 3px solid #c9c9ce;
  border-bottom: 3px solid #c9c9ce;
  padding: 12px;
}
#root .profile_page .profile_sect.point_history .point_history_table thead tr th:first-child {
  border-left: 3px solid #c9c9ce;
  border-radius: 12px 0 0 12px;
}
#root .profile_page .profile_sect.point_history .point_history_table thead tr th:last-child {
  border-right: 3px solid #c9c9ce;
  border-radius: 0 12px 12px 0;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr {
  margin-top: 25px;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td {
  padding: 12px;
  background-color: #fdfdff;
  border-top: 3px solid rgba(52, 93, 255, 0.1019607843);
  border-bottom: 3px solid rgba(52, 93, 255, 0.1019607843);
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td:first-child {
  border-left: 3px solid rgba(52, 93, 255, 0.1019607843);
  border-radius: 16px 0 0 16px;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td:last-child {
  border-right: 3px solid rgba(52, 93, 255, 0.1019607843);
  border-radius: 0 16px 16px 0;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td.id {
  color: #345dff;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td.point {
  color: #1fc16b;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td figure {
  display: flex;
  justify-content: flex-end;
}
#root .profile_page .profile_sect.point_history .point_history_table tbody tr td figure svg {
  width: 16px;
}
#root .profile_page .profile_sect.point_history .no_data {
  padding: 12px;
  background-color: #fdfdff;
  border: 3px solid rgba(52, 93, 255, 0.1019607843);
  border-radius: 16px;
  text-align: center;
}

#root .register-login-page .content.register {
  padding-top: 30px;
}
#root .register-login-page .content.register .form .third-party-register {
  margin-top: 20px;
}
#root .register-login-page .content.register .form .mail-username {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}
#root .register-login-page .content.register .form .mail-username .input {
  width: 100%;
}
#root .register-login-page .content.register .form .mail-username .mail-phone {
  flex: 1 1 100%;
}
#root .register-login-page .content.register .form .mail-username .mail-phone .phone {
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .mail-phone .phone .phone-input #phone {
  width: 100%;
}
#root .register-login-page .content.register .form .mail-username .mail-phone .mail {
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .username {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1 1 100%;
  gap: 7px;
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .username .label {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content.register .form .mail-username .username .label .info {
  position: relative;
}
#root .register-login-page .content.register .form .mail-username .username .label .info figure {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  border-radius: 50%;
  border: 1px solid #757578;
  cursor: pointer;
}
#root .register-login-page .content.register .form .mail-username .username .label .info figure svg {
  width: 100%;
  height: 100%;
  fill: #757578;
}
#root .register-login-page .content.register .form .mail-username .username .label .info .modal {
  position: absolute;
  bottom: 16px;
  left: 16px;
  padding: 14px 8px;
  border: 1px solid #040814;
  background: #fdfdff;
  border-radius: 4px 4px 4px 0;
  width: -moz-max-content;
  width: max-content;
  display: none;
}
#root .register-login-page .content.register .form .mail-username .username .label .info:hover .modal {
  display: block;
}
#root .register-login-page .content.register .form .passwords {
  display: flex;
  flex-direction: column;
  gap: 75px;
  margin: 30px 0;
}
#root .register-login-page .content.register .form .passwords .password,
#root .register-login-page .content.register .form .passwords .password-again {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
}
#root .register-login-page .content.register .form .passwords .password .b_4, #root .register-login-page .content.register .form .passwords .password .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .form .passwords .password .ajs-ok, #root .register-login-page .content.register .form .passwords .password .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .form .passwords .password .ajs-cancel,
#root .register-login-page .content.register .form .passwords .password-again .b_4,
#root .register-login-page .content.register .form .passwords .password-again .alertify .ajs-dialog .ajs-footer .ajs-ok,
.alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .form .passwords .password-again .ajs-ok,
#root .register-login-page .content.register .form .passwords .password-again .alertify .ajs-dialog .ajs-footer .ajs-cancel,
.alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .form .passwords .password-again .ajs-cancel {
  color: #d00416;
}
#root .register-login-page .content.register .form .agreements {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  margin-top: 10px;
}
#root .register-login-page .content.register .form .agreements .agreement .b_2 {
  color: #345dff;
}
#root .register-login-page .content.register .form .agreements .agreement .b_2.wrong {
  color: #d00416;
}
#root .register-login-page .content.register .form .background_btn {
  margin-top: 12px;
  width: 100%;
}
#root .register-login-page .content.register .verify-code-page {
  margin-top: 50px;
}
#root .register-login-page .content.register .verify-code-page .wrong-txt {
  display: none;
}
#root .register-login-page .content.register .verify-code-page .wrong-txt .h_8, #root .register-login-page .content.register .verify-code-page .wrong-txt .alertify .ajs-dialog .ajs-header, .alertify .ajs-dialog #root .register-login-page .content.register .verify-code-page .wrong-txt .ajs-header {
  color: #d00416;
  text-align: center;
  margin: 20px;
}
#root .register-login-page .content.register .verify-code-page .content-verify {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  margin-top: 25px;
  margin-bottom: 75px;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .b_1, #root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .alertify .ajs-dialog .ajs-body .ajs-content, .alertify .ajs-dialog .ajs-body #root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .ajs-content {
  width: 80%;
  text-align: center;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links .b_2 {
  color: #345dff;
  text-align: center;
  background: none;
}
#root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links .b_2:disabled, #root .register-login-page .content.register .verify-code-page .content-verify .content-verify-inner .links .b_2[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
#root .register-login-page .content.register .verify-code-page .content-verify .counter {
  text-align: center;
  color: #d00416;
}
#root .register-login-page .content.register .verify-code-page .background_btn {
  width: 100%;
}
#root .register-login-page .content.register .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character {
  box-shadow: 0px 5px 16px 0px rgba(208, 4, 22, 0.5019607843);
}
#root .register-login-page .content.register .verify-code-page.wrong .verify-code-inputs .vi__container .vi__character:where(.vi__character--selected) {
  color: #d00416;
}
#root .register-login-page .content.register .verify-code-page.wrong .wrong-txt {
  display: block;
}
#root .register-login-page .content.register .fav-team-and-league form {
  display: flex;
  flex-direction: column;
  gap: 36px;
  margin-top: 20px;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .b_2 {
  margin-bottom: 10px;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .select-trigger .content-drop .img-drop {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .select-trigger .content-drop .img-drop img {
  -o-object-fit: cover;
     object-fit: cover;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .options .option .img-drop {
  overflow: hidden;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
#root .register-login-page .content.register .fav-team-and-league form .leagues .custom-dropdown .options .option .img-drop img {
  -o-object-fit: cover;
     object-fit: cover;
}
#root .register-login-page .content.register .fav-team-and-league form hr {
  border: 0;
  height: 1px;
  color: rgba(4, 8, 20, 0.5019607843);
  background: rgba(4, 8, 20, 0.5019607843);
}
#root .register-login-page .content.register .fav-team-and-league form .teams .b_2 {
  margin-bottom: 10px;
}
#root .register-login-page .content.register .fav-team-and-league form .btns {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 46px;
}
#root .register-login-page .content.register .fav-team-and-league form .btns .outline_btn, #root .register-login-page .content.register .fav-team-and-league form .btns .alertify .ajs-dialog .ajs-footer .ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .fav-team-and-league form .btns .ajs-ok, #root .register-login-page .content.register .fav-team-and-league form .btns .alertify .ajs-dialog .ajs-footer .ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content.register .fav-team-and-league form .btns .ajs-cancel {
  color: #345dff;
}

#root .register-login-page {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
#root .register-login-page .img {
  display: flex;
  width: 40%;
}
#root .register-login-page .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
#root .register-login-page .content {
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 50px 160px 50px 90px;
  overflow: auto;
}
#root .register-login-page .content .register_login_hood {
  display: flex;
  align-items: center;
  gap: 30px;
  padding-left: 5px;
}
#root .register-login-page .content .register_login_hood a figure {
  display: flex;
  width: 25px;
}
#root .register-login-page .content .register_login_hood a figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content .form .third-party-register {
  display: flex;
  justify-content: space-between;
  gap: 22px;
  margin-top: 50px;
  margin-bottom: 25px;
}
#root .register-login-page .content .form .divider {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
#root .register-login-page .content .form .divider hr {
  height: 1px;
  width: 100%;
  color: #c9c9ce;
  background-color: #c9c9ce;
}
#root .register-login-page .content .form .divider .b_2 {
  width: stretch;
  width: -moz-available;
  width: -webkit-fill-available;
  text-align: center;
}
#root .register-login-page .content .form .show_pass {
  position: relative;
}
#root .register-login-page .content .form .show_pass .input {
  width: 100%;
  padding-right: 50px;
}
#root .register-login-page .content .form .show_pass figure {
  display: flex;
  position: absolute;
  width: 20px;
  top: calc(50% - 6.5px);
  right: 10px;
  cursor: pointer;
}
#root .register-login-page .content .form .show_pass figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content .form .terms {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  height: 0;
  overflow: hidden;
  transition: 300ms all;
}
#root .register-login-page .content .form .terms .term {
  display: flex;
  align-items: center;
  gap: 5px;
}
#root .register-login-page .content .form .terms .term figure {
  width: 16px;
  height: 16px;
}
#root .register-login-page .content .form .terms .term figure svg {
  width: 100%;
  height: 100%;
}
#root .register-login-page .content .form .terms .term figure svg.xmark {
  fill: #d00416;
}
#root .register-login-page .content .form .terms .term figure svg.correct {
  fill: #1fc16b;
}
#root .register-login-page .content .form .terms .term .b_4.wrong, #root .register-login-page .content .form .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content .form .terms .term .wrong.ajs-ok, #root .register-login-page .content .form .terms .term .alertify .ajs-dialog .ajs-footer .wrong.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content .form .terms .term .wrong.ajs-cancel {
  color: #d00416 !important;
}
#root .register-login-page .content .form .terms .term .b_4.correct, #root .register-login-page .content .form .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-ok, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content .form .terms .term .correct.ajs-ok, #root .register-login-page .content .form .terms .term .alertify .ajs-dialog .ajs-footer .correct.ajs-cancel, .alertify .ajs-dialog .ajs-footer #root .register-login-page .content .form .terms .term .correct.ajs-cancel {
  color: #1fc16b !important;
}
#root .register-login-page .content .form .terms.active {
  height: -moz-max-content;
  height: max-content;
}
#root .register-login-page .content .form .links {
  display: flex;
  gap: 24px;
  align-items: center;
  margin-bottom: 15px;
}
#root .register-login-page .content .form .links .b_3 {
  width: 100%;
  border: 3px solid #345dff;
  border-radius: 12px;
  color: #345dff;
  padding: 4px;
  background-color: #fdfdff;
  text-align: center;
}
#root .register-login-page .content .form .links .b_3.active {
  background-color: #345dff;
  color: #fdfdff;
}
#root .register-login-page .content .form .recaptcha {
  display: inline-block;
  margin-top: 20px;
}
#root .register-login-page .content .form .recaptcha .b_3 {
  display: none;
  color: #d00416;
}
#root .register-login-page .content .form .recaptcha.wrong {
  border: 2px solid #d00416;
  border-radius: 5px;
  padding: 5px 8px;
}
#root .register-login-page .content .form .recaptcha.wrong .b_3 {
  display: inline-block;
}
#root .register-login-page .content .form .background_btn {
  width: 100%;
  margin: 24px 0 24px 0;
}
#root .register-login-page .content .form .redirect {
  color: #345dff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1440px) {
  #root .register-login-page .img {
    width: 30%;
  }
  #root .register-login-page .content {
    width: 70%;
  }
}
@media screen and (max-width: 1280px) {
  #root .register-login-page .content {
    padding: 50px 90px 50px 50px;
  }
  #root .register-login-page .content .register_login_hood {
    gap: 25px;
  }
  #root .register-login-page .content .register_login_hood a figure {
    width: 20px;
  }
}
@media screen and (max-width: 1024px) {
  #root .register-login-page .img {
    width: 25%;
  }
  #root .register-login-page .content {
    width: 75%;
    padding: 50px 50px 50px 30px;
  }
  #root .register-login-page .content .register_login_hood a figure {
    width: 18px;
  }
  #root .register-login-page .content .form .third-party-register {
    gap: 12px;
  }
  #root .register-login-page .content .form .links {
    gap: 14px;
  }
  #root .register-login-page .content .form .links .b_3 {
    border: 2px solid #345dff;
  }
}
@media screen and (max-width: 768px) {
  #root .register-login-page .img {
    display: none;
  }
  #root .register-login-page .content {
    width: 100%;
    padding: 50px var(--main-gap);
  }
  #root .register-login-page .content .form .links .b_3 {
    border-radius: 10px;
  }
}
@media screen and (max-width: 480px) {
  #root .register-login-page .content .register_login_hood {
    gap: 20px;
  }
  #root .register-login-page .content .register_login_hood a figure {
    width: 15px;
  }
  #root .register-login-page .content .form .third-party-register {
    margin-top: 25px;
  }
  #root .register-login-page .content .form .recaptcha {
    transform: scale(0.8);
    transform-origin: 0 0;
  }
}/*# sourceMappingURL=main.css.map */