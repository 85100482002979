.progress_bar {
  position: relative;
  width: 100px;
  height: 10px;
  border: 1px solid map-get($color, "dark/alpha/25");
  border-radius: 50px;
  overflow: hidden;
  .progress {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    height: 100%;
    background-color: map-get($color, "primary/300");
  }
}

@include mediaQuery(1024px) {
  // 768 // Tablet
  .progress_bar {
    width: 80px;
    height: 6px;
    // border: 1px solid map-get($color, "dark/alpha/25");
    // border-radius: 50px;
    .progress {
    }
  }
}
