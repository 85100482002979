.third_party_btns {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px;
  @include box_shadow(map-get($color, "dark/alpha/25"));
  border-radius: 12px;
  background-color: map-get($color, "neutral/100");
  svg {
    width: 30px;
    height: 30px;
  }
  &.btn_apple {
    fill: #000;
  }
  &.btn_google {
    path:nth-child(1) {
      fill: #4285f4;
    }
    path:nth-child(2) {
      fill: #34a853;
    }
    path:nth-child(3) {
      fill: #fbbc05;
    }
    path:nth-child(4) {
      fill: #ea4335;
    }
  }
  &.btn_facebook {
    svg {
      fill: #0866ff;
    }
  }
}

.background_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: map-get($color, "neutral/100");
  background: map-get($color, "primary/300");
  border: 1px solid map-get($color, "primary/300");
  padding: 13px 10px;
  border-radius: 12px;
  transition: 300ms all;
  h4 {
    color: map-get($color, "neutral/100");
  }
  .btn-loader {
    display: none;
  }
  &:hover {
    background: map-get($color, "primary/400");
    border-color: map-get($color, "primary/400");
    box-shadow: 0px 4px 4px 0px map-get($color, "dark/alpha/25");
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background: map-get($color, "neutral/300");
    border-color: map-get($color, "neutral/300");
    color: map-get($color, "dark/100");
    &:hover {
      box-shadow: 0 0 0 0;
    }
    &:active {
      border: 3px solid map-get($color, "neutral/300");
    }
  }
  &:active {
    border: 1px solid map-get($color, "dark/alpha/50");
    box-shadow: 0 0 0 0;
  }
  &[data-loader="true"] {
    cursor: not-allowed;
    background: map-get($color, "primary/300");
    border: 1px solid map-get($color, "dark/alpha/50");
    color: map-get($color, "neutral/100");
    .btn-loader {
      display: inline-block;
    }
    &:hover {
      box-shadow: 0 0 0 0;
    }
    &:active {
      border: 1px solid map-get($color, "dark/alpha/50");
    }
  }
}

.outline_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  color: map-get($color, "primary/300");
  border: 3px solid map-get($color, "primary/300");
  padding: 13px 10px;
  border-radius: 12px;
  // background: none;
  background-color: map-get($color, "neutral/100");
  transition: 300ms all;
  .btn-loader {
    display: none;
    border-color: map-get($color, "primary/300") !important;
  }
  &:hover {
    box-shadow: 4px 4px 4px 0px map-get($color, "dark/alpha/25");
  }
  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    border-color: map-get($color, "neutral/400");
    color: map-get($color, "neutral/400");
    &:hover {
      box-shadow: 0 0 0 0;
    }
    &:active {
      background: none;
    }
  }
  &:active {
    background-color: map-get($color, "neutral/100");
    box-shadow: 0 0 0 0;
  }
  &[data-loader="true"] {
    cursor: not-allowed;
    background: map-get($color, "neutral/100");
    border-color: map-get($color, "primary/300");
    color: map-get($color, "primary/300");
    .btn-loader {
      display: inline-block;
    }
    &:hover {
      box-shadow: 0 0 0 0;
    }
    &:active {
      border: 1px solid map-get($color, "neutral/400");
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  .third_party_btns {
    // width: 100%;
    // padding: 15px;
    // border-radius: 12px;
    svg {
      // width: 30px;
      // height: 30px;
    }
    &.btn_apple {
    }
    &.btn_google {
      path:nth-child(1) {
      }
      path:nth-child(2) {
      }
      path:nth-child(3) {
      }
      path:nth-child(4) {
      }
    }
    &.btn_facebook {
      svg {
      }
    }
  }
  .outline_btn {
    // gap: 30px;
    // border: 3px solid map-get($color, "primary/300");
    // padding: 13px 10px;
    // border-radius: 12px;
    .btn-loader {
    }
    &:hover {
    }
    &:disabled,
    &[disabled] {
      &:hover {
      }
      &:active {
      }
    }
    &:active {
    }
    &[data-loader="true"] {
      .btn-loader {
      }
      &:hover {
      }
      &:active {
        // border: 1px solid map-get($color, "neutral/400");
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  .third_party_btns {
    // width: 100%;
    padding: 10px;
    // border-radius: 12px;
    svg {
      // width: 30px;
      // height: 30px;
    }
    &.btn_apple {
    }
    &.btn_google {
      path:nth-child(1) {
      }
      path:nth-child(2) {
      }
      path:nth-child(3) {
      }
      path:nth-child(4) {
      }
    }
    &.btn_facebook {
      svg {
      }
    }
  }
  .outline_btn {
    // gap: 30px;
    border: 2px solid map-get($color, "primary/300");
    // padding: 13px 10px;
    // border-radius: 12px;
    .btn-loader {
    }
    &:hover {
    }
    &:disabled,
    &[disabled] {
      &:hover {
      }
      &:active {
      }
    }
    &:active {
    }
    &[data-loader="true"] {
      .btn-loader {
      }
      &:hover {
      }
      &:active {
        // border: 1px solid map-get($color, "neutral/400");
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  .third_party_btns {
    // width: 100%;
    // padding: 10px;
    // border-radius: 12px;
    svg {
      width: 25px;
      height: 25px;
    }
    &.btn_apple {
    }
    &.btn_google {
      path:nth-child(1) {
      }
      path:nth-child(2) {
      }
      path:nth-child(3) {
      }
      path:nth-child(4) {
      }
    }
    &.btn_facebook {
      svg {
      }
    }
  }
  .outline_btn {
    // gap: 30px;
    // border: 2px solid map-get($color, "primary/300");
    // padding: 13px 10px;
    // border-radius: 12px;
    .btn-loader {
    }
    &:hover {
    }
    &:disabled,
    &[disabled] {
      &:hover {
      }
      &:active {
      }
    }
    &:active {
    }
    &[data-loader="true"] {
      .btn-loader {
      }
      &:hover {
      }
      &:active {
        // border: 1px solid map-get($color, "neutral/400");
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768 // Tablet
  .third_party_btns {
    // width: 100%;
    padding: 8px;
    // border-radius: 12px;
    svg {
      // width: 25px;
      // height: 25px;
    }
    &.btn_apple {
    }
    &.btn_google {
      path:nth-child(1) {
      }
      path:nth-child(2) {
      }
      path:nth-child(3) {
      }
      path:nth-child(4) {
      }
    }
    &.btn_facebook {
      svg {
      }
    }
  }
  .outline_btn {
    // gap: 30px;
    // border: 2px solid map-get($color, "primary/300");
    // padding: 13px 10px;
    // border-radius: 12px;
    .btn-loader {
    }
    &:hover {
    }
    &:disabled,
    &[disabled] {
      &:hover {
      }
      &:active {
      }
    }
    &:active {
    }
    &[data-loader="true"] {
      .btn-loader {
      }
      &:hover {
      }
      &:active {
        // border: 1px solid map-get($color, "neutral/400");
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  .third_party_btns {
    // width: 100%;
    // padding: 8px;
    border-radius: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
    &.btn_apple {
    }
    &.btn_google {
      path:nth-child(1) {
      }
      path:nth-child(2) {
      }
      path:nth-child(3) {
      }
      path:nth-child(4) {
      }
    }
    &.btn_facebook {
      svg {
      }
    }
  }
  .outline_btn {
    // gap: 30px;
    // border: 2px solid map-get($color, "primary/300");
    // padding: 13px 10px;
    // border-radius: 12px;
    .btn-loader {
    }
    &:hover {
    }
    &:disabled,
    &[disabled] {
      &:hover {
      }
      &:active {
      }
    }
    &:active {
    }
    &[data-loader="true"] {
      .btn-loader {
      }
      &:hover {
      }
      &:active {
        // border: 1px solid map-get($color, "neutral/400");
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  .third_party_btns {
    // width: 100%;
    padding: 5px;
    border-radius: 8px;
    svg {
      width: 15px;
      height: 15px;
    }
    &.btn_apple {
    }
    &.btn_google {
      path:nth-child(1) {
      }
      path:nth-child(2) {
      }
      path:nth-child(3) {
      }
      path:nth-child(4) {
      }
    }
    &.btn_facebook {
      svg {
      }
    }
  }
  .outline_btn {
    // gap: 30px;
    border: 1px solid map-get($color, "primary/300");
    // padding: 13px 10px;
    // border-radius: 12px;
    .btn-loader {
    }
    &:hover {
    }
    &:disabled,
    &[disabled] {
      &:hover {
      }
      &:active {
      }
    }
    &:active {
    }
    &[data-loader="true"] {
      .btn-loader {
      }
      &:hover {
      }
      &:active {
        // border: 1px solid map-get($color, "neutral/400");
      }
    }
  }
}
