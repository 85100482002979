.match_component {
  width: calc((100% / 2) - (48px / 2));
  @include box_shadow(map-get($color, "dark/alpha/25"));
  border-radius: 16px;
  background-color: map-get($color, "neutral/100");
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 45px 12px;
  gap: 12px;
  position: relative;
  &.mini_gap {
    width: calc((100% / 2) - (36px / 2));
  }
  .background_btn.btn--delete_to_basket {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    border-radius: 50%;
    padding: 5px 8px;
    @include box_shadow(map-get($color, "green/alpha/50"));
    background-color: map-get($color, "neutral/100");
    border: none;
    .btn-loader {
      border-top: 2px solid map-get($color, "red/200");
    }
    figure {
      width: 14px;
      svg {
        width: 100%;
        height: 100%;
        fill: map-get($color, "red/200");
      }
    }
  }
  .existing_info {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      padding: 2px;
      border-radius: 50%;
      border: 1px solid map-get($color, "green/300");
      animation: opacity 1s linear infinite;
      p {
        color: map-get($color, "green/300");
      }
    }
    .modal {
      position: absolute;
      bottom: 16px;
      left: 16px;
      padding: 14px 8px;
      border: 1px solid map-get($color, "green/300");
      background: map-get($color, "neutral/100");
      border-radius: 4px 4px 4px 0;
      width: max-content;
      color: map-get($color, "green/300");
      display: none;
    }
    &:hover {
      figure {
        animation: none;
      }
      .modal {
        display: inline-block;
      }
    }
  }
  .premium {
    position: absolute;
    top: 13px;
    left: 13px;
    figure {
      width: 24px;
      // margin: 20px;
      svg {
        width: 100%;
        height: 100%;
        fill: map-get($color, "yellow/200");
        stroke-width: 5%;
        stroke: map-get($color, "dark/alpha/25");
      }
    }
  }
  .match_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: stretch;
    height: -moz-available;
    height: -webkit-fill-available;
    .teams {
      .team_names {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        .team {
          flex: 1 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          &.left {
            text-align: left;
          }
          &.right {
            text-align: right;
          }
          .h_8 {
            color: map-get($color, "neutral/900");
          }
          .team_last_matches {
            display: flex;
            flex-direction: column;
            gap: 5px;
            .b_4 {
              color: map-get($color, "neutral/900");
            }
            .data {
              display: flex;
              align-items: center;
              gap: 8px;
              .match_info {
                display: inline-block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid map-get($color, "dark/alpha/50");
                position: relative;
                .modal {
                  display: none;
                  position: absolute;
                  top: 12px;
                  z-index: 1;
                  padding: 8px;
                  color: map-get($color, "dark/100");
                  border: 1px solid map-get($color, "dark/100");
                  background: map-get($color, "neutral/100");
                  width: max-content;
                }
                &.W {
                  background-color: map-get($color, "green/300");
                  border: 1px solid map-get($color, "green/300");
                }
                &.L {
                  background-color: map-get($color, "red/200");
                  border: 1px solid map-get($color, "red/200");
                }
                &.D {
                  background-color: map-get($color, "yellow/300");
                  border: 1px solid map-get($color, "yellow/300");
                }
                &:hover {
                  .modal {
                    display: inline-block;
                  }
                }
              }
            }
            &.left {
              text-align: left;
              align-items: flex-start;
              .modal {
                border-radius: 0px 4px 4px 4px;
                left: 3px;
                text-align: left;
              }
            }
            &.right {
              text-align: right;
              align-items: flex-end;
              .modal {
                right: 3px;
                border-radius: 4px 0px 4px 4px;
                text-align: right;
              }
            }
          }
        }
        span.vs {
          flex: 1 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          p,
          .h_8 {
            text-align: center;
            color: map-get($color, "neutral/900");
          }
        }
      }
    }
    .statistics {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      border: 3px solid map-get($color, "neutral/alpha/40");
      padding: 10px;
      border-radius: 24px;
      margin-top: 25px;
      .b_2 {
      }
      .statistic {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        .b_3 {
          color: map-get($color, "neutral/1000");
          display: flex;
          flex-direction: column;
          align-items: center;
          span {
          }
        }
      }
    }
  }
  .background_btn {
    border-radius: 16px;
    padding: 6px 48px;
    .btn-loader {
    }
    .b_3 {
      color: map-get($color, "neutral/100");
    }
    &:disabled,
    &[disabled] {
      background: none;
      border-color: map-get($color, "primary/300");
      .b_3 {
        color: map-get($color, "primary/300");
      }
    }
  }
  .selected_and_delete {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    .selected_and_point {
      // display: flex;
      // justify-content: center;
      // flex: 1 1 40%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .prediction_selected {
        flex: 1 1;
        .h_8 {
          display: flex;
          align-items: center;
          flex-direction: column;
          // gap: 10px;
          span {
            color: map-get($color, "primary/300");
          }
        }
      }
      .prediction_point {
        flex: 1 1;
        .h_8 {
          display: flex;
          align-items: center;
          flex-direction: column;
          // gap: 10px;
          span {
            color: map-get($color, "primary/300");
          }
        }
      }
    }
    .delete {
      // flex: 1 1 10%;
      .background_btn {
        padding: 6px 36px;
        p {
          color: map-get($color, "neutral/100");
        }
      }
    }
  }
  &.added {
    @include box_shadow(map-get($color, "green/alpha/25"));
    .buttons {
      justify-content: space-between;
    }
  }
  &.correct {
    @include box_shadow(map-get($color, "green/alpha/50"));
  }
  &.wrong {
    @include box_shadow(map-get($color, "red/alpha/40"));
  }
  &.canceled {
    box-shadow: none;
    background-color: map-get($color, "neutral/300");
    .match_content {
      .h_8 {
        color: map-get($color, "red/200");
        opacity: 1;
      }
      .teams {
        opacity: 0.5;
        .teams_last_matches {
          .team_last_matches {
            .data {
              .match_info:hover {
                .modal {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
    .selected_and_delete {
      opacity: 0.5;
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  .match_component {
    // width: calc((100% / 2) - (48px / 2));
    width: calc((100% - 48px) / (2));
    // border-radius: 16px;
    // padding: 45px 12px;
    // gap: 12px;
    &.mini_gap {
      // width: calc((100% / 2) - (36px / 2));
    }
    .background_btn.btn--delete_to_basket {
      // padding: 5px 8px;
      .btn-loader {
      }
      figure {
        // width: 14px;
        svg {
        }
      }
    }
    .existing_info {
      // top: 15px;
      // left: 15px;
      figure {
        // width: 20px;
        // height: 20px;
        // padding: 2px;
        p {
        }
      }
      .modal {
        // bottom: 16px;
        // left: 16px;
        // padding: 14px 8px;
        // border-radius: 4px 4px 4px 0;
      }
      &:hover {
        figure {
        }
        .modal {
        }
      }
    }
    .match_content {
      .teams {
        .team_names {
          .team {
            &.left {
            }
            &.right {
            }
            .h_8 {
            }
            .team_last_matches {
              // gap: 5px;
              .b_4 {
              }
              .data {
                // gap: 8px;
                .match_info {
                  // width: 10px;
                  // height: 10px;
                  .modal {
                    // top: 12px;
                    // padding: 8px;
                  }
                  &.W {
                  }
                  &.L {
                  }
                  &.D {
                  }
                  &:hover {
                    .modal {
                    }
                  }
                }
              }
              &.left {
                .modal {
                  // border-radius: 0px 4px 4px 4px;
                  // left: 3px;
                }
              }
              &.right {
                .modal {
                  // right: 3px;
                  // border-radius: 4px 0px 4px 4px;
                }
              }
            }
          }
          span.vs {
            p,
            .h_8 {
            }
          }
        }
      }
      .statistics {
        //   gap: 10px;
        //  padding: 10px;
        //   border-radius: 24px;
        //   margin-top: 25px;
        .b_2 {
        }
        .statistic {
          .b_3 {
            span {
            }
          }
        }
      }
    }
    .background_btn {
      // border-radius: 16px;
      // padding: 6px 48px;
      .btn-loader {
      }
      .b_3 {
      }
      &:disabled,
      &[disabled] {
        .b_3 {
        }
      }
    }
    .selected_and_delete {
      // gap: 10px;
      // width: 100%;
      .selected_and_point {
        // width: 100%;
        .prediction_selected {
          .h_8 {
            span {
            }
          }
        }
        .prediction_point {
          .h_8 {
            span {
            }
          }
        }
      }
      .delete {
        .background_btn {
          // padding: 6px 36px;
          p {
          }
        }
      }
    }
    &.added {
      .buttons {
      }
    }
    &.correct {
    }
    &.wrong {
    }
    &.canceled {
      .match_content {
        .h_8 {
        }
        .teams {
          .teams_last_matches {
            .team_last_matches {
              .data {
                .match_info:hover {
                  .modal {
                  }
                }
              }
            }
          }
        }
      }
      .selected_and_delete {
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  .match_component {
    // width: calc((100% / 2) - (30px / 2));
    width: calc((100% - 30px) / (2));
    // border-radius: 16px;
    // padding: 45px 12px;
    // gap: 12px;
    &.mini_gap {
      // width: calc((100% / 2) - (36px / 2));
    }
    .background_btn.btn--delete_to_basket {
      // padding: 5px 8px;
      .btn-loader {
      }
      figure {
        // width: 14px;
        svg {
        }
      }
    }
    .existing_info {
      // top: 15px;
      // left: 15px;
      figure {
        // width: 20px;
        // height: 20px;
        // padding: 2px;
        p {
        }
      }
      .modal {
        // bottom: 16px;
        // left: 16px;
        // padding: 14px 8px;
        // border-radius: 4px 4px 4px 0;
      }
      &:hover {
        figure {
        }
        .modal {
        }
      }
    }
    .match_content {
      .teams {
        .team_names {
          .team {
            &.left {
            }
            &.right {
            }
            .h_8 {
            }
            .team_last_matches {
              // gap: 5px;
              .b_4 {
              }
              .data {
                // gap: 8px;
                .match_info {
                  // width: 10px;
                  // height: 10px;
                  .modal {
                    // top: 12px;
                    // padding: 8px;
                  }
                  &.W {
                  }
                  &.L {
                  }
                  &.D {
                  }
                  &:hover {
                    .modal {
                    }
                  }
                }
              }
              &.left {
                .modal {
                  // border-radius: 0px 4px 4px 4px;
                  // left: 3px;
                }
              }
              &.right {
                .modal {
                  // right: 3px;
                  // border-radius: 4px 0px 4px 4px;
                }
              }
            }
          }
          span.vs {
            p,
            .h_8 {
            }
          }
        }
      }
      .statistics {
        //   gap: 10px;
        //  padding: 10px;
        //   border-radius: 24px;
        //   margin-top: 25px;
        .b_2 {
        }
        .statistic {
          .b_3 {
            span {
            }
          }
        }
      }
    }
    .background_btn {
      // border-radius: 16px;
      // padding: 6px 48px;
      .btn-loader {
      }
      .b_3 {
      }
      &:disabled,
      &[disabled] {
        .b_3 {
        }
      }
    }
    .selected_and_delete {
      // gap: 10px;
      // width: 100%;
      .selected_and_point {
        // width: 100%;
        .prediction_selected {
          .h_8 {
            span {
            }
          }
        }
        .prediction_point {
          .h_8 {
            span {
            }
          }
        }
      }
      .delete {
        .background_btn {
          // padding: 6px 36px;
          p {
          }
        }
      }
    }
    &.added {
      .buttons {
      }
    }
    &.correct {
    }
    &.wrong {
    }
    &.canceled {
      .match_content {
        .h_8 {
        }
        .teams {
          .teams_last_matches {
            .team_last_matches {
              .data {
                .match_info:hover {
                  .modal {
                  }
                }
              }
            }
          }
        }
      }
      .selected_and_delete {
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  .match_component {
    width: calc((100% / 1));
    // border-radius: 16px;
    // padding: 45px 12px;
    // gap: 12px;
    &.mini_gap {
      width: calc((100% / 1));
    }
    .background_btn.btn--delete_to_basket {
      // padding: 5px 8px;
      .btn-loader {
      }
      figure {
        // width: 14px;
        svg {
        }
      }
    }
    .existing_info {
      // top: 15px;
      // left: 15px;
      figure {
        // width: 20px;
        // height: 20px;
        // padding: 2px;
        p {
        }
      }
      .modal {
        // bottom: 16px;
        // left: 16px;
        // padding: 14px 8px;
        // border-radius: 4px 4px 4px 0;
      }
      &:hover {
        figure {
        }
        .modal {
        }
      }
    }
    .match_content {
      .teams {
        .team_names {
          .team {
            &.left {
            }
            &.right {
            }
            .h_8 {
            }
            .team_last_matches {
              // gap: 5px;
              .b_4 {
              }
              .data {
                // gap: 8px;
                .match_info {
                  // width: 10px;
                  // height: 10px;
                  .modal {
                    // top: 12px;
                    // padding: 8px;
                  }
                  &.W {
                  }
                  &.L {
                  }
                  &.D {
                  }
                  &:hover {
                    .modal {
                    }
                  }
                }
              }
              &.left {
                .modal {
                  // border-radius: 0px 4px 4px 4px;
                  // left: 3px;
                }
              }
              &.right {
                .modal {
                  // right: 3px;
                  // border-radius: 4px 0px 4px 4px;
                }
              }
            }
          }
          span.vs {
            p,
            .h_8 {
            }
          }
        }
      }
      .statistics {
        //   gap: 10px;
        //  padding: 10px;
        //   border-radius: 24px;
        //   margin-top: 25px;
        .b_2 {
        }
        .statistic {
          .b_3 {
            span {
            }
          }
        }
      }
    }
    .background_btn {
      // border-radius: 16px;
      // padding: 6px 48px;
      .btn-loader {
      }
      .b_3 {
      }
      &:disabled,
      &[disabled] {
        .b_3 {
        }
      }
    }
    .selected_and_delete {
      // gap: 10px;
      // width: 100%;
      .selected_and_point {
        // width: 100%;
        .prediction_selected {
          .h_8 {
            span {
            }
          }
        }
        .prediction_point {
          .h_8 {
            span {
            }
          }
        }
      }
      .delete {
        .background_btn {
          // padding: 6px 36px;
          p {
          }
        }
      }
    }
    &.added {
      .buttons {
      }
    }
    &.correct {
    }
    &.wrong {
    }
    &.canceled {
      .match_content {
        .h_8 {
        }
        .teams {
          .teams_last_matches {
            .team_last_matches {
              .data {
                .match_info:hover {
                  .modal {
                  }
                }
              }
            }
          }
        }
      }
      .selected_and_delete {
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768
  .match_component {
    // width: calc((100% / 1));
    // border-radius: 16px;
    // padding: 45px 12px;
    // gap: 12px;
    &.mini_gap {
      // width: calc((100% / 1));
    }
    .background_btn.btn--delete_to_basket {
      // padding: 5px 8px;
      .btn-loader {
      }
      figure {
        // width: 14px;
        svg {
        }
      }
    }
    .existing_info {
      // top: 15px;
      // left: 15px;
      figure {
        // width: 20px;
        // height: 20px;
        // padding: 2px;
        p {
        }
      }
      .modal {
        // bottom: 16px;
        // left: 16px;
        // padding: 14px 8px;
        // border-radius: 4px 4px 4px 0;
      }
      &:hover {
        figure {
        }
        .modal {
        }
      }
    }
    .match_content {
      .teams {
        .team_names {
          .team {
            &.left {
            }
            &.right {
            }
            .h_8 {
            }
            .team_last_matches {
              // gap: 5px;
              .b_4 {
              }
              .data {
                // gap: 8px;
                .match_info {
                  // width: 10px;
                  // height: 10px;
                  .modal {
                    // top: 12px;
                    // padding: 8px;
                  }
                  &.W {
                  }
                  &.L {
                  }
                  &.D {
                  }
                  &:hover {
                    .modal {
                    }
                  }
                }
              }
              &.left {
                .modal {
                  // border-radius: 0px 4px 4px 4px;
                  // left: 3px;
                }
              }
              &.right {
                .modal {
                  // right: 3px;
                  // border-radius: 4px 0px 4px 4px;
                }
              }
            }
          }
          span.vs {
            p,
            .h_8 {
            }
          }
        }
      }
      .statistics {
        //   gap: 10px;
        //  padding: 10px;
        //   border-radius: 24px;
        //   margin-top: 25px;
        .b_2 {
        }
        .statistic {
          .b_3 {
            span {
            }
          }
        }
      }
    }
    .background_btn {
      // border-radius: 16px;
      // padding: 6px 48px;
      .btn-loader {
      }
      .b_3 {
      }
      &:disabled,
      &[disabled] {
        .b_3 {
        }
      }
    }
    .selected_and_delete {
      // gap: 10px;
      // width: 100%;
      .selected_and_point {
        // width: 100%;
        .prediction_selected {
          .h_8 {
            span {
            }
          }
        }
        .prediction_point {
          .h_8 {
            span {
            }
          }
        }
      }
      .delete {
        .background_btn {
          // padding: 6px 36px;
          p {
          }
        }
      }
    }
    &.added {
      .buttons {
      }
    }
    &.correct {
    }
    &.wrong {
    }
    &.canceled {
      .match_content {
        .h_8 {
        }
        .teams {
          .teams_last_matches {
            .team_last_matches {
              .data {
                .match_info:hover {
                  .modal {
                  }
                }
              }
            }
          }
        }
      }
      .selected_and_delete {
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  .match_component {
    // width: calc((100% / 1));
    // border-radius: 16px;
    // padding: 45px 12px;
    // gap: 12px;
    &.mini_gap {
      // width: calc((100% / 1));
    }
    .background_btn.btn--delete_to_basket {
      // padding: 5px 8px;
      .btn-loader {
      }
      figure {
        width: 10px;
        svg {
        }
      }
    }
    .existing_info {
      // top: 15px;
      // left: 15px;
      figure {
        width: 17px;
        height: 17px;
        // padding: 2px;
        p {
        }
      }
      .modal {
        // bottom: 16px;
        // left: 16px;
        // padding: 14px 8px;
        // border-radius: 4px 4px 4px 0;
      }
      &:hover {
        figure {
        }
        .modal {
        }
      }
    }
    .match_content {
      .teams {
        .team_names {
          .team {
            &.left {
            }
            &.right {
            }
            .h_8 {
            }
            .team_last_matches {
              // gap: 5px;
              .b_4 {
              }
              .data {
                // gap: 8px;
                .match_info {
                  // width: 10px;
                  // height: 10px;
                  .modal {
                    // top: 12px;
                    // padding: 8px;
                  }
                  &.W {
                  }
                  &.L {
                  }
                  &.D {
                  }
                  &:hover {
                    .modal {
                    }
                  }
                }
              }
              &.left {
                .modal {
                  // border-radius: 0px 4px 4px 4px;
                  // left: 3px;
                }
              }
              &.right {
                .modal {
                  // right: 3px;
                  // border-radius: 4px 0px 4px 4px;
                }
              }
            }
          }
          span.vs {
            p,
            .h_8 {
            }
          }
        }
      }
      .statistics {
        //   gap: 10px;
        padding: 8px;
        border-width: 2px;
        border-radius: 20px;
        //   margin-top: 25px;
        .b_2 {
        }
        .statistic {
          .b_3 {
            span {
            }
          }
        }
      }
    }
    .background_btn {
      // border-radius: 16px;
      padding: 3px 36px;
      .btn-loader {
      }
      .b_3 {
      }
      &:disabled,
      &[disabled] {
        .b_3 {
        }
      }
    }
    .selected_and_delete {
      // gap: 10px;
      // width: 100%;
      .selected_and_point {
        // width: 100%;
        .prediction_selected {
          .h_8 {
            span {
            }
          }
        }
        .prediction_point {
          .h_8 {
            span {
            }
          }
        }
      }
      .delete {
        .background_btn {
          // padding: 6px 36px;
          p {
          }
        }
      }
    }
    &.added {
      .buttons {
      }
    }
    &.correct {
    }
    &.wrong {
    }
    &.canceled {
      .match_content {
        .h_8 {
        }
        .teams {
          .teams_last_matches {
            .team_last_matches {
              .data {
                .match_info:hover {
                  .modal {
                  }
                }
              }
            }
          }
        }
      }
      .selected_and_delete {
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  .match_component {
    // width: calc((100% / 1));
    // border-radius: 16px;
    // padding: 45px 12px;
    // gap: 12px;
    &.mini_gap {
      // width: calc((100% / 1));
    }
    .background_btn.btn--delete_to_basket {
      // padding: 5px 8px;
      .btn-loader {
      }
      figure {
        // width: 10px;
        svg {
        }
      }
    }
    .existing_info {
      // top: 15px;
      // left: 15px;
      figure {
        // width: 17px;
        // height: 17px;
        // padding: 2px;
        p {
        }
      }
      .modal {
        // bottom: 16px;
        // left: 16px;
        // padding: 14px 8px;
        // border-radius: 4px 4px 4px 0;
      }
      &:hover {
        figure {
        }
        .modal {
        }
      }
    }
    .match_content {
      .teams {
        .team_names {
          .team {
            &.left {
            }
            &.right {
            }
            .h_8 {
            }
            .team_last_matches {
              // gap: 5px;
              .b_4 {
              }
              .data {
                // gap: 8px;
                .match_info {
                  // width: 10px;
                  // height: 10px;
                  .modal {
                    // top: 12px;
                    // padding: 8px;
                  }
                  &.W {
                  }
                  &.L {
                  }
                  &.D {
                  }
                  &:hover {
                    .modal {
                    }
                  }
                }
              }
              &.left {
                .modal {
                  // border-radius: 0px 4px 4px 4px;
                  // left: 3px;
                }
              }
              &.right {
                .modal {
                  // right: 3px;
                  // border-radius: 4px 0px 4px 4px;
                }
              }
            }
          }
          span.vs {
            p,
            .h_8 {
            }
          }
        }
      }
      .statistics {
        //   gap: 10px;
        // padding: 8px;
        // border-width: 2px;
        // border-radius: 20px;
        //   margin-top: 25px;
        .b_2 {
        }
        .statistic {
          .b_3 {
            span {
            }
          }
        }
      }
    }
    .background_btn {
      // border-radius: 16px;
      // padding: 3px 36px;
      .btn-loader {
      }
      .b_3 {
      }
      &:disabled,
      &[disabled] {
        .b_3 {
        }
      }
    }
    .selected_and_delete {
      // gap: 10px;
      // width: 100%;
      .selected_and_point {
        // width: 100%;
        .prediction_selected {
          .h_8 {
            span {
            }
          }
        }
        .prediction_point {
          .h_8 {
            span {
            }
          }
        }
      }
      .delete {
        .background_btn {
          // padding: 6px 36px;
          p {
          }
        }
      }
    }
    &.added {
      .buttons {
      }
    }
    &.correct {
    }
    &.wrong {
    }
    &.canceled {
      .match_content {
        .h_8 {
        }
        .teams {
          .teams_last_matches {
            .team_last_matches {
              .data {
                .match_info:hover {
                  .modal {
                  }
                }
              }
            }
          }
        }
      }
      .selected_and_delete {
      }
    }
  }
}

@keyframes opacity {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
