#root {
  .leaderboard_page {
    margin-top: 75px;
    padding: 100px var(--main-gap);
    h5.h_5.hood {
      text-align: center;
      margin-bottom: 30px;
    }
    .info_leaderboard {
      display: flex;
      flex-direction: column;
      // align-items: stretch;
      // justify-content: space-between;
      // flex-wrap: wrap;
      width: 100%;
      gap: 40px;
      .top,
      .bottom {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        .total {
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          gap: 10px;
          flex: 1 1 calc((100% / 5) - 40px);
          border-radius: 16px;
          padding: 16px;
          @include box_shadow(map-get($color, "dark/alpha/25"));
          figure {
            height: 60px;
            // background-color: red;
            svg {
              // width: 100%;
              height: 100%;
            }
          }
          .content_total {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            p.b_1 {
              margin-bottom: 15px;
            }
            h6.h_7 {
            }
          }
          &.total_player {
            background-color: #dfffee;
            p,
            h6 {
              color: #0e4f2c;
            }
            svg {
              fill: #0e4f2c;
            }
          }
          &.average_point {
            background-color: #dfe5fe;
            p,
            h6 {
              color: #162978;
            }
            svg {
              fill: #162978;
            }
          }
          &.correct_package {
            background-color: #c3dff4;
            p,
            h6 {
              color: #124063;
            }
            svg {
              fill: #124063;
            }
          }
          &.correct_home {
            background-color: #e4c1c5;
            p,
            h6 {
              color: #63121b;
            }
            svg {
              fill: #63121b;
            }
          }
          &.correct_draw {
            background-color: #a7e4dd;
            p,
            h6 {
              color: #0e3a35;
            }
            svg {
              fill: #0e3a35;
            }
          }
          &.correct_away {
            background-color: #dcbed0;
            p,
            h6 {
              color: #631243;
            }
            svg {
              fill: #631243;
            }
          }
          &.correct_score {
            background-color: #ccc7ae;
            p,
            h6 {
              color: #403700;
            }
            svg {
              fill: #403700;
            }
          }
        }
        .remaining_time {
          @extend .total;
          background-color: #fff5cd;
          flex: 1 1 calc(((100% / 5) * 3) - 40px);
          justify-content: center;
          // max-width: calc((100% / 5) * 3);
          .remaining_time_content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 40px;
            // color: #4f4417;
            h6.h_6 {
              color: #4f4417;
              text-transform: uppercase;
            }
            p.h_6 {
              display: flex;
              align-items: stretch;
              gap: 8px;
              color: #4f4417;
              // color: #4f4417;
              .day,
              .hours {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: space-between;
                span {
                  &.h_8 {
                    color: #4f4417;
                    // color: map-get($color, "neutral/400");
                  }
                }
              }
            }
          }
          p.b_1 {
            border-top: 1px solid map-get($color, "dark/alpha/25");
            padding-top: 10px;
            // color: #4f4417;
          }
        }
      }
    }
    .leaderboard_sect {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-wrap: wrap;
      gap: 50px;
      margin-top: 50px;
      .h_7.hood {
        text-align: center;
        span {
          margin-right: 10px;
        }
      }
      .filter {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 30px;
        .user_leagues,
        .periods,
        .week {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
      .leaderboard {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: 30px;
        .user_queue {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          span.dot {
            width: 15px;
            height: 15px;
            background-color: map-get($color, "neutral/600");
            border-radius: 50%;
          }
        }
      }
      .outline_btn {
        // margin-top: 50px;
        width: max-content;
        border-radius: 18px;
        padding: 2px 10px;
        p {
          color: map-get($color, "primary/300");
        }
      }
    }
  }
  .leaderboard_card {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: map-get($color, "neutral/100");
    @include box_shadow(map-get($color, "dark/alpha/25"));
    border-radius: 16px;
    padding: 12px;
    // perspective: 1000px;
    cursor: pointer;
    position: relative;
    // overflow: hidden;
    .leaderboard_card_inner {
      width: 100%;
      height: 40px;
      position: relative;
      // transition: transform 0.6s;
      // transform-style: preserve-3d;
      // position: relative;
      .leaderboard_card_front {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
        z-index: 2;
        &.visible {
          opacity: 1;
          z-index: 2;
        }
        &.hidden {
          opacity: 0;
          z-index: 1;
        }
        .left {
          display: flex;
          align-items: center;
          gap: 15px;
          @include fill_available;
          .queue {
            display: flex;
            align-items: center;
            p.b_1 {
              white-space: pre;
              font-family: monospace;
            }
          }
          figure {
            width: 36px;
            height: 36px;
            border-radius: 50%;
            overflow: hidden;
            @include box_shadow(map-get($color, "dark/alpha/10"));
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          p.name {
            // flex: 1 1 (100% / 10);
            // width: 100%;
            width: 8vw;
          }
          p.percent {
            // flex: 1 1 (100% / 10);
            // flex: 1 1;
            width: max-content;
            color: map-get($color, "primary/300");
          }
        }
        .right {
          display: flex;
          align-items: center;
          gap: 15px;
          .point {
            color: map-get($color, "primary/300");
            span {
              margin-right: 5px;
            }
          }
          .report {
            position: relative;
            figure {
              display: flex;
              height: 20px;
              width: 20px;
              opacity: 1;
              transition: $transition;
              cursor: pointer;
              svg {
                width: 100%;
                height: 100%;
                fill: map-get($color, "dark/alpha/25");
              }
            }
            .content {
              position: absolute;
              background-color: map-get($color, "neutral/100");
              @include box_shadow(map-get($color, "dark/alpha/25"));
              top: 0;
              right: 0;
              width: max-content;
              display: flex;
              flex-direction: column;
              gap: 5px;
              padding: 15px 10px;
              border-radius: 5px;
              display: none;
              z-index: 1;
              .b_1 {
                color: map-get($color, "neutral/800");
                transition: $transition;
                &:hover {
                  color: map-get($color, "dark/100");
                }
              }
            }
            &:hover {
              .content {
                display: flex;
              }
            }
          }
        }
      }
      .leaderboard_card_back {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        gap: 20px;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        z-index: 1;
        &.visible {
          opacity: 1;
          z-index: 2;
        }
        &.hidden {
          opacity: 0;
          z-index: 1;
        }
        .percents {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          p {
            display: flex;
            align-items: center;
            gap: 5px;
            span {
              color: map-get($color, "primary/300");
            }
          }
        }
      }
    }
    &.my {
      @include box_shadow(map-get($color, "primary/300"));
    }
    &.flipped {
      .leaderboard_card_inner {
        // transform: rotateY(180deg);
      }
    }
    // &:hover {
    //   .report {
    //     figure {
    //       opacity: 1;
    //     }
    //   }
    // }
  }
  .leaderboard_king_card {
    @extend .leaderboard_card;
    width: calc((100% - (30px * 2)) / 3);
    align-items: flex-start;
    .leaderboard_card_inner {
      width: 100%;
      height: 80px;
      position: relative;
      // transition: transform 0.6s;
      // transform-style: preserve-3d;
      .leaderboard_card_front {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        // align-items: center;
        // justify-content: center;
        transition: opacity 0.5s ease-in-out;
        opacity: 1;
        z-index: 2;
        &.visible {
          opacity: 1;
          z-index: 2;
        }
        &.hidden {
          opacity: 0;
          z-index: 1;
        }
        .leaderboard_king_card_content {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;
          gap: 15px;
          .left {
            width: auto;
            figure.queue,
            figure.profile {
              width: 60px;
              height: 60px;
            }
            figure.queue {
              box-shadow: 0 0 0 0;
              img {
              }
            }
            figure.profile {
              // @include box_shadow(map-get($color, "dark/alpha/10"));
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .right {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            width: 100%;
            text-align: center;
            .txt {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 0;
              .h_8 {
                span {
                  margin-right: 5px;
                }
              }
            }
          }
        }
        .report {
          display: flex;
          justify-content: flex-end;
          // width: 100%;
          position: relative;
          figure {
            display: flex;
            height: 20px;
            opacity: 1;
            transition: $transition;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
              fill: map-get($color, "dark/alpha/25");
            }
          }
          .content {
            position: absolute;
            background-color: map-get($color, "neutral/100");
            @include box_shadow(map-get($color, "dark/alpha/25"));
            top: 0;
            right: 0;
            width: max-content;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 15px 10px;
            border-radius: 5px;
            display: none;
            .b_1 {
              color: map-get($color, "neutral/800");
              transition: $transition;
              &:hover {
                color: map-get($color, "dark/100");
              }
            }
          }
          &:hover {
            .content {
              display: flex;
            }
          }
        }
      }
      .leaderboard_card_back {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        transition: opacity 0.5s ease-in-out;
        opacity: 0;
        z-index: 1;
        &.visible {
          opacity: 1;
          z-index: 2;
        }
        &.hidden {
          opacity: 0;
          z-index: 1;
        }
        .percents {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          gap: 10px;
          p {
            display: flex;
            align-items: center;
            gap: 5px;
            span {
              color: map-get($color, "primary/300");
            }
          }
        }
      }
    }
    &.first {
      border: 2px solid map-get($color, "gold/200");
      @include box_shadow(map-get($color, "gold/200"));
      // border: 2px solid map-get($color, "red/200");
      p {
        color: map-get($color, "gold/200");
        // color: map-get($color, "red/200");
      }
    }
    &.second {
      border: 2px solid map-get($color, "silver/200");
      @include box_shadow(map-get($color, "silver/200"));
      p {
        color: map-get($color, "silver/200");
      }
    }
    &.thirth {
      border: 2px solid map-get($color, "bronze/200");
      @include box_shadow(map-get($color, "bronze/200"));
      p {
        color: map-get($color, "bronze/200");
      }
    }
    // &.flipped {
    //   .leaderboard_card_inner {
    //     transform: rotateY(180deg);
    //   }
    // }
    // &:hover {
    //   .report {
    //     figure {
    //       opacity: 1;
    //     }
    //   }
    // }
  }
  .no_leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    margin-bottom: 50px;
    .h_3 {
      color: map-get($color, "neutral/900");
      text-align: center;
      width: 75%;
    }
    .h_8 {
      color: map-get($color, "neutral/900");
    }
    figure {
      width: 28px;
      svg {
        width: 100%;
        height: 100%;
        fill: map-get($color, "neutral/900");
      }
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .leaderboard_page {
      // margin-top: 75px;
      h5.h_5.hood {
        // margin-bottom: 30px;
      }
      .info_leaderboard {
        // gap: 40px;
        .top,
        .bottom {
          // gap: 40px;
          .total {
            // gap: 10px;
            // flex: 1 1 calc((100% / 5) - 40px);
            // border-radius: 16px;
            // padding: 16px;
            figure {
              // height: 60px;
              svg {
              }
            }
            .content_total {
              p.b_1 {
                // margin-bottom: 15px;
              }
            }
          }
          .remaining_time {
            // flex: 1 1 calc(((100% / 5) * 3) - 40px);
            .remaining_time_content {
              // gap: 40px;
              h6.h_6 {
              }
              p.h_6 {
                // gap: 8px;
                .day,
                .hours {
                  span {
                    &.h_8 {
                    }
                  }
                }
              }
            }
            p.b_1 {
              // border-top: 1px solid map-get($color, "dark/alpha/25");
              // padding-top: 10px;
            }
          }
        }
      }
      .leaderboard_sect {
        // gap: 50px;
        // margin-top: 50px;
        .h_7.hood {
          span {
            // margin-right: 10px;
          }
        }
        .filter {
          // gap: 30px;
          .user_leagues,
          .periods,
          .week {
            // gap: 5px;
          }
        }
        .leaderboard {
          // gap: 30px;
          .user_queue {
            // gap: 15px;
            span.dot {
              // width: 15px;
              // height: 15px;
            }
          }
        }
        .outline_btn {
          // border-radius: 18px;
          // padding: 2px 10px;
          p {
          }
        }
      }
    }
    .leaderboard_card {
      // border-radius: 16px;
      // padding: 12px;
      .leaderboard_card_inner {
        // height: 40px;
        .leaderboard_card_front {
          .left {
            // gap: 15px;
            .queue {
              p.b_1 {
              }
            }
            figure {
              // width: 36px;
              // height: 36px;
              img {
              }
            }
            p.name {
              // width: 8vw;
            }
            p.percent {
            }
          }
          .right {
            // gap: 15px;
            .point {
              span {
                // margin-right: 5px;
              }
            }
            .report {
              figure {
                // height: 20px;
                // width: 20px;
                svg {
                }
              }
              .content {
                // gap: 5px;
                // padding: 15px 10px;
                // border-radius: 5px;
                .b_1 {
                  &:hover {
                  }
                }
              }
              &:hover {
                .content {
                }
              }
            }
          }
        }
        .leaderboard_card_back {
          // gap: 20px;
          &.visible {
          }
          &.hidden {
          }
          .percents {
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.my {
      }
      &.flipped {
        .leaderboard_card_inner {
        }
      }
    }
    .leaderboard_king_card {
      // width: calc((100% - (30px * 2)) / 3);
      .leaderboard_card_inner {
        // height: 80px;
        .leaderboard_card_front {
          &.visible {
          }
          &.hidden {
          }
          .leaderboard_king_card_content {
            // gap: 15px;
            .left {
              figure.queue,
              figure.profile {
                // width: 60px;
                // height: 60px;
              }
              figure.queue {
                img {
                }
              }
              figure.profile {
                img {
                }
              }
            }
            .right {
              .txt {
                .h_8 {
                  span {
                    // margin-right: 5px;
                  }
                }
              }
            }
          }
          .report {
            figure {
              // height: 20px;
              svg {
              }
            }
            .content {
              // gap: 5px;
              // padding: 15px 10px;
              // border-radius: 5px;
              .b_1 {
                &:hover {
                }
              }
            }
            &:hover {
              .content {
              }
            }
          }
        }
        .leaderboard_card_back {
          &.visible {
          }
          &.hidden {
          }
          .percents {
            // gap: 10px;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.first {
        // border: 2px solid map-get($color, "gold/200");
        p {
        }
      }
      &.second {
        // border: 2px solid map-get($color, "silver/200");
        p {
        }
      }
      &.thirth {
        // border: 2px solid map-get($color, "bronze/200");
        p {
        }
      }
    }
    .no_leaderboard {
      // gap: 50px;
      // margin-bottom: 50px;
      .h_3 {
        // width: 75%;
      }
      .h_8 {
      }
      figure {
        // width: 28px;
        svg {
        }
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .leaderboard_page {
      // margin-top: 75px;
      h5.h_5.hood {
        // margin-bottom: 30px;
      }
      .info_leaderboard {
        // gap: 40px;
        .top,
        .bottom {
          // gap: 40px;
          .total {
            // gap: 10px;
            // flex: 1 1 calc((100% / 5) - 40px);
            // border-radius: 16px;
            // padding: 16px;
            figure {
              height: 50px;
              svg {
              }
            }
            .content_total {
              p.b_1 {
                // margin-bottom: 15px;
              }
            }
          }
          .remaining_time {
            // flex: 1 1 calc(((100% / 5) * 3) - 40px);
            .remaining_time_content {
              // gap: 40px;
              h6.h_6 {
              }
              p.h_6 {
                // gap: 8px;
                .day,
                .hours {
                  span {
                    &.h_8 {
                    }
                  }
                }
              }
            }
            p.b_1 {
              // border-top: 1px solid map-get($color, "dark/alpha/25");
              // padding-top: 10px;
            }
          }
        }
      }
      .leaderboard_sect {
        // gap: 50px;
        // margin-top: 50px;
        .h_7.hood {
          span {
            // margin-right: 10px;
          }
        }
        .filter {
          // gap: 30px;
          .user_leagues,
          .periods,
          .week {
            // gap: 5px;
          }
        }
        .leaderboard {
          // gap: 30px;
          .user_queue {
            // gap: 15px;
            span.dot {
              // width: 15px;
              // height: 15px;
            }
          }
        }
        .outline_btn {
          // border-radius: 18px;
          // padding: 2px 10px;
          p {
          }
        }
      }
    }
    .leaderboard_card {
      // border-radius: 16px;
      // padding: 12px;
      .leaderboard_card_inner {
        // height: 40px;
        .leaderboard_card_front {
          .left {
            // gap: 15px;
            .queue {
              p.b_1 {
              }
            }
            figure {
              // width: 36px;
              // height: 36px;
              img {
              }
            }
            p.name {
              // width: 8vw;
            }
            p.percent {
            }
          }
          .right {
            // gap: 15px;
            .point {
              span {
                // margin-right: 5px;
              }
            }
            .report {
              figure {
                // height: 20px;
                // width: 20px;
                svg {
                }
              }
              .content {
                // gap: 5px;
                // padding: 15px 10px;
                // border-radius: 5px;
                .b_1 {
                  &:hover {
                  }
                }
              }
              &:hover {
                .content {
                }
              }
            }
          }
        }
        .leaderboard_card_back {
          // gap: 20px;
          &.visible {
          }
          &.hidden {
          }
          .percents {
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.my {
      }
      &.flipped {
        .leaderboard_card_inner {
        }
      }
    }
    .leaderboard_king_card {
      // width: calc((100% - (30px * 2)) / 3);
      .leaderboard_card_inner {
        // height: 80px;
        .leaderboard_card_front {
          &.visible {
          }
          &.hidden {
          }
          .leaderboard_king_card_content {
            // gap: 15px;
            .left {
              figure.queue,
              figure.profile {
                // width: 60px;
                // height: 60px;
              }
              figure.queue {
                img {
                }
              }
              figure.profile {
                img {
                }
              }
            }
            .right {
              .txt {
                .h_8 {
                  span {
                    // margin-right: 5px;
                  }
                }
              }
            }
          }
          .report {
            figure {
              // height: 20px;
              svg {
              }
            }
            .content {
              // gap: 5px;
              // padding: 15px 10px;
              // border-radius: 5px;
              .b_1 {
                &:hover {
                }
              }
            }
            &:hover {
              .content {
              }
            }
          }
        }
        .leaderboard_card_back {
          &.visible {
          }
          &.hidden {
          }
          .percents {
            // gap: 10px;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.first {
        // border: 2px solid map-get($color, "gold/200");
        p {
        }
      }
      &.second {
        // border: 2px solid map-get($color, "silver/200");
        p {
        }
      }
      &.thirth {
        // border: 2px solid map-get($color, "bronze/200");
        p {
        }
      }
    }
    .no_leaderboard {
      // gap: 50px;
      // margin-bottom: 50px;
      .h_3 {
        // width: 75%;
      }
      .h_8 {
      }
      figure {
        // width: 28px;
        svg {
        }
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .leaderboard_page {
      // margin-top: 75px;
      h5.h_5.hood {
        // margin-bottom: 30px;
      }
      .info_leaderboard {
        // gap: 40px;
        .top,
        .bottom {
          gap: 20px;
          .total {
            // gap: 10px;
            flex: 1 1 calc((100% / 5) - 20px);
            // border-radius: 16px;
            // padding: 16px;
            figure {
              height: 45px;
              svg {
              }
            }
            .content_total {
              p.b_1 {
                // margin-bottom: 15px;
              }
            }
          }
          .remaining_time {
            flex: 1 1 calc(((100% / 5) * 3) - 20px);
            .remaining_time_content {
              // gap: 40px;
              h6.h_6 {
              }
              p.h_6 {
                // gap: 8px;
                .day,
                .hours {
                  span {
                    &.h_8 {
                    }
                  }
                }
              }
            }
            p.b_1 {
              // border-top: 1px solid map-get($color, "dark/alpha/25");
              // padding-top: 10px;
            }
          }
        }
      }
      .leaderboard_sect {
        // gap: 50px;
        // margin-top: 50px;
        .h_7.hood {
          span {
            // margin-right: 10px;
          }
        }
        .filter {
          // gap: 30px;
          .user_leagues,
          .periods,
          .week {
            // gap: 5px;
          }
        }
        .leaderboard {
          // gap: 30px;
          .user_queue {
            // gap: 15px;
            span.dot {
              // width: 15px;
              // height: 15px;
            }
          }
        }
        .outline_btn {
          // border-radius: 18px;
          // padding: 2px 10px;
          p {
          }
        }
      }
    }
    .leaderboard_card {
      // border-radius: 16px;
      // padding: 12px;
      .leaderboard_card_inner {
        // height: 40px;
        .leaderboard_card_front {
          .left {
            // gap: 15px;
            .queue {
              p.b_1 {
              }
            }
            figure {
              // width: 36px;
              // height: 36px;
              img {
              }
            }
            p.name {
              // width: 8vw;
            }
            p.percent {
            }
          }
          .right {
            // gap: 15px;
            .point {
              span {
                // margin-right: 5px;
              }
            }
            .report {
              figure {
                // height: 20px;
                // width: 20px;
                svg {
                }
              }
              .content {
                // gap: 5px;
                padding: 10px;
                // border-radius: 5px;
                .b_1 {
                  &:hover {
                  }
                }
              }
              &:hover {
                .content {
                }
              }
            }
          }
        }
        .leaderboard_card_back {
          // gap: 20px;
          &.visible {
          }
          &.hidden {
          }
          .percents {
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.my {
      }
      &.flipped {
        .leaderboard_card_inner {
        }
      }
    }
    .leaderboard_king_card {
      width: calc(100%);
      .leaderboard_card_inner {
        // height: 80px;
        .leaderboard_card_front {
          &.visible {
          }
          &.hidden {
          }
          .leaderboard_king_card_content {
            // gap: 15px;
            .left {
              figure.queue,
              figure.profile {
                // width: 60px;
                // height: 60px;
              }
              figure.queue {
                img {
                }
              }
              figure.profile {
                img {
                }
              }
            }
            .right {
              .txt {
                .h_8 {
                  span {
                    // margin-right: 5px;
                  }
                }
              }
            }
          }
          .report {
            figure {
              // height: 20px;
              svg {
              }
            }
            .content {
              // gap: 5px;
              padding: 10px;
              // border-radius: 5px;
              .b_1 {
                &:hover {
                }
              }
            }
            &:hover {
              .content {
              }
            }
          }
        }
        .leaderboard_card_back {
          &.visible {
          }
          &.hidden {
          }
          .percents {
            // gap: 10px;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.first {
        // border: 2px solid map-get($color, "gold/200");
        p {
        }
      }
      &.second {
        // border: 2px solid map-get($color, "silver/200");
        p {
        }
      }
      &.thirth {
        // border: 2px solid map-get($color, "bronze/200");
        p {
        }
      }
    }
    .no_leaderboard {
      // gap: 50px;
      // margin-bottom: 50px;
      .h_3 {
        // width: 75%;
      }
      .h_8 {
      }
      figure {
        // width: 28px;
        svg {
        }
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768 // Tablet
  #root {
    .leaderboard_page {
      // margin-top: 75px;
      h5.h_5.hood {
        // margin-bottom: 30px;
      }
      .info_leaderboard {
        // gap: 40px;
        .top,
        .bottom {
          // gap: 20px;
          .total {
            // gap: 10px;
            flex: 1 1 calc((100% / 3) - 20px);
            // border-radius: 16px;
            // padding: 16px;
            figure {
              // height: 45px;
              svg {
              }
            }
            .content_total {
              p.b_1 {
                // margin-bottom: 15px;
              }
            }
          }
          .remaining_time {
            // flex: 1 1 calc(((100% / 5) * 3) - 20px);
            .remaining_time_content {
              // gap: 40px;
              h6.h_6 {
              }
              p.h_6 {
                // gap: 8px;
                .day,
                .hours {
                  span {
                    &.h_8 {
                    }
                  }
                }
              }
            }
            p.b_1 {
              // border-top: 1px solid map-get($color, "dark/alpha/25");
              // padding-top: 10px;
            }
          }
        }
      }
      .leaderboard_sect {
        // gap: 50px;
        // margin-top: 50px;
        .h_7.hood {
          span {
            // margin-right: 10px;
          }
        }
        .filter {
          // gap: 30px;
          .user_leagues,
          .periods,
          .week {
            // gap: 5px;
          }
        }
        .leaderboard {
          // gap: 30px;
          .user_queue {
            // gap: 15px;
            span.dot {
              // width: 15px;
              // height: 15px;
            }
          }
        }
        .outline_btn {
          // border-radius: 18px;
          // padding: 2px 10px;
          p {
          }
        }
      }
    }
    .leaderboard_card {
      border-radius: 12px;
      padding: 8px;
      .leaderboard_card_inner {
        // height: 40px;
        .leaderboard_card_front {
          .left {
            // gap: 15px;
            .queue {
              p.b_1 {
              }
            }
            figure {
              // width: 36px;
              // height: 36px;
              img {
              }
            }
            p.name {
              // width: 8vw;
            }
            p.percent {
            }
          }
          .right {
            // gap: 15px;
            .point {
              span {
                // margin-right: 5px;
              }
            }
            .report {
              figure {
                // height: 20px;
                // width: 20px;
                svg {
                }
              }
              .content {
                // gap: 5px;
                // padding: 10px;
                // border-radius: 5px;
                .b_1 {
                  &:hover {
                  }
                }
              }
              &:hover {
                .content {
                }
              }
            }
          }
        }
        .leaderboard_card_back {
          // gap: 20px;
          &.visible {
          }
          &.hidden {
          }
          .percents {
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.my {
      }
      &.flipped {
        .leaderboard_card_inner {
        }
      }
    }
    .leaderboard_king_card {
      // width: calc(100%);
      .leaderboard_card_inner {
        // height: 80px;
        .leaderboard_card_front {
          &.visible {
          }
          &.hidden {
          }
          .leaderboard_king_card_content {
            // gap: 15px;
            .left {
              figure.queue,
              figure.profile {
                // width: 60px;
                // height: 60px;
              }
              figure.queue {
                img {
                }
              }
              figure.profile {
                img {
                }
              }
            }
            .right {
              .txt {
                .h_8 {
                  span {
                    // margin-right: 5px;
                  }
                }
              }
            }
          }
          .report {
            figure {
              // height: 20px;
              svg {
              }
            }
            .content {
              // gap: 5px;
              // padding: 10px;
              // border-radius: 5px;
              .b_1 {
                &:hover {
                }
              }
            }
            &:hover {
              .content {
              }
            }
          }
        }
        .leaderboard_card_back {
          &.visible {
          }
          &.hidden {
          }
          .percents {
            // gap: 10px;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.first {
        // border: 2px solid map-get($color, "gold/200");
        p {
        }
      }
      &.second {
        // border: 2px solid map-get($color, "silver/200");
        p {
        }
      }
      &.thirth {
        // border: 2px solid map-get($color, "bronze/200");
        p {
        }
      }
    }
    .no_leaderboard {
      // gap: 50px;
      // margin-bottom: 50px;
      .h_3 {
        // width: 75%;
      }
      .h_8 {
      }
      figure {
        // width: 28px;
        svg {
        }
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    .leaderboard_page {
      // margin-top: 75px;
      h5.h_5.hood {
        // margin-bottom: 30px;
      }
      .info_leaderboard {
        // gap: 40px;
        .top,
        .bottom {
          // gap: 20px;
          .total {
            // gap: 10px;
            // flex: 1 1 calc((100% / 5) - 20px);
            border-radius: 12px;
            // padding: 16px;
            figure {
              height: 40px;
              svg {
              }
            }
            .content_total {
              p.b_1 {
                // margin-bottom: 15px;
              }
            }
          }
          .remaining_time {
            // flex: 1 1 calc(((100% / 5) * 3) - 20px);
            .remaining_time_content {
              // gap: 40px;
              h6.h_6 {
              }
              p.h_6 {
                // gap: 8px;
                .day,
                .hours {
                  span {
                    &.h_8 {
                    }
                  }
                }
              }
            }
            p.b_1 {
              // border-top: 1px solid map-get($color, "dark/alpha/25");
              // padding-top: 10px;
            }
          }
        }
      }
      .leaderboard_sect {
        // gap: 50px;
        // margin-top: 50px;
        .h_7.hood {
          span {
            // margin-right: 10px;
          }
        }
        .filter {
          gap: 15px;
          .user_leagues,
          .periods,
          .week {
            width: 100%;
            // gap: 5px;
          }
        }
        .leaderboard {
          // gap: 30px;
          .user_queue {
            // gap: 15px;
            span.dot {
              // width: 15px;
              // height: 15px;
            }
          }
        }
        .outline_btn {
          // border-radius: 18px;
          // padding: 2px 10px;
          p {
          }
        }
      }
    }
    .leaderboard_card {
      // border-radius: 12px;
      // padding: 8px;
      .leaderboard_card_inner {
        // height: 40px;
        .leaderboard_card_front {
          .left {
            // gap: 15px;
            .queue {
              p.b_1 {
              }
            }
            figure {
              // width: 36px;
              // height: 36px;
              img {
              }
            }
            p.name {
              width: 20vw;
            }
            p.percent {
            }
          }
          .right {
            // gap: 15px;
            .point {
              span {
                // margin-right: 5px;
              }
            }
            .report {
              figure {
                // height: 20px;
                // width: 20px;
                svg {
                }
              }
              .content {
                // gap: 5px;
                // padding: 10px;
                // border-radius: 5px;
                .b_1 {
                  &:hover {
                  }
                }
              }
              &:hover {
                .content {
                }
              }
            }
          }
        }
        .leaderboard_card_back {
          // gap: 20px;
          &.visible {
          }
          &.hidden {
          }
          .percents {
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.my {
      }
      &.flipped {
        .leaderboard_card_inner {
        }
      }
    }
    .leaderboard_king_card {
      // width: calc(100%);
      .leaderboard_card_inner {
        // height: 80px;
        .leaderboard_card_front {
          &.visible {
          }
          &.hidden {
          }
          .leaderboard_king_card_content {
            // gap: 15px;
            .left {
              figure.queue,
              figure.profile {
                // width: 60px;
                // height: 60px;
              }
              figure.queue {
                img {
                }
              }
              figure.profile {
                img {
                }
              }
            }
            .right {
              .txt {
                .h_8 {
                  span {
                    // margin-right: 5px;
                  }
                }
              }
            }
          }
          .report {
            figure {
              // height: 20px;
              svg {
              }
            }
            .content {
              // gap: 5px;
              // padding: 10px;
              // border-radius: 5px;
              .b_1 {
                &:hover {
                }
              }
            }
            &:hover {
              .content {
              }
            }
          }
        }
        .leaderboard_card_back {
          &.visible {
          }
          &.hidden {
          }
          .percents {
            // gap: 10px;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.first {
        // border: 2px solid map-get($color, "gold/200");
        p {
        }
      }
      &.second {
        // border: 2px solid map-get($color, "silver/200");
        p {
        }
      }
      &.thirth {
        // border: 2px solid map-get($color, "bronze/200");
        p {
        }
      }
    }
    .no_leaderboard {
      // gap: 50px;
      // margin-bottom: 50px;
      .h_3 {
        // width: 75%;
      }
      .h_8 {
      }
      figure {
        // width: 28px;
        svg {
        }
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  #root {
    .leaderboard_page {
      margin: 0;
      padding: 50px var(--main-gap);
      h5.h_5.hood {
        // margin-bottom: 30px;
      }
      .info_leaderboard {
        gap: 10px;
        .top,
        .bottom {
          gap: 10px;
          .total {
            // gap: 10px;
            // flex: 1 1 calc((100% / 5) - 20px);
            // border-radius: 12px;
            // padding: 16px;
            figure {
              // height: 40px;
              svg {
              }
            }
            .content_total {
              p.b_1 {
                // margin-bottom: 15px;
              }
            }
          }
          .remaining_time {
            // flex: 1 1 calc(((100% / 5) * 3) - 20px);
            .remaining_time_content {
              // gap: 40px;
              gap: 10px;
              flex-direction: column;
              h6.h_6 {
                text-align: center;
              }
              p.h_6 {
                // gap: 8px;
                .day,
                .hours {
                  span {
                    &.h_8 {
                    }
                  }
                }
              }
            }
            p.b_1 {
              // border-top: 1px solid map-get($color, "dark/alpha/25");
              // padding-top: 10px;
            }
          }
        }
      }
      .leaderboard_sect {
        // gap: 50px;
        // margin-top: 50px;
        .h_7.hood {
          span {
            // margin-right: 10px;
          }
        }
        .filter {
          // gap: 15px;
          .user_leagues,
          .periods,
          .week {
            // width: 100%;
            // gap: 5px;
          }
        }
        .leaderboard {
          // gap: 30px;
          .user_queue {
            // gap: 15px;
            span.dot {
              // width: 15px;
              // height: 15px;
            }
          }
        }
        .outline_btn {
          // border-radius: 18px;
          // padding: 2px 10px;
          p {
          }
        }
      }
    }
    .leaderboard_card {
      // border-radius: 12px;
      // padding: 8px;
      .leaderboard_card_inner {
        // height: 40px;
        .leaderboard_card_front {
          .left {
            gap: 5px;
            .queue {
              p.b_1 {
              }
            }
            figure {
              // width: 36px;
              // height: 36px;
              img {
              }
            }
            p.name {
              width: 30vw;
            }
            p.percent {
            }
          }
          .right {
            gap: 5px;
            .point {
              span {
                // margin-right: 5px;
              }
            }
            .report {
              figure {
                height: 15px;
                width: 15px;
                svg {
                }
              }
              .content {
                // gap: 5px;
                // padding: 10px;
                // border-radius: 5px;
                .b_1 {
                  &:hover {
                  }
                }
              }
              &:hover {
                .content {
                }
              }
            }
          }
        }
        .leaderboard_card_back {
          // gap: 20px;
          &.visible {
          }
          &.hidden {
          }
          .percents {
            flex-direction: column;
            gap: 0;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.my {
      }
      &.flipped {
        .leaderboard_card_inner {
        }
      }
    }
    .leaderboard_king_card {
      // width: calc(100%);
      .leaderboard_card_inner {
        // height: 80px;
        .leaderboard_card_front {
          &.visible {
          }
          &.hidden {
          }
          .leaderboard_king_card_content {
            gap: 10px;
            .left {
              figure.queue,
              figure.profile {
                width: 50px;
                height: 50px;
              }
              figure.queue {
                img {
                }
              }
              figure.profile {
                img {
                }
              }
            }
            .right {
              .txt {
                .h_8 {
                  span {
                    // margin-right: 5px;
                  }
                }
              }
            }
          }
          .report {
            figure {
              // height: 20px;
              svg {
              }
            }
            .content {
              // gap: 5px;
              // padding: 10px;
              // border-radius: 5px;
              .b_1 {
                &:hover {
                }
              }
            }
            &:hover {
              .content {
              }
            }
          }
        }
        .leaderboard_card_back {
          &.visible {
          }
          &.hidden {
          }
          .percents {
            // gap: 10px;
            p {
              // gap: 5px;
              span {
              }
            }
          }
        }
      }
      &.first {
        // border: 2px solid map-get($color, "gold/200");
        p {
        }
      }
      &.second {
        // border: 2px solid map-get($color, "silver/200");
        p {
        }
      }
      &.thirth {
        // border: 2px solid map-get($color, "bronze/200");
        p {
        }
      }
    }
    .no_leaderboard {
      // gap: 50px;
      // margin-bottom: 50px;
      .h_3 {
        // width: 75%;
      }
      .h_8 {
      }
      figure {
        // width: 28px;
        svg {
        }
      }
    }
  }
}
