#root {
  .cond-use {
    margin-top: 75px;
    padding: 30px var(--main-gap) 60px var(--main-gap);
    min-height: 100vh;
    .h_2 {
      text-align: center;
      margin: 0 0 30px 0;
    }
    .conditions {
      display: flex;
      flex-wrap: wrap;
      gap: 35px;
      .condition {
        flex: 1 1 100%;
        padding: 56px;
        @include box_shadow(map-get($color, "dark/alpha/25"));
        border-radius: 16px;
        background-color: map-get($color, "neutral/100");
        transition: $transition all;
        .h_8 {
        }
        .desc {
          margin-top: 50px;
        }
        .b_1 {
          span {
          }
        }
        &.half {
          flex: 1 1 calc(50% - (35px / 2));
          padding: 28px 56px;
        }
        &:hover {
          @include box_shadow(map-get($color, "primary/alpha/50"));
        }
      }
    }
  }
  .privacy-policy {
    margin-top: 75px;
    padding: 30px var(--main-gap) 60px var(--main-gap);
    min-height: 100vh;
    .h_2 {
      text-align: center;
      margin: 0 0 30px 0;
    }
    .policies {
      display: flex;
      flex-wrap: wrap;
      gap: 35px;
      .policy {
        flex: 1 1 100%;
        padding: 56px;
        @include box_shadow(map-get($color, "dark/alpha/25"));
        border-radius: 16px;
        transition: $transition all;
        background-color: map-get($color, "neutral/100");
        .h_8 {
        }
        .desc {
          // margin-top: 50px;
        }
        .b_1 {
          span {
          }
        }
        &.half {
          flex: 1 1 calc(50% - (35px / 2));
          padding: 28px 56px;
        }
        &:hover {
          @include box_shadow(map-get($color, "primary/alpha/50"));
        }
      }
    }
  }
  .faqs {
    margin-top: 75px;
    padding: 30px var(--main-gap);
    min-height: 100vh;
    .main-hood {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      .h_2 {
      }
      .h_6 {
        color: map-get($color, "primary/800");
      }
    }
    .questions-category {
      .h_7 {
        margin: 30px 0 25px 0;
      }
      .questions {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .question {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 40px;
          border-radius: 14px;
          @include box_shadow(map-get($color, "dark/alpha/25"));
          background-color: map-get($color, "neutral/100");
          cursor: pointer;
          transition: $transition all;
          .content {
            flex: 1 1 100%;
            display: inline;
            flex-direction: column;
            align-items: flex-start;
            gap: 20px;
            transition: $transition all;
            .h_8 {
            }
            .hood {
              color: map-get($color, "primary/800");
            }
            .open-txt {
              max-height: 0;
              overflow: hidden;
              transition: $transition all;
            }
          }
          .arrow {
            flex: 1 1 calc(100% / 5);
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            figure {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 17px;
              border-radius: 50%;
              height: fit-content;
              @include box_shadow(map-get($color, "dark/alpha/25"));
              transform: rotate(-90deg);
              transition: $transition all;
              svg {
                width: 16px;
                fill: map-get($color, "primary/300");
                transition: $transition all;
              }
            }
          }
          &.open {
            align-items: flex-start;
            @include box_shadow(map-get($color, "primary/alpha/50"));
            .content {
              display: flex;
              .open-txt {
                max-height: 1440px;
              }
            }
            .arrow {
              figure {
                background-color: map-get($color, "primary/300");
                box-shadow: none;
                transform: rotate(0deg);
                svg {
                  fill: map-get($color, "neutral/100");
                }
              }
            }
          }
        }
      }
    }
  }
}
