#root {
  .register-login-page {
    .content.login {
      .form {
        .phone-mail-username {
          .phone {
            position: relative;
            // .phone-input {
            input {
              width: 100%;
            }
            // }
          }
          // .phone {
          //   position: relative;
          //   // input {
          //   //   width: 100%;
          //   // }
          // }
          .mail {
            position: relative;
          }
          .username {
            position: relative;
          }
          .input {
            width: 100%;
          }
        }
        .password {
          display: flex;
          flex-direction: column;
          gap: 4px;
          margin: 32px 0;
          position: relative;
        }
        .remember-forget {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .b_2 {
            color: map-get($color, "primary/300");
          }
          .remember-me {
            .custom-checkbox {
              padding-left: 0;
              padding-right: 20px;
              .checkmark {
                right: 0;
                left: auto;
              }
            }
          }
        }
        // .background_btn {
        //   width: 100%;
        //   margin: 24px 0 24px 0;
        // }
        // .no-account {
        //   color: map-get($color, "primary/300");
        //   text-align: center;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        // }
      }
      .forgot-password {
        .h_4 {
        }
        .b_1.desc {
          margin-top: 12px;
        }
        form {
          .phone-mail {
            margin: 130px 0 190px 0;
            .links {
              display: flex;
              gap: 24px;
              align-items: center;
              margin-bottom: 15px;
              .b_3 {
                width: 100%;
                border: 3px solid map-get($color, "primary/300");
                border-radius: 12px;
                color: map-get($color, "primary/300");
                padding: 12px;
                background-color: map-get($color, "neutral/100");
                text-align: center;
                &.active {
                  background-color: map-get($color, "primary/300");
                  color: map-get($color, "neutral/100");
                }
              }
            }
            .phone {
              position: relative;
              input {
                width: 100%;
              }
            }
            .mail {
              position: relative;
            }
            .input {
              width: 100%;
            }
            .terms {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;
              position: absolute;
              top: calc(100% + 5px);
              left: 0;
              height: 0;
              overflow: hidden;
              transition: 300ms all;
              .term {
                display: flex;
                align-items: center;
                gap: 5px;
                figure {
                  width: 16px;
                  height: 16px;
                  svg {
                    width: 100%;
                    height: 100%;
                    &.xmark {
                      fill: map-get($color, "red/200");
                    }
                    &.correct {
                      fill: map-get($color, "green/300");
                    }
                  }
                }
                .b_4 {
                  &.wrong {
                    color: map-get($color, "red/200");
                  }
                  &.correct {
                    color: map-get($color, "green/300");
                  }
                }
              }
              &.active {
                height: max-content;
              }
            }
          }
          .background_btn {
            width: 100%;
          }
        }
        .verify-code-page {
          margin-top: 50px;
          .wrong-txt {
            display: none;
            .h_8 {
              color: map-get($color, "red/200");
              text-align: center;
              margin: 20px;
            }
          }
          .content-verify {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            margin-top: 25px;
            margin-bottom: 75px;
            .content-verify-inner {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 15px;
              .b_1 {
                width: 95%;
                text-align: center;
              }
              .links {
                display: flex;
                flex-direction: column;
                gap: 4px;
                .b_2 {
                  color: map-get($color, "primary/300");
                  text-align: center;
                  background: none;
                  &:disabled,
                  &[disabled] {
                    cursor: not-allowed;
                    opacity: 0.5;
                  }
                }
              }
            }
            .counter {
              text-align: center;
              color: map-get($color, "red/200");
            }
          }
          .background_btn {
            width: 100%;
          }
          &.wrong {
            .verify-code-inputs {
              .vi__container {
                .vi__character {
                  @include box_shadow(map-get($color, "red/alpha/50"));
                  &:where(.vi__character--selected) {
                    color: map-get($color, "red/200");
                  }
                }
              }
            }
            .wrong-txt {
              display: block;
            }
          }
        }
        .set-password {
          p.b_1 {
            margin-top: 12px;
          }
          .passwords {
            display: flex;
            flex-direction: column;
            gap: 75px;
            margin: 95px 0 190px 0;
            .input {
            }
            .password {
            }
            .password,
            .password-again {
              display: flex;
              flex-direction: column;
              gap: 4px;
              position: relative;
              .b_4 {
                color: map-get($color, "red/200");
              }
              .b_2 {
              }
              .input {
              }
            }
            .terms {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;
              position: absolute;
              top: calc(100% + 5px);
              left: 0;
              height: 0;
              overflow: hidden;
              transition: 300ms all;
              .term {
                display: flex;
                align-items: center;
                gap: 5px;
                figure {
                  width: 16px;
                  height: 16px;
                  svg {
                    width: 100%;
                    height: 100%;
                    &.xmark {
                      fill: map-get($color, "red/200");
                    }
                    &.correct {
                      fill: map-get($color, "green/300");
                    }
                  }
                }
                .b_4 {
                  &.wrong {
                    color: map-get($color, "red/200");
                  }
                  &.correct {
                    color: map-get($color, "green/300");
                  }
                }
              }
              &.active {
                height: max-content;
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .register-login-page {
      .content.login {
        .form {
          .phone-mail-username {
            .phone {
              input {
                // width: 100%;
              }
            }
            .mail {
            }
            .username {
            }
            .input {
              // width: 100%;
            }
          }
          .password {
            // gap: 4px;
            // margin: 32px 0;
          }
          .remember-forget {
            .b_2 {
            }
            .remember-me {
              .custom-checkbox {
                // padding-left: 0;
                // padding-right: 20px;
                .checkmark {
                  // right: 0;
                  // left: auto;
                }
              }
            }
          }
        }
        .forgot-password {
          .h_4 {
          }
          .b_1.desc {
            // margin-top: 12px;
          }
          form {
            .phone-mail {
              // margin: 130px 0 190px 0;
              .links {
                // gap: 24px;
                // margin-bottom: 15px;
                .b_3 {
                  // width: 100%;
                  // border: 3px solid map-get($color, "primary/300");
                  // border-radius: 12px;
                  // padding: 12px;
                  &.active {
                  }
                }
              }
              .phone {
                input {
                  // width: 100%;
                }
              }
              .mail {
              }
              .input {
                // width: 100%;
              }
              .terms {
                // gap: 5px;
                // top: calc(100% + 5px);
                // left: 0;
                // height: 0;
                .term {
                  // gap: 5px;
                  figure {
                    // width: 16px;
                    // height: 16px;
                    svg {
                      // width: 100%;
                      // height: 100%;
                      &.xmark {
                      }
                      &.correct {
                      }
                    }
                  }
                  .b_4 {
                    &.wrong {
                    }
                    &.correct {
                    }
                  }
                }
                &.active {
                  // height: max-content;
                }
              }
            }
            .background_btn {
              // width: 100%;
            }
          }
          .verify-code-page {
            // margin-top: 50px;
            .wrong-txt {
              .h_8 {
                // margin: 20px;
              }
            }
            .content-verify {
              // gap: 4px;
              // margin-top: 25px;
              // margin-bottom: 75px;
              .content-verify-inner {
                // gap: 15px;
                .b_1 {
                  // width: 95%;
                }
                .links {
                  // gap: 4px;
                  .b_2 {
                    &:disabled,
                    &[disabled] {
                    }
                  }
                }
              }
              .counter {
              }
            }
            .background_btn {
              // width: 100%;
            }
            &.wrong {
              .verify-code-inputs {
                .vi__container {
                  .vi__character {
                    &:where(.vi__character--selected) {
                    }
                  }
                }
              }
              .wrong-txt {
              }
            }
          }
          .set-password {
            p.b_1 {
              // margin-top: 12px;
            }
            .passwords {
              // gap: 75px;
              // margin: 95px 0 190px 0;
              .input {
              }
              .password {
              }
              .password,
              .password-again {
                // gap: 4px;
                .b_4 {
                }
                .b_2 {
                }
                .input {
                }
              }
              .terms {
                // gap: 5px;
                // top: calc(100% + 5px);
                // left: 0;
                // height: 0;
                .term {
                  // gap: 5px;
                  figure {
                    // width: 16px;
                    // height: 16px;
                    svg {
                      // width: 100%;
                      // height: 100%;
                      &.xmark {
                      }
                      &.correct {
                      }
                    }
                  }
                  .b_4 {
                    &.wrong {
                    }
                    &.correct {
                    }
                  }
                }
                &.active {
                  // height: max-content;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
}

@include mediaQuery(1280px) {
  // 1024
}

@include mediaQuery(1024px) {
  // 768
}

@include mediaQuery(768px) {
  // 480
}

@include mediaQuery(480px) {
  // 300
}
