#root {
  nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px var(--main-gap);
    border-radius: 0 0 16px 16px;
    @include box_shadow(map-get($color, "dark/alpha/25"));
    background-color: map-get($color, "neutral/100");
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    .logo {
      figure {
        display: flex;
        width: 140px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .nav-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      .pages {
        display: flex;
        align-items: center;
        gap: 24px;
        .b_1 {
          color: map-get($color, "dark/100");
        }
        .prediction_link {
          position: relative;
          margin-right: 10px;
          span {
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -5px;
            right: -20px;
            background: map-get($color, "primary/300");
            // padding: 0px 3px;
            border-radius: 50%;
            color: map-get($color, "neutral/100");
          }
        }
      }
      .nav-btns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        .b_2 {
          padding: 8px 12px;
          border-radius: 150px;
        }
        .background_btn {
        }
        .outline_btn {
        }
      }
      .select-langs {
      }
      .user {
        display: flex;
        align-items: center;
        gap: 24px;
        .notifications {
          figure {
            display: flex;
            height: 25px;
            svg {
              width: 100%;
              height: 100%;
              fill: map-get($color, "dark/100");
            }
          }
        }
        .profile {
          position: relative;
          display: flex;
          align-items: center;
          gap: 25px;
          .point {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2px;
            .b_2 {
              color: map-get($color, "primary/300");
              filter: drop-shadow(0.5px 0 0 map-get($color, "primary/alpha/10"))
                drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
            }
          }
          .profile_photo {
            cursor: pointer;
            position: relative;
            figure {
              width: 60px;
              height: 60px;
              overflow: hidden;
              border-radius: 50%;
              @include box_shadow(map-get($color, "dark/alpha/25"));
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .modal {
              display: flex;
              flex-direction: column;
              position: absolute;
              top: 100%;
              right: 50%;
              @include box_shadow(map-get($color, "dark/alpha/25"));
              background-color: map-get($color, "neutral/100");
              border-radius: 10px;
              width: max-content;
              a {
                padding: 10px;
                display: flex;
                align-items: center;
                gap: 10px;
                border-bottom: 1px solid map-get($color, "neutral/200");
                &:last-child {
                  border: none;
                }
                figure {
                  display: flex;
                  box-shadow: 0 0 0 0;
                  width: 20px;
                  height: 20px;
                  svg {
                    width: 100%;
                    height: 100%;
                  }
                }
                p {
                  color: map-get($color, "dark/100");
                }
              }
            }
          }
        }
      }
    }
  }
  .mobile_nav {
    bottom: 0;
    top: auto;
    border-radius: 10px 10px 0 0;
    padding: 5px 15px;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      border-radius: 6px;
      figure {
        display: flex;
        height: 20px;
        svg {
          width: 100%;
          height: 100%;
          fill: map-get($color, "dark/100");
        }
      }
      &.power_off {
        figure {
          svg {
            fill: map-get($color, "red/200");
          }
        }
      }
      &.profile_photo {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        height: auto;
        figure {
          width: 35px;
          height: 35px;
          overflow: hidden;
          border-radius: 50%;
          @include box_shadow(map-get($color, "dark/alpha/25"));
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .point {
          display: flex;
          flex-direction: column;
          align-items: center;
          // gap: 2px;
          .b_4 {
            color: map-get($color, "primary/300");
            filter: drop-shadow(0.5px 0 0 map-get($color, "primary/alpha/10"))
              drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
              drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
              drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
          }
          .progress_bar {
            width: 50px;
            height: 4px;
          }
        }
      }
      &.active {
        background-color: map-get($color, "primary/300");
        figure {
          svg {
            fill: map-get($color, "neutral/100");
          }
        }
      }
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    nav {
      // padding: 2px var(--main-gap);
      // border-radius: 0 0 16px 16px;
      .logo {
        figure {
          // width: 140px;
          img {
          }
        }
      }
      .nav-content {
        // gap: 24px;
        .pages {
          // gap: 24px;
          .b_1 {
          }
          .prediction_link {
            margin-right: 10px;
            span {
              // width: 20px;
              // height: 20px;
              // top: -5px;
              // right: -20px;
            }
          }
        }
        .nav-btns {
          // gap: 12px;
          .b_2 {
            // padding: 8px 12px;
            // border-radius: 150px;
          }
          .background_btn {
          }
          .outline_btn {
          }
        }
        .select-langs {
        }
        .user {
          // gap: 24px;
          .notifications {
            figure {
              // height: 25px;
              svg {
              }
            }
          }
          .profile {
            // gap: 25px;
            .point {
              // gap: 2px;
              .b_2 {
                // filter: drop-shadow(
                //     0.5px 0 0 map-get($color, "primary/alpha/10")
                //   )
                //   drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
              }
            }
            .profile_photo {
              figure {
                // width: 60px;
                // height: 60px;
                img {
                }
              }
              .modal {
                // top: 100%;
                // right: 50%;
                // border-radius: 10px;
                a {
                  // padding: 10px;
                  // gap: 10px;
                  // border-bottom: 1px solid map-get($color, "neutral/200");
                  &:last-child {
                  }
                  figure {
                    // width: 20px;
                    // height: 20px;
                    svg {
                    }
                  }
                  p {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    nav {
      // padding: 2px var(--main-gap);
      // border-radius: 0 0 16px 16px;
      .logo {
        figure {
          // width: 140px;
          img {
          }
        }
      }
      .nav-content {
        // gap: 24px;
        .pages {
          // gap: 24px;
          .b_1 {
          }
          .prediction_link {
            margin-right: 10px;
            span {
              // width: 20px;
              // height: 20px;
              // top: -5px;
              // right: -20px;
            }
          }
        }
        .nav-btns {
          // gap: 12px;
          .b_2 {
            // padding: 8px 12px;
            // border-radius: 150px;
          }
          .background_btn {
          }
          .outline_btn {
          }
        }
        .select-langs {
        }
        .user {
          // gap: 24px;
          .notifications {
            figure {
              // height: 25px;
              svg {
              }
            }
          }
          .profile {
            // gap: 25px;
            .point {
              // gap: 2px;
              .b_2 {
                // filter: drop-shadow(
                //     0.5px 0 0 map-get($color, "primary/alpha/10")
                //   )
                //   drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
              }
            }
            .profile_photo {
              figure {
                // width: 60px;
                // height: 60px;
                img {
                }
              }
              .modal {
                // top: 100%;
                // right: 50%;
                // border-radius: 10px;
                a {
                  // padding: 10px;
                  // gap: 10px;
                  // border-bottom: 1px solid map-get($color, "neutral/200");
                  &:last-child {
                  }
                  figure {
                    // width: 20px;
                    // height: 20px;
                    svg {
                    }
                  }
                  p {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    nav {
      // padding: 2px var(--main-gap);
      // border-radius: 0 0 16px 16px;
      .logo {
        figure {
          width: 120px;
          img {
          }
        }
      }
      .nav-content {
        // gap: 24px;
        .pages {
          // gap: 24px;
          .b_1 {
          }
          .prediction_link {
            margin-right: 10px;
            span {
              width: 15px;
              height: 15px;
              // top: -5px;
              right: -15px;
            }
          }
        }
        .nav-btns {
          // gap: 12px;
          .b_2 {
            // padding: 8px 12px;
            // border-radius: 150px;
          }
          .background_btn {
          }
          .outline_btn {
          }
        }
        .select-langs {
        }
        .user {
          // gap: 24px;
          .notifications {
            figure {
              height: 20px;
              svg {
              }
            }
          }
          .profile {
            // gap: 25px;
            .point {
              // gap: 2px;
              .b_2 {
                // filter: drop-shadow(
                //     0.5px 0 0 map-get($color, "primary/alpha/10")
                //   )
                //   drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
              }
            }
            .profile_photo {
              figure {
                width: 50px;
                height: 50px;
                img {
                }
              }
              .modal {
                // top: 100%;
                // right: 50%;
                // border-radius: 10px;
                a {
                  // padding: 10px;
                  // gap: 10px;
                  // border-bottom: 1px solid map-get($color, "neutral/200");
                  &:last-child {
                  }
                  figure {
                    // width: 20px;
                    // height: 20px;
                    svg {
                    }
                  }
                  p {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768 // Tablet
  #root {
    nav {
      // padding: 2px var(--main-gap);
      // border-radius: 0 0 16px 16px;
      .logo {
        figure {
          width: 90px;
          img {
          }
        }
      }
      .nav-content {
        gap: 16px;
        .pages {
          gap: 16px;
          .b_1 {
          }
          .prediction_link {
            // margin-right: 10px;
            span {
              // width: 15px;
              // height: 15px;
              // top: -5px;
              // right: -15px;
            }
          }
        }
        .nav-btns {
          // gap: 12px;
          .b_2 {
            // padding: 8px 12px;
            // border-radius: 150px;
          }
          .background_btn {
          }
          .outline_btn {
          }
        }
        .select-langs {
        }
        .user {
          gap: 16px;
          .notifications {
            figure {
              // height: 20px;
              svg {
              }
            }
          }
          .profile {
            gap: 16px;
            .point {
              // gap: 2px;
              .b_2 {
                // filter: drop-shadow(
                //     0.5px 0 0 map-get($color, "primary/alpha/10")
                //   )
                //   drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
              }
            }
            .profile_photo {
              figure {
                width: 45px;
                height: 45px;
                img {
                }
              }
              .modal {
                // top: 100%;
                // right: 50%;
                // border-radius: 10px;
                a {
                  // padding: 10px;
                  // gap: 10px;
                  // border-bottom: 1px solid map-get($color, "neutral/200");
                  &:last-child {
                  }
                  figure {
                    width: 15px;
                    height: 15px;
                    svg {
                    }
                  }
                  p {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    nav {
      // padding: 2px var(--main-gap);
      // border-radius: 0 0 16px 16px;
      .logo {
        figure {
          width: 50px;
          img {
          }
        }
      }
      .nav-content {
        gap: 6px;
        .pages {
          gap: 6px;
          .b_1 {
          }
          .prediction_link {
            // margin-right: 10px;
            span {
              // width: 15px;
              // height: 15px;
              // top: -5px;
              // right: -15px;
            }
          }
        }
        .nav-btns {
          // gap: 12px;
          .b_2 {
            // padding: 8px 12px;
            // border-radius: 150px;
          }
          .background_btn {
          }
          .outline_btn {
          }
        }
        .select-langs {
        }
        .user {
          gap: 6px;
          .notifications {
            figure {
              height: 15px;
              svg {
              }
            }
          }
          .profile {
            gap: 6px;
            .point {
              // gap: 2px;
              .progress_bar {
                width: 50px;
              }
              .b_2 {
                // filter: drop-shadow(
                //     0.5px 0 0 map-get($color, "primary/alpha/10")
                //   )
                //   drop-shadow(0 0.5px 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(-0.5px 0 0 map-get($color, "primary/alpha/10"))
                //   drop-shadow(0 -0.5px 0 map-get($color, "primary/alpha/10"));
              }
            }
            .profile_photo {
              figure {
                width: 25px;
                height: 25px;
                img {
                }
              }
              .modal {
                // top: 100%;
                // right: 50%;
                border-radius: 8px;
                a {
                  padding: 5px;
                  gap: 5px;
                  // border-bottom: 1px solid map-get($color, "neutral/200");
                  &:last-child {
                  }
                  figure {
                    width: 10px;
                    height: 10px;
                    svg {
                    }
                  }
                  p {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
}
