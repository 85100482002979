#root {
  .matches_page {
    margin-top: 75px;
    padding: 20px var(--main-gap) 130px var(--main-gap);
    position: relative;
    min-height: 100vh;
    .matches_filter {
    }
    .matches_content {
      .h_5.hood {
        text-align: center;
        margin: 25px 0 70px 0;
      }
      .matches {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 48px;
      }
      &.no {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        margin-bottom: 50px;
        // color: map-get($color, "dark/alpha/50");
        .h_3 {
          text-align: center;
          width: 75%;
          color: map-get($color, "neutral/900");
        }
        .h_8 {
          text-align: center;
          color: map-get($color, "neutral/900");
        }
        figure {
          width: 28px;
          svg {
            width: 100%;
            height: 100%;
            // fill: map-get($color, "dark/alpha/50");
            fill: map-get($color, "neutral/900");
          }
        }
      }
    }
  }
  .favorite_match {
    margin-top: 60px;
    .hood_match {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      .h_5 {
      }
      a.outline_btn {
        padding: 2px 10px;
        border-radius: 16px;
        cursor: pointer;
        .b_1 {
          color: map-get($color, "primary/300");
        }
      }
    }
    .matches {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      gap: 48px;
      position: relative;
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .matches_page {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      .matches_filter {
      }
      .matches_content {
        .h_5.hood {
          // margin: 25px 0 70px 0;
        }
        .matches {
          // gap: 48px;
        }
        &.no {
          // gap: 50px;
          // margin-bottom: 50px;
          .h_3 {
            // width: 75%;
          }
          .h_8 {
          }
          figure {
            // width: 28px;
            svg {
            }
          }
        }
      }
    }
    .favorite_match {
      // margin-top: 60px;
      .hood_match {
        // margin-bottom: 40px;
        .h_5 {
        }
        a.outline_btn {
          // padding: 2px 10px;
          // border-radius: 16px;
          .b_1 {
          }
        }
      }
      .matches {
        // gap: 48px;
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .matches_page {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      .matches_filter {
      }
      .matches_content {
        .h_5.hood {
          // margin: 25px 0 70px 0;
        }
        .matches {
          gap: 30px;
        }
        &.no {
          // gap: 50px;
          // margin-bottom: 50px;
          .h_3 {
            // width: 75%;
          }
          .h_8 {
          }
          figure {
            // width: 28px;
            svg {
            }
          }
        }
      }
    }
    .favorite_match {
      // margin-top: 60px;
      .hood_match {
        // margin-bottom: 40px;
        .h_5 {
        }
        a.outline_btn {
          // padding: 2px 10px;
          // border-radius: 16px;
          .b_1 {
          }
        }
      }
      .matches {
        gap: 30px;
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .matches_page {
      // margin-top: 75px;
      // padding: 20px var(--main-gap) 130px var(--main-gap);
      .matches_filter {
      }
      .matches_content {
        .h_5.hood {
          // margin: 25px 0 70px 0;
        }
        .matches {
          // gap: 20px;
        }
        &.no {
          // gap: 50px;
          // margin-bottom: 50px;
          .h_3 {
            // width: 75%;
          }
          .h_8 {
          }
          figure {
            // width: 28px;
            svg {
            }
          }
        }
      }
    }
    .favorite_match {
      // margin-top: 60px;
      .hood_match {
        // margin-bottom: 40px;
        .h_5 {
        }
        a.outline_btn {
          // padding: 2px 10px;
          // border-radius: 16px;
          .b_1 {
          }
        }
      }
      .matches {
        // gap: 30px;
        align-items: normal;
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768
}

@include mediaQuery(768px) {
  // 480
}

@include mediaQuery(480px) {
  // 300
}
