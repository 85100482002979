#root {
  .main_ads_or_img {
    margin: 95px 0 70px 0;
    height: 170px;
    border-radius: 12px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .main_ads_or_img {
      // margin: 95px 0 70px 0;
      // height: 170px;
      // border-radius: 12px;
      img {
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .main_ads_or_img {
      margin: 70px 0;
      // height: 170px;
      // border-radius: 12px;
      img {
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .main_ads_or_img {
      margin: 30px 0 50px 0;
      height: 150px;
      // border-radius: 12px;
      img {
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768 // Tablet
  #root {
    .main_ads_or_img {
      margin: 0px 0 50px 0;
      height: 130px;
      // border-radius: 12px;
      img {
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    .main_ads_or_img {
      margin: 0px 0 30px 0;
      height: 100px;
      // border-radius: 12px;
      img {
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  #root {
    .main_ads_or_img {
      margin: 0;
      height: 80px;
      // border-radius: 12px;
      img {
      }
    }
  }
}
