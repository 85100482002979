#root {
  .register-login-page {
    .content.register {
      padding-top: 30px;
      .form {
        .third-party-register {
          margin-top: 20px;
        }
        .mail-username {
          display: flex;
          justify-content: space-between;
          gap: 50px;
          .input {
            width: 100%;
          }
          .mail-phone {
            flex: 1 1 100%;
            .phone {
              position: relative;
              .phone-input {
                #phone {
                  width: 100%;
                }
              }
            }
            .mail {
              position: relative;
              input {
              }
            }
          }
          .username {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex: 1 1 100%;
            gap: 7px;
            position: relative;
            .label {
              display: flex;
              align-items: center;
              gap: 5px;
              .b_2 {
              }
              .info {
                position: relative;
                figure {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 16px;
                  height: 16px;
                  padding: 2px;
                  border-radius: 50%;
                  border: 1px solid map-get($color, "neutral/700");
                  cursor: pointer;
                  svg {
                    width: 100%;
                    height: 100%;
                    // fill: map-get($color, "neutral/100");
                    fill: map-get($color, "neutral/700");
                  }
                }
                .modal {
                  position: absolute;
                  bottom: 16px;
                  left: 16px;
                  padding: 14px 8px;
                  border: 1px solid map-get($color, "dark/100");
                  background: map-get($color, "neutral/100");
                  border-radius: 4px 4px 4px 0;
                  width: max-content;
                  display: none;
                  .b_4 {
                  }
                }
                &:hover {
                  .modal {
                    display: block;
                  }
                }
              }
            }
            .input {
            }
            .terms {
              .term {
                figure {
                  svg {
                  }
                }
                .b_4 {
                }
              }
            }
          }
        }
        .passwords {
          display: flex;
          flex-direction: column;
          gap: 75px;
          margin: 30px 0;
          .input {
          }
          .password {
          }
          .password,
          .password-again {
            display: flex;
            flex-direction: column;
            gap: 4px;
            position: relative;
            .b_4 {
              color: map-get($color, "red/200");
            }
            .b_2 {
            }
            .input {
            }
          }
        }
        .agreements {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          margin-top: 10px;
          .agreement {
            input {
            }
            .b_2 {
              color: map-get($color, "primary/300");
              &.wrong {
                color: map-get($color, "red/200");
              }
            }
          }
        }
        .background_btn {
          margin-top: 12px;
          width: 100%;
        }
      }
      .verify-code-page {
        margin-top: 50px;
        .wrong-txt {
          display: none;
          .h_8 {
            color: map-get($color, "red/200");
            text-align: center;
            margin: 20px;
          }
        }
        .content-verify {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 4px;
          margin-top: 25px;
          margin-bottom: 75px;
          .content-verify-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;
            .b_1 {
              width: 80%;
              text-align: center;
            }
            .links {
              display: flex;
              flex-direction: column;
              gap: 4px;
              .b_2 {
                color: map-get($color, "primary/300");
                text-align: center;
                background: none;
                &:disabled,
                &[disabled] {
                  cursor: not-allowed;
                  opacity: 0.5;
                }
              }
            }
          }
          .counter {
            text-align: center;
            color: map-get($color, "red/200");
          }
        }
        .background_btn {
          width: 100%;
        }
        &.wrong {
          .verify-code-inputs {
            .vi__container {
              .vi__character {
                @include box_shadow(map-get($color, "red/alpha/50"));
                &:where(.vi__character--selected) {
                  color: map-get($color, "red/200");
                }
              }
            }
          }
          .wrong-txt {
            display: block;
          }
        }
      }
      .fav-team-and-league {
        form {
          display: flex;
          flex-direction: column;
          gap: 36px;
          margin-top: 20px;
          .leagues {
            .b_2 {
              margin-bottom: 10px;
            }
            .custom-dropdown {
              .select-trigger {
                .content-drop {
                  .img-drop {
                    overflow: hidden;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    img {
                      object-fit: cover;
                    }
                  }
                }
              }
              .options {
                .option {
                  .img-drop {
                    overflow: hidden;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    img {
                      object-fit: cover;
                    }
                  }
                }
              }
            }
          }
          hr {
            border: 0;
            height: 1px;
            color: map-get($color, "dark/alpha/50");
            background: map-get($color, "dark/alpha/50");
          }
          .teams {
            .b_2 {
              margin-bottom: 10px;
            }
          }
          .btns {
            display: flex;
            flex-direction: column;
            gap: 25px;
            margin-top: calc(82px - 36px);
            .outline_btn {
              color: map-get($color, "primary/300");
            }
          }
        }
      }
    }
  }
}
