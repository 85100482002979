#root {
  .prediction_page {
    margin-top: 75px;
    min-height: 100vh;
    padding: 80px var(--main-gap) 135px var(--main-gap);
    .hood.h_5 {
      text-align: center;
      // margin-bottom: 45px;
    }
    .hood.h_8 {
      margin-top: 25px;
      text-align: center;
    }
    .prediction_page_content {
      display: flex;
      flex-direction: column;
      gap: 60px;
      .right_to_and_points {
        display: flex;
        gap: 36px;
        justify-content: space-between;
        margin-top: 40px;
        width: 100%;
        .right_to_predictions,
        .total_points,
        .match_count {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: map-get($color, "neutral/100");
          @include box_shadow(map-get($color, "dark/alpha/25"));
          border-radius: 20px;
          padding: 6px 12px;
          width: calc((100% / 2) - (36px / 2));
          div.hood {
            display: flex;
            align-items: center;
            gap: 10px;
            figure {
              // width: 60px;
              height: 35px;
              display: flex;
              img {
                width: 100%;
                height: 100%;
              }
            }
            h6.h_8 {
              display: flex;
              align-items: flex-start;
              gap: 5px;
              .info {
                position: relative;
                cursor: pointer;
                figure {
                  width: 16px;
                  height: 16px;
                  padding: 2px;
                  border-radius: 50%;
                  border: 1px solid map-get($color, "neutral/700");
                  svg {
                    width: 100%;
                    height: 100%;
                    fill: map-get($color, "neutral/700");
                  }
                }
                .modal {
                  position: absolute;
                  bottom: 16px;
                  left: 16px;
                  padding: 14px 8px;
                  border: 1px solid map-get($color, "dark/100");
                  background: map-get($color, "neutral/100");
                  border-radius: 4px 4px 4px 0;
                  width: max-content;
                  display: none;
                }
                &:hover {
                  .modal {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .remaining_prediction {
          }
        }
      }
      .predictions_and_settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: space-between;
        .predictions {
          display: flex;
          // flex-direction: column;
          flex-wrap: wrap;
          gap: 36px;
          width: 100%;
          margin-bottom: 60px;
          .background_btn {
            border-radius: 12px;
            padding: 2px 36px;
            width: auto;
            &[data-loader="true"] {
              p {
                color: map-get($color, "primary/300");
              }
              .btn-loader {
                border-color: map-get($color, "primary/300");
              }
            }
          }
          .no_prediction {
            display: flex;
            flex-direction: column;
            gap: 60px;
            align-items: center;
            h6.h_7 {
              color: map-get($color, "neutral/900");
            }
            .outline_btn.b_3 {
              padding: 6px 18px;
              border-radius: 18px;
              width: fit-content;
            }
          }
        }
        .background_btn {
          // margin-top: 60px;
          border-radius: 20px;
          padding: 6px 36px;
          // width: fit-content;
          width: 25%;
          // padding: 10px 30px;
          p {
            color: map-get($color, "neutral/100");
          }
        }
      }
    }
  }
}
