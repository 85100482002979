#root {
  .register-login-page {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .img {
      display: flex;
      width: 40%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      width: 60%;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      padding: 50px 160px 50px 90px;
      overflow: auto;
      .register_login_hood {
        display: flex;
        align-items: center;
        gap: 30px;
        padding-left: 5px;
        a {
          figure {
            display: flex;
            width: 25px;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .h_6 {
        }
      }
      .form {
        .third-party-register {
          display: flex;
          justify-content: space-between;
          gap: 22px;
          margin-top: 50px;
          margin-bottom: 25px;
          .third_party_btns {
            svg {
            }
            .b_4 {
            }
          }
        }
        .divider {
          display: flex;
          align-items: center;
          margin-bottom: 25px;
          hr {
            height: 1px;
            width: 100%;
            color: map-get($color, "neutral/300");
            background-color: map-get($color, "neutral/300");
          }
          .b_2 {
            @include fill_available;
            text-align: center;
          }
        }
        .show_pass {
          position: relative;
          .input {
            width: 100%;
            padding-right: 50px;
          }
          figure {
            display: flex;
            position: absolute;
            width: 20px;
            top: calc(50% - (13px / 2));
            right: 10px;
            cursor: pointer;
            svg {
              width: 100%;
              height: 100%;
            }
          }
        }
        .terms {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          height: 0;
          overflow: hidden;
          transition: 300ms all;
          .term {
            display: flex;
            align-items: center;
            gap: 5px;
            figure {
              width: 16px;
              height: 16px;
              svg {
                width: 100%;
                height: 100%;
                &.xmark {
                  fill: map-get($color, "red/200");
                }
                &.correct {
                  fill: map-get($color, "green/300");
                }
              }
            }
            .b_4 {
              &.wrong {
                color: map-get($color, "red/200") !important;
              }
              &.correct {
                color: map-get($color, "green/300") !important;
              }
            }
          }
          &.active {
            height: max-content;
          }
        }
        .links {
          display: flex;
          gap: 24px;
          align-items: center;
          margin-bottom: 15px;
          .b_3 {
            width: 100%;
            border: 3px solid map-get($color, "primary/300");
            border-radius: 12px;
            color: map-get($color, "primary/300");
            padding: 4px;
            background-color: map-get($color, "neutral/100");
            text-align: center;
            &.active {
              background-color: map-get($color, "primary/300");
              color: map-get($color, "neutral/100");
            }
          }
        }
        .recaptcha {
          display: inline-block;
          margin-top: 20px;
          .b_3 {
            display: none;
            color: map-get($color, "red/200");
          }
          &.wrong {
            border: 2px solid map-get($color, "red/200");
            border-radius: 5px;
            padding: 5px 8px;
            .b_3 {
              display: inline-block;
            }
          }
        }
        .background_btn {
          width: 100%;
          margin: 24px 0 24px 0;
        }
        .redirect {
          color: map-get($color, "primary/300");
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .register-login-page {
      // width: 100%;
      // height: 100vh;
      .img {
        // width: 40%;
        img {
        }
      }
      .content {
        // width: 60%;
        // padding: 50px 160px 50px 90px;
        .register_login_hood {
          // gap: 30px;
          // padding-left: 5px;
          a {
            figure {
              // width: 25px;
              svg {
              }
            }
          }
        }
        .form {
          .third-party-register {
            // gap: 22px;
            // margin-top: 50px;
            // margin-bottom: 25px;
            .third_party_btns {
              svg {
              }
              .b_4 {
              }
            }
          }
          .divider {
            // margin-bottom: 25px;
            hr {
              // height: 1px;
              // width: 100%;
            }
            .b_2 {
            }
          }
          .show_pass {
            .input {
              // width: 100%;
              // padding-right: 50px;
            }
            figure {
              // width: 20px;
              // top: calc(50% - (13px / 2));
              // right: 10px;
              svg {
              }
            }
          }
          .terms {
            // gap: 5px;
            // top: calc(100% + 10px);
            // left: 0;
            // height: 0;
            .term {
              // gap: 5px;
              figure {
                // width: 16px;
                // height: 16px;
                svg {
                  &.xmark {
                  }
                  &.correct {
                  }
                }
              }
              .b_4 {
                &.wrong {
                }
                &.correct {
                }
              }
            }
            &.active {
              // height: max-content;
            }
          }
          .links {
            // gap: 24px;
            // margin-bottom: 15px;
            .b_3 {
              // width: 100%;
              // border: 3px solid map-get($color, "primary/300");
              // border-radius: 12px;
              // padding: 4px;
              &.active {
              }
            }
          }
          .recaptcha {
            // margin-top: 20px;
            .b_3 {
            }
            &.wrong {
              // border: 2px solid map-get($color, "red/200");
              // border-radius: 5px;
              // padding: 5px 8px;
              .b_3 {
              }
            }
          }
          .background_btn {
            // width: 100%;
            // margin: 24px 0 24px 0;
          }
          .redirect {
          }
        }
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .register-login-page {
      // width: 100%;
      // height: 100vh;
      .img {
        width: 30%;
        img {
        }
      }
      .content {
        width: 70%;
        // padding: 50px 160px 50px 90px;
        .register_login_hood {
          // gap: 30px;
          // padding-left: 5px;
          a {
            figure {
              // width: 25px;
              svg {
              }
            }
          }
        }
        .form {
          .third-party-register {
            // gap: 22px;
            // margin-top: 50px;
            // margin-bottom: 25px;
            .third_party_btns {
              svg {
              }
              .b_4 {
              }
            }
          }
          .divider {
            // margin-bottom: 25px;
            hr {
              // height: 1px;
              // width: 100%;
            }
            .b_2 {
            }
          }
          .show_pass {
            .input {
              // width: 100%;
              // padding-right: 50px;
            }
            figure {
              // width: 20px;
              // top: calc(50% - (13px / 2));
              // right: 10px;
              svg {
              }
            }
          }
          .terms {
            // gap: 5px;
            // top: calc(100% + 10px);
            // left: 0;
            // height: 0;
            .term {
              // gap: 5px;
              figure {
                // width: 16px;
                // height: 16px;
                svg {
                  &.xmark {
                  }
                  &.correct {
                  }
                }
              }
              .b_4 {
                &.wrong {
                }
                &.correct {
                }
              }
            }
            &.active {
              // height: max-content;
            }
          }
          .links {
            // gap: 24px;
            // margin-bottom: 15px;
            .b_3 {
              // width: 100%;
              // border: 3px solid map-get($color, "primary/300");
              // border-radius: 12px;
              // padding: 4px;
              &.active {
              }
            }
          }
          .recaptcha {
            // margin-top: 20px;
            .b_3 {
            }
            &.wrong {
              // border: 2px solid map-get($color, "red/200");
              // border-radius: 5px;
              // padding: 5px 8px;
              .b_3 {
              }
            }
          }
          .background_btn {
            // width: 100%;
            // margin: 24px 0 24px 0;
          }
          .redirect {
          }
        }
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .register-login-page {
      // width: 100%;
      // height: 100vh;
      .img {
        // width: 30%;
        img {
        }
      }
      .content {
        // width: 70%;
        padding: 50px 90px 50px 50px;
        .register_login_hood {
          gap: 25px;
          // padding-left: 5px;
          a {
            figure {
              width: 20px;
              svg {
              }
            }
          }
        }
        .form {
          .third-party-register {
            // gap: 22px;
            // margin-top: 50px;
            // margin-bottom: 25px;
            .third_party_btns {
              svg {
              }
              .b_4 {
              }
            }
          }
          .divider {
            // margin-bottom: 25px;
            hr {
              // height: 1px;
              // width: 100%;
            }
            .b_2 {
            }
          }
          .show_pass {
            .input {
              // width: 100%;
              // padding-right: 50px;
            }
            figure {
              // width: 20px;
              // top: calc(50% - (13px / 2));
              // right: 10px;
              svg {
              }
            }
          }
          .terms {
            // gap: 5px;
            // top: calc(100% + 10px);
            // left: 0;
            // height: 0;
            .term {
              // gap: 5px;
              figure {
                // width: 16px;
                // height: 16px;
                svg {
                  &.xmark {
                  }
                  &.correct {
                  }
                }
              }
              .b_4 {
                &.wrong {
                }
                &.correct {
                }
              }
            }
            &.active {
              // height: max-content;
            }
          }
          .links {
            // gap: 24px;
            // margin-bottom: 15px;
            .b_3 {
              // width: 100%;
              // border: 3px solid map-get($color, "primary/300");
              // border-radius: 12px;
              // padding: 4px;
              &.active {
              }
            }
          }
          .recaptcha {
            // margin-top: 20px;
            .b_3 {
            }
            &.wrong {
              // border: 2px solid map-get($color, "red/200");
              // border-radius: 5px;
              // padding: 5px 8px;
              .b_3 {
              }
            }
          }
          .background_btn {
            // width: 100%;
            // margin: 24px 0 24px 0;
          }
          .redirect {
          }
        }
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768
  #root {
    .register-login-page {
      // width: 100%;
      // height: 100vh;
      .img {
        width: 25%;
        img {
        }
      }
      .content {
        width: 75%;
        padding: 50px 50px 50px 30px;
        .register_login_hood {
          // gap: 30px;
          // padding-left: 5px;
          a {
            figure {
              width: 18px;
              svg {
              }
            }
          }
        }
        .form {
          .third-party-register {
            gap: 12px;
            // margin-top: 50px;
            // margin-bottom: 25px;
            .third_party_btns {
              svg {
              }
              .b_4 {
              }
            }
          }
          .divider {
            // margin-bottom: 25px;
            hr {
              // height: 1px;
              // width: 100%;
            }
            .b_2 {
            }
          }
          .show_pass {
            .input {
              // width: 100%;
              // padding-right: 50px;
            }
            figure {
              // width: 20px;
              // top: calc(50% - (13px / 2));
              // right: 10px;
              svg {
              }
            }
          }
          .terms {
            // gap: 5px;
            // top: calc(100% + 10px);
            // left: 0;
            // height: 0;
            .term {
              // gap: 5px;
              figure {
                // width: 16px;
                // height: 16px;
                svg {
                  &.xmark {
                  }
                  &.correct {
                  }
                }
              }
              .b_4 {
                &.wrong {
                }
                &.correct {
                }
              }
            }
            &.active {
              // height: max-content;
            }
          }
          .links {
            gap: 14px;
            // margin-bottom: 15px;
            .b_3 {
              // width: 100%;
              border: 2px solid map-get($color, "primary/300");
              // border-radius: 12px;
              // padding: 4px;
              &.active {
              }
            }
          }
          .recaptcha {
            // margin-top: 20px;
            .b_3 {
            }
            &.wrong {
              // border: 2px solid map-get($color, "red/200");
              // border-radius: 5px;
              // padding: 5px 8px;
              .b_3 {
              }
            }
          }
          .background_btn {
            // width: 100%;
            // margin: 24px 0 24px 0;
          }
          .redirect {
          }
        }
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    .register-login-page {
      // width: 100%;
      // height: 100vh;
      .img {
        display: none;
        // width: 25%;
        img {
        }
      }
      .content {
        width: 100%;
        // width: 75%;
        padding: 50px var(--main-gap);
        .register_login_hood {
          // gap: 30px;
          // padding-left: 5px;
          a {
            figure {
              // width: 25px;
              svg {
              }
            }
          }
        }
        .form {
          .third-party-register {
            // gap: 12px;
            // margin-top: 50px;
            // margin-bottom: 25px;
            .third_party_btns {
              svg {
              }
              .b_4 {
              }
            }
          }
          .divider {
            // margin-bottom: 25px;
            hr {
              // height: 1px;
              // width: 100%;
            }
            .b_2 {
            }
          }
          .show_pass {
            .input {
              // width: 100%;
              // padding-right: 50px;
            }
            figure {
              // width: 20px;
              // top: calc(50% - (13px / 2));
              // right: 10px;
              svg {
              }
            }
          }
          .terms {
            // gap: 5px;
            // top: calc(100% + 10px);
            // left: 0;
            // height: 0;
            .term {
              // gap: 5px;
              figure {
                // width: 16px;
                // height: 16px;
                svg {
                  &.xmark {
                  }
                  &.correct {
                  }
                }
              }
              .b_4 {
                &.wrong {
                }
                &.correct {
                }
              }
            }
            &.active {
              // height: max-content;
            }
          }
          .links {
            // gap: 14px;
            // margin-bottom: 15px;
            .b_3 {
              // width: 100%;
              // border: 2px solid map-get($color, "primary/300");
              border-radius: 10px;
              // padding: 4px;
              &.active {
              }
            }
          }
          .recaptcha {
            // margin-top: 20px;
            .b_3 {
            }
            &.wrong {
              // border: 2px solid map-get($color, "red/200");
              // border-radius: 5px;
              // padding: 5px 8px;
              .b_3 {
              }
            }
          }
          .background_btn {
            // width: 100%;
            // margin: 24px 0 24px 0;
          }
          .redirect {
          }
        }
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
  #root {
    .register-login-page {
      // width: 100%;
      // height: 100vh;
      .img {
        // display: none;
        // width: 25%;
        img {
        }
      }
      .content {
        // width: 100%;
        // width: 75%;
        // padding: 50px var(--main-gap);
        .register_login_hood {
          gap: 20px;
          // padding-left: 5px;
          a {
            figure {
              width: 15px;
              svg {
              }
            }
          }
        }
        .form {
          .third-party-register {
            // gap: 12px;
            margin-top: 25px;
            // margin-bottom: 25px;
            .third_party_btns {
              svg {
              }
              .b_4 {
              }
            }
          }
          .divider {
            // margin-bottom: 25px;
            hr {
              // height: 1px;
              // width: 100%;
            }
            .b_2 {
            }
          }
          .show_pass {
            .input {
              // width: 100%;
              // padding-right: 50px;
            }
            figure {
              // width: 20px;
              // top: calc(50% - (13px / 2));
              // right: 10px;
              svg {
              }
            }
          }
          .terms {
            // gap: 5px;
            // top: calc(100% + 10px);
            // left: 0;
            // height: 0;
            .term {
              // gap: 5px;
              figure {
                // width: 16px;
                // height: 16px;
                svg {
                  &.xmark {
                  }
                  &.correct {
                  }
                }
              }
              .b_4 {
                &.wrong {
                }
                &.correct {
                }
              }
            }
            &.active {
              // height: max-content;
            }
          }
          .links {
            // gap: 14px;
            // margin-bottom: 15px;
            .b_3 {
              // width: 100%;
              // border: 2px solid map-get($color, "primary/300");
              // border-radius: 10px;
              // padding: 4px;
              &.active {
              }
            }
          }
          .recaptcha {
            // margin-top: 20px;
            // width: 100%;
            transform: scale(0.8);
            transform-origin: 0 0;
            div,
            iframe {
              // width: 100% !important;
            }
            .b_3 {
            }
            &.wrong {
              // border: 2px solid map-get($color, "red/200");
              // border-radius: 5px;
              // padding: 5px 8px;
              .b_3 {
              }
            }
          }
          .background_btn {
            // width: 100%;
            // margin: 24px 0 24px 0;
          }
          .redirect {
          }
        }
      }
    }
  }
}
