#root {
  .custom-dropdown {
    position: relative;
    .disabled {
      display: none;
    }
    .select-trigger {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      border-radius: 12px;
      padding: 5px 8px;
      background-color: map-get($color, "neutral/100");
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 25px;
        .img-drop {
          width: 60px;
          height: 60px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .txt {
          .h_8 {
          }
          .b_4 {
          }
        }
      }
      .arrow {
        padding: 0 32px;
        transform: rotate(0deg);
        transition: transform 0.3s;
        svg {
          width: 32px;
          height: 32px;
        }
        &.open {
          transform: rotate(-180deg);
        }
      }
      &.open {
        border-radius: 12px 12px 0 0;
      }
    }
    .options {
      display: none;
      position: absolute;
      width: 100%;
      background-color: map-get($color, "neutral/100");
      display: block;
      z-index: 2;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      border-radius: 0 0 12px 12px;
      border-top: 0;
      min-height: 100px;
      max-height: 200px;
      overflow: auto;
      .option {
        display: flex;
        align-items: center;
        padding: 5px 8px;
        gap: 25px;
        border-bottom: 2px solid map-get($color, "dark/alpha/25");
        cursor: pointer;
        .img-drop {
          width: 60px;
          height: 60px;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
    &.disabled {
      .disabled {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: map-get($color, "neutral/alpha/40");
        border-radius: 12px;
        z-index: 1;
        cursor: no-drop;
      }
    }
  }
  .phone-dropdown {
    position: relative;
    .select-trigger {
      width: max-content;
      display: flex;
      align-items: center;
      border-radius: 8px 0 0 8px;
      padding: 15px 8px;
      background-color: map-get($color, "neutral/100");
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        gap: 12px;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_4 {
          }
        }
      }
      .arrow {
        transform: rotate(0deg);
        transition: transform 0.3s;
        svg {
          width: 16px;
          height: 16px;
        }
        &.open {
          transform: rotate(-180deg);
        }
      }
    }
    .options {
      display: none;
      position: absolute;
      width: max-content;
      background-color: map-get($color, "neutral/100");
      display: block;
      z-index: 2;
      border-radius: 0 0 4px 4px;
      min-height: 60px;
      max-height: 120px;
      overflow: auto;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      clip-path: inset(0px -60px -60px -60px);
      .option {
        display: flex;
        align-items: center;
        padding: 15px 8px;
        gap: 12px;
        justify-content: space-between;
        border-bottom: 2px solid map-get($color, "primary/alpha/10");
        cursor: pointer;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_4 {
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }
  .packages-dropdown,
  .week-dropdown,
  .month-dropdown,
  .leagues-dropdown,
  .periods-dropdown {
    width: 15vw;
    position: relative;
    .select-trigger {
      display: flex;
      align-items: center;
      background-color: map-get($color, "neutral/100");
      // border: 2px solid map-get($color, "neutral/400");
      border-radius: 8px;
      padding: 10px 15px;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      cursor: pointer;
      .content-drop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        width: 100%;
        .txt {
          .b_3 {
          }
        }
        .arrow {
          width: 8px;
          display: flex;
          align-items: center;
          svg {
            width: 100%;
            height: 100%;
          }
        }
      }
      &.open {
        border-radius: 8px 8px 0 0;
      }
    }
    .options {
      display: none;
      position: absolute;
      width: 100%;
      background-color: map-get($color, "neutral/100");
      display: block;
      z-index: 3;
      border-radius: 0 0 8px 8px;
      min-height: 60px;
      max-height: 210px;
      overflow: auto;
      @include box_shadow(map-get($color, "dark/alpha/25"));
      clip-path: inset(0px -60px -60px -60px);
      .option {
        display: flex;
        align-items: center;
        padding: 15px 8px;
        gap: 12px;
        justify-content: space-between;
        border-bottom: 2px solid map-get($color, "primary/alpha/10");
        cursor: pointer;
        .img-drop {
          width: 28px;
          height: 20px;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .txt {
          .b_3 {
          }
        }
        &:nth-last-child(1) {
          border-bottom: 0;
        }
      }
    }
  }
  // .week-dropdown{
  //   &:nth-last-child(1) {
  //     border-bottom: 0;
  //   }
  // }
}

@include mediaQuery(1920px) {
  // 1440
  #root {
    .custom-dropdown {
      .disabled {
      }
      .select-trigger {
        // border-radius: 12px;
        // padding: 5px 8px;
        .content-drop {
          // gap: 25px;
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          .txt {
            .h_8 {
            }
            .b_4 {
            }
          }
        }
        .arrow {
          // padding: 0 32px;
          svg {
            // width: 32px;
            // height: 32px;
          }
          &.open {
          }
        }
        &.open {
          // border-radius: 12px 12px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 12px 12px;
        // min-height: 100px;
        // max-height: 200px;
        .option {
          // padding: 5px 8px;
          // gap: 25px;
          // border-bottom: 2px solid map-get($color, "dark/alpha/25");
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
      &.disabled {
        .disabled {
          // border-radius: 12px;
        }
      }
    }
    .phone-dropdown {
      .select-trigger {
        // border-radius: 8px 0 0 8px;
        // padding: 15px 8px;
        .content-drop {
          // gap: 12px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
        }
        .arrow {
          svg {
            // width: 16px;
            // height: 16px;
          }
          &.open {
          }
        }
      }
      .options {
        // border-radius: 0 0 4px 4px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
    .packages-dropdown,
    .week-dropdown,
    .month-dropdown,
    .leagues-dropdown,
    .periods-dropdown {
      // width: 15vw;
      .select-trigger {
        // border-radius: 8px;
        // padding: 10px 15px;
        .content-drop {
          // gap: 20px;
          .txt {
            .b_3 {
            }
          }
          .arrow {
            // width: 8px;
            svg {
            }
          }
        }
        &.open {
          // border-radius: 8px 8px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 8px 8px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_3 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
  }
}

@include mediaQuery(1440px) {
  // 1280
  #root {
    .custom-dropdown {
      .disabled {
      }
      .select-trigger {
        // border-radius: 12px;
        // padding: 5px 8px;
        .content-drop {
          // gap: 25px;
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          .txt {
            .h_8 {
            }
            .b_4 {
            }
          }
        }
        .arrow {
          // padding: 0 32px;
          svg {
            // width: 32px;
            // height: 32px;
          }
          &.open {
          }
        }
        &.open {
          // border-radius: 12px 12px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 12px 12px;
        // min-height: 100px;
        // max-height: 200px;
        .option {
          // padding: 5px 8px;
          // gap: 25px;
          // border-bottom: 2px solid map-get($color, "dark/alpha/25");
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
      &.disabled {
        .disabled {
          // border-radius: 12px;
        }
      }
    }
    .phone-dropdown {
      .select-trigger {
        // border-radius: 8px 0 0 8px;
        // padding: 15px 8px;
        .content-drop {
          // gap: 12px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
        }
        .arrow {
          svg {
            // width: 16px;
            // height: 16px;
          }
          &.open {
          }
        }
      }
      .options {
        // border-radius: 0 0 4px 4px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
    .packages-dropdown,
    .week-dropdown,
    .month-dropdown,
    .leagues-dropdown,
    .periods-dropdown {
      // width: 15vw;
      .select-trigger {
        // border-radius: 8px;
        // padding: 10px 15px;
        .content-drop {
          // gap: 20px;
          .txt {
            .b_3 {
            }
          }
          .arrow {
            // width: 8px;
            svg {
            }
          }
        }
        &.open {
          // border-radius: 8px 8px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 8px 8px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_3 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
  }
}

@include mediaQuery(1280px) {
  // 1024
  #root {
    .custom-dropdown {
      .disabled {
      }
      .select-trigger {
        // border-radius: 12px;
        // padding: 5px 8px;
        .content-drop {
          // gap: 25px;
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          .txt {
            .h_8 {
            }
            .b_4 {
            }
          }
        }
        .arrow {
          // padding: 0 32px;
          svg {
            // width: 32px;
            // height: 32px;
          }
          &.open {
          }
        }
        &.open {
          // border-radius: 12px 12px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 12px 12px;
        // min-height: 100px;
        // max-height: 200px;
        .option {
          // padding: 5px 8px;
          // gap: 25px;
          // border-bottom: 2px solid map-get($color, "dark/alpha/25");
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
      &.disabled {
        .disabled {
          // border-radius: 12px;
        }
      }
    }
    .phone-dropdown {
      .select-trigger {
        // border-radius: 8px 0 0 8px;
        // padding: 15px 8px;
        .content-drop {
          // gap: 12px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
        }
        .arrow {
          svg {
            // width: 16px;
            // height: 16px;
          }
          &.open {
          }
        }
      }
      .options {
        // border-radius: 0 0 4px 4px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
    .packages-dropdown,
    .week-dropdown,
    .month-dropdown,
    .leagues-dropdown,
    .periods-dropdown {
      width: 17vw;
      .select-trigger {
        border-radius: 6px;
        padding: 8px 15px;
        .content-drop {
          // gap: 20px;
          .txt {
            .b_3 {
            }
          }
          .arrow {
            // width: 8px;
            svg {
            }
          }
        }
        &.open {
          border-radius: 6px 6px 0 0;
        }
      }
      .options {
        border-radius: 0 0 6px 6px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          padding: 13px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          border-width: 1px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_3 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
  }
}

@include mediaQuery(1024px) {
  // 768
  #root {
    .custom-dropdown {
      .disabled {
      }
      .select-trigger {
        // border-radius: 12px;
        // padding: 5px 8px;
        .content-drop {
          // gap: 25px;
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          .txt {
            .h_8 {
            }
            .b_4 {
            }
          }
        }
        .arrow {
          // padding: 0 32px;
          svg {
            // width: 32px;
            // height: 32px;
          }
          &.open {
          }
        }
        &.open {
          // border-radius: 12px 12px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 12px 12px;
        // min-height: 100px;
        // max-height: 200px;
        .option {
          // padding: 5px 8px;
          // gap: 25px;
          // border-bottom: 2px solid map-get($color, "dark/alpha/25");
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
      &.disabled {
        .disabled {
          // border-radius: 12px;
        }
      }
    }
    .phone-dropdown {
      .select-trigger {
        // border-radius: 8px 0 0 8px;
        // padding: 15px 8px;
        .content-drop {
          // gap: 12px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
        }
        .arrow {
          svg {
            // width: 16px;
            // height: 16px;
          }
          &.open {
          }
        }
      }
      .options {
        // border-radius: 0 0 4px 4px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
    .packages-dropdown,
    .week-dropdown,
    .month-dropdown,
    .leagues-dropdown,
    .periods-dropdown {
      width: 23vw;
      .select-trigger {
        // border-radius: 6px;
        // padding: 8px 15px;
        .content-drop {
          // gap: 20px;
          .txt {
            .b_3 {
            }
          }
          .arrow {
            width: 7px;
            svg {
            }
          }
        }
        &.open {
          // border-radius: 6px 6px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 6px 6px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 13px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          // border-width: 1px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_3 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
  }
}

@include mediaQuery(768px) {
  // 480
  #root {
    .custom-dropdown {
      .disabled {
      }
      .select-trigger {
        // border-radius: 12px;
        // padding: 5px 8px;
        .content-drop {
          // gap: 25px;
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          .txt {
            .h_8 {
            }
            .b_4 {
            }
          }
        }
        .arrow {
          // padding: 0 32px;
          svg {
            // width: 32px;
            // height: 32px;
          }
          &.open {
          }
        }
        &.open {
          // border-radius: 12px 12px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 12px 12px;
        // min-height: 100px;
        // max-height: 200px;
        .option {
          // padding: 5px 8px;
          // gap: 25px;
          // border-bottom: 2px solid map-get($color, "dark/alpha/25");
          .img-drop {
            // width: 60px;
            // height: 60px;
            img {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
      &.disabled {
        .disabled {
          // border-radius: 12px;
        }
      }
    }
    .phone-dropdown {
      .select-trigger {
        // border-radius: 8px 0 0 8px;
        // padding: 15px 8px;
        .content-drop {
          // gap: 12px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
        }
        .arrow {
          svg {
            // width: 16px;
            // height: 16px;
          }
          &.open {
          }
        }
      }
      .options {
        // border-radius: 0 0 4px 4px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 15px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_4 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
    .packages-dropdown,
    .week-dropdown,
    .month-dropdown,
    .leagues-dropdown,
    .periods-dropdown {
      width: 100%;
      .select-trigger {
        // border-radius: 6px;
        // padding: 8px 15px;
        .content-drop {
          // gap: 20px;
          .txt {
            .b_3 {
            }
          }
          .arrow {
            width: 6px;
            svg {
            }
          }
        }
        &.open {
          // border-radius: 6px 6px 0 0;
        }
      }
      .options {
        // border-radius: 0 0 6px 6px;
        // min-height: 60px;
        // max-height: 120px;
        // clip-path: inset(0px -60px -60px -60px);
        .option {
          // padding: 13px 8px;
          // gap: 12px;
          // border-bottom: 2px solid map-get($color, "primary/alpha/10");
          // border-width: 1px;
          .img-drop {
            // width: 28px;
            // height: 20px;
            // border-radius: 2px;
            img {
            }
          }
          .txt {
            .b_3 {
            }
          }
          &:nth-last-child(1) {
          }
        }
      }
    }
  }
}

@include mediaQuery(480px) {
  // 300
}
