#root {
  .coming-soon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: map-get($color, "footer");
    gap: 30px;
    .h_1 {
      color: map-get($color, "neutral/100");
    }
    .h_5 {
      text-align: center;
      width: 35%;
      color: map-get($color, "neutral/100");
    }
    .outline_btn {
      color: map-get($color, "neutral/100");
      background: transparent;
      border-color: map-get($color, "neutral/100");
    }
  }
}
