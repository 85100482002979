.stat_card {
  display: flex;
  align-items: center;
  gap: 15px;
  @include box_shadow(map-get($color, "dark/alpha/25"));
  border-radius: 12px;
  padding: 8px;
  background-color: map-get($color, "neutral/100");
  figure {
    width: 40px;
    flex: 0 0 40px;
    svg {
      width: 100%;
      height: 100%;
      fill: map-get($color, "primary/300");
    }
  }
  .txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc((1.5em * 3) + 8px);
    h6 {
    }
    p {
    }
  }
}
