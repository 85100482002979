.alertify {
  .ajs-dialog {
    overflow: hidden;
    border-radius: 16px;
    .ajs-header {
      @extend .h_8;
    }
    .ajs-body {
      .ajs-content {
        @extend .b_1;
      }
    }
    .ajs-footer {
      .ajs-ok {
        @extend .b_4;
        @extend .outline_btn;
        @extend .--bb;
        display: inline-block !important;
        padding: 10px !important;
      }
      .ajs-cancel {
        @extend .b_4;
        @extend .outline_btn;
        @extend .--b;
        display: inline-block !important;
        padding: 10px !important;
        color: map-get($color, "red/200") !important;
        border-color: map-get($color, "red/200") !important;
      }
    }
  }
}

.alertify-notifier {
  .ajs-error {
    border-radius: 6px;
    background: map-get($color, "red/200") !important;
    color: map-get($color, "neutral/100");
  }
}
