.h_1 {
  font-family: $font;
  font-size: pxToRem(84);
  line-height: pxToRem(125);
  @extend .--bb;
  color: map-get($color, "dark/100");
}

.h_2 {
  font-family: $font;
  font-size: pxToRem(70);
  line-height: pxToRem(104);
  @extend .--b;
  color: map-get($color, "dark/100");
}

.h_3 {
  font-family: $font;
  font-size: pxToRem(58);
  line-height: pxToRem(86);
  @extend .--b;
  color: map-get($color, "dark/100");
}

.h_4 {
  font-family: $font;
  font-size: pxToRem(48);
  line-height: pxToRem(70);
  @extend .--m;
  color: map-get($color, "dark/100");
}

.h_5 {
  font-family: $font;
  font-size: pxToRem(40);
  line-height: pxToRem(55);
  @extend .--m;
  color: map-get($color, "dark/100");
}

.h_6 {
  font-family: $font;
  font-size: pxToRem(34);
  line-height: pxToRem(50);
  @extend .--m;
  color: map-get($color, "dark/100");
}

.h_7 {
  font-family: $font;
  font-size: pxToRem(28);
  line-height: pxToRem(40);
  @extend .--m;
  color: map-get($color, "dark/100");
}

.h_8 {
  font-family: $font;
  font-size: pxToRem(24);
  line-height: pxToRem(35);
  @extend .--m;
  color: map-get($color, "dark/100");
}

.b_1 {
  font-family: $font;
  font-size: pxToRem(19);
  line-height: pxToRem(28);
  @extend .--r;
  color: map-get($color, "dark/100");
}

.b_2 {
  font-family: $font;
  font-size: pxToRem(17);
  line-height: pxToRem(25);
  @extend .--r;
  color: map-get($color, "dark/100");
}

.b_3 {
  font-family: $font;
  font-size: pxToRem(15);
  line-height: pxToRem(22);
  @extend .--r;
  color: map-get($color, "dark/100");
}

.b_4 {
  font-family: $font;
  font-size: pxToRem(13);
  line-height: pxToRem(19);
  @extend .--r;
  color: map-get($color, "dark/100");
}

.--r {
  font-weight: 400;
}

.--m {
  font-weight: 500;
}

.--b {
  font-weight: 600;
}

.--bb {
  font-weight: 700;
}

@include mediaQuery(1920px) {
  // 1440
  .h_1 {
    font-size: pxToRem(82);
    line-height: pxToRem(123);
  }
  .h_2 {
    font-size: pxToRem(68);
    line-height: pxToRem(102);
  }
  .h_3 {
    font-size: pxToRem(56);
    line-height: pxToRem(84);
  }
  .h_4 {
    font-size: pxToRem(46);
    line-height: pxToRem(68);
  }
  .h_5 {
    font-size: pxToRem(38);
    line-height: pxToRem(57);
  }
  .h_6 {
    font-size: pxToRem(32);
    line-height: pxToRem(48);
  }
  .h_7 {
    font-size: pxToRem(26);
    line-height: pxToRem(38);
  }
  .h_8 {
    font-size: pxToRem(22);
    line-height: pxToRem(33);
  }
  .b_1 {
    font-size: pxToRem(18);
    line-height: pxToRem(27);
  }
  .b_2 {
    font-size: pxToRem(16);
    line-height: pxToRem(24);
  }
  .b_3 {
    font-size: pxToRem(14);
    line-height: pxToRem(21);
  }
  .b_4 {
    font-size: pxToRem(12);
    line-height: pxToRem(18);
  }
}

@include mediaQuery(1440px) {
  // 1280
  .h_1 {
    font-size: pxToRem(80);
    line-height: pxToRem(121);
  }
  .h_2 {
    font-size: pxToRem(66);
    line-height: pxToRem(100);
  }
  .h_3 {
    font-size: pxToRem(54);
    line-height: pxToRem(82);
  }
  .h_4 {
    font-size: pxToRem(44);
    line-height: pxToRem(66);
  }
  .h_5 {
    font-size: pxToRem(36);
    line-height: pxToRem(55);
  }
  .h_6 {
    font-size: pxToRem(30);
    line-height: pxToRem(46);
  }
  .h_7 {
    font-size: pxToRem(24);
    line-height: pxToRem(36);
  }
  .h_8 {
    font-size: pxToRem(20);
    line-height: pxToRem(31);
  }
  .b_1 {
    font-size: pxToRem(17);
    line-height: pxToRem(26);
  }
  .b_2 {
    font-size: pxToRem(15);
    line-height: pxToRem(23);
  }
  .b_3 {
    font-size: pxToRem(13);
    line-height: pxToRem(20);
  }
  .b_4 {
    font-size: pxToRem(11);
    line-height: pxToRem(17);
  }
}

@include mediaQuery(1280px) {
  // 1024
  .h_1 {
    font-size: pxToRem(78);
    line-height: pxToRem(119);
  }
  .h_2 {
    font-size: pxToRem(64);
    line-height: pxToRem(98);
  }
  .h_3 {
    font-size: pxToRem(52);
    line-height: pxToRem(80);
  }
  .h_4 {
    font-size: pxToRem(42);
    line-height: pxToRem(64);
  }
  .h_5 {
    font-size: pxToRem(34);
    line-height: pxToRem(53);
  }
  .h_6 {
    font-size: pxToRem(28);
    line-height: pxToRem(44);
  }
  .h_7 {
    font-size: pxToRem(22);
    line-height: pxToRem(34);
  }
  .h_8 {
    font-size: pxToRem(18);
    line-height: pxToRem(29);
  }
  .b_1 {
    font-size: pxToRem(16);
    line-height: pxToRem(25);
  }
  .b_2 {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
  }
  .b_3 {
    font-size: pxToRem(12);
    line-height: pxToRem(19);
  }
  .b_4 {
    font-size: pxToRem(10);
    line-height: pxToRem(16);
  }
}

@include mediaQuery(1024px) {
  // 768
  .h_1 {
    font-size: pxToRem(76);
    line-height: pxToRem(117);
  }
  .h_2 {
    font-size: pxToRem(62);
    line-height: pxToRem(96);
  }
  .h_3 {
    font-size: pxToRem(50);
    line-height: pxToRem(78);
  }
  .h_4 {
    font-size: pxToRem(40);
    line-height: pxToRem(62);
  }
  .h_5 {
    font-size: pxToRem(32);
    line-height: pxToRem(51);
  }
  .h_6 {
    font-size: pxToRem(26);
    line-height: pxToRem(42);
  }
  .h_7 {
    font-size: pxToRem(20);
    line-height: pxToRem(32);
  }
  .h_8 {
    font-size: pxToRem(16);
    line-height: pxToRem(27);
  }
  .b_1 {
    font-size: pxToRem(15);
    line-height: pxToRem(24);
  }
  .b_2 {
    font-size: pxToRem(13);
    line-height: pxToRem(21);
  }
  .b_3 {
    font-size: pxToRem(11);
    line-height: pxToRem(18);
  }
  .b_4 {
    font-size: pxToRem(9);
    line-height: pxToRem(15);
  }
}

@include mediaQuery(768px) {
  // 480
  .h_1 {
    font-size: pxToRem(72);
    line-height: pxToRem(113);
  }
  .h_2 {
    font-size: pxToRem(58);
    line-height: pxToRem(92);
  }
  .h_3 {
    font-size: pxToRem(46);
    line-height: pxToRem(74);
  }
  .h_4 {
    font-size: pxToRem(36);
    line-height: pxToRem(58);
  }
  .h_5 {
    font-size: pxToRem(30);
    line-height: pxToRem(49);
  }
  .h_6 {
    font-size: pxToRem(26);
    line-height: pxToRem(42);
  }
  .h_7 {
    font-size: pxToRem(20);
    line-height: pxToRem(32);
  }
  .h_8 {
    font-size: pxToRem(16);
    line-height: pxToRem(27);
  }
  .b_1 {
    font-size: pxToRem(14);
    line-height: pxToRem(22);
  }
  .b_2 {
    font-size: pxToRem(12);
    line-height: pxToRem(19);
  }
  .b_3 {
    font-size: pxToRem(10);
    line-height: pxToRem(17);
  }
  .b_4 {
    font-size: pxToRem(8);
    line-height: pxToRem(14);
  }
}

@include mediaQuery(480px) {
  // 300
  .h_1 {
    font-size: pxToRem(55);
    line-height: pxToRem(107);
  }
  .h_2 {
    font-size: pxToRem(54);
    line-height: pxToRem(88);
  }
  .h_3 {
    font-size: pxToRem(44);
    line-height: pxToRem(72);
  }
  .h_4 {
    font-size: pxToRem(34);
    line-height: pxToRem(56);
  }
  .h_5 {
    font-size: pxToRem(28);
    line-height: pxToRem(47);
  }
  .h_6 {
    font-size: pxToRem(24);
    line-height: pxToRem(40);
  }
  .h_7 {
    font-size: pxToRem(18);
    line-height: pxToRem(30);
  }
  .h_8 {
    font-size: pxToRem(14);
    line-height: pxToRem(25);
  }
  .b_1 {
    font-size: pxToRem(13);
    line-height: pxToRem(20);
  }
  .b_2 {
    font-size: pxToRem(11);
    line-height: pxToRem(19);
  }
  .b_3 {
    font-size: pxToRem(9);
    line-height: pxToRem(16);
  }
  .b_4 {
    font-size: pxToRem(7);
    line-height: pxToRem(13);
  }
}
