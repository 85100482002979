* {
  @include reset;
}

body {
  font-family: $font;
  overflow-x: hidden;
  background-image: url("/public/img/background.svg"),
    url("/public/img/background.svg");
  background-size: 500px 500px;
  background-position: 0 0, 250px 250px;
  background-color: map-get($color, "neutral/100");
}

svg {
  fill: map-get($color, "dark/100");
}

.non_select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &--img {
    @extend .non_select;
    pointer-events: none;
  }
}

a,
button {
  border: none;
  cursor: pointer;
}

iframe#webpack-dev-server-client-overlay {
  display: none;
  opacity: 0 !important;
}

@include mediaQuery(1920px) {
  // 1440
  body {
    background-size: 450px 450px;
    background-position: 0 0, 225px 225px;
  }
}

@include mediaQuery(1440px) {
  // 1280
  body {
    background-size: 400px 400px;
    background-position: 0 0, 200px 200px;
  }
}

@include mediaQuery(1280px) {
  // 1024
  body {
    background-size: 350px 350px;
    background-position: 0 0, 175px 175px;
  }
}

@include mediaQuery(1024px) {
  // 768 // Tablet
  body {
    background-size: 300px 300px;
    background-position: 0 0, 150px 150px;
  }
}

@include mediaQuery(768px) {
  // 480
  body {
    background-size: 250px 250px;
    background-position: 0 0, 125px 125px;
  }
}

@include mediaQuery(480px) {
  // 300
  body {
    background-size: 200px 200px;
    background-position: 0 0, 100px 100px;
  }
}
