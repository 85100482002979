.achievement_card {
  display: flex;
  align-items: center;
  gap: 5px;
  border: 3px solid map-get($color, "dark/100");
  border-radius: 12px;
  padding: 8px;
  background-color: map-get($color, "neutral/100");
  color: map-get($color, "neutral/800");
  // @include box_shadow(map-get($color, "dark/alpha/25"));
  &.block {
    @extend .non_select--img;
    filter: grayscale(100%);
    cursor: not-allowed;
    box-shadow: 0 0 0 0 !important;
  }
  &.gold {
    border-color: map-get($color, "gold/100");
    @include box_shadow(map-get($color, "gold/100"));
  }
  &.silver {
    border-color: map-get($color, "silver/100");
    @include box_shadow(map-get($color, "silver/100"));
  }
  &.bronze {
    border-color: map-get($color, "bronze/100");
    @include box_shadow(map-get($color, "bronze/100"));
  }
  figure {
    width: 48px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .card_content {
    width: 100%;
    .b_3 {
    }
    .b_4 {
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .b_2 {
        color: map-get($color, "green/300");
      }
      .bar {
        .b_4 {
        }
      }
    }
  }
}
